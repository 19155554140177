import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import './RfaForm.css';

import useRfaForm from './RfaForm.hook';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';
import Selector from 'components/shared/selector/Selector';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import SearchableDropDownPaginated from 'components/shared/dropdown/SearchableDropDownPaginated';
import DatePicker from 'components/shared/date-picker/DatePicker';
import TimePicker from 'components/shared/time-picker/TimePicker';
import CharsCounterTextarea from 'components/shared/chars-counter/CharsCounterTextarea';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import ImageUpload from 'components/shared/image-upload/ImageUpload';
import DocumentUploadWithPreview from 'components/shared/document-upload/DocumentUploadWithPreview';
import RadioButton from 'components/shared/radio-button/RadioButton';

import { getCurrentRfa, getRfaForm, getRfaReadOnly } from './store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import {
  getProjectProposal,
  getCsiCodes,
} from 'components/admin/projects/details/project-proposal/store/selectors';

import {
  todayValidation,
  sameOrBeforeTodayValidation,
  calculateDateDuration,
} from 'services/utils/date-util';
import { rfaTypes, responseMethodsOptions, REASSIGN_STATUS } from 'domain/rfa';
import moment from 'moment';

const ByAtText = ({ by, at, getByAtText, label = 'Review By' }) => (
  <FormControlBlock
    hideBottomLabel
    label={label}
    control={<ReadOnlyInput value={getByAtText(by, at)} />}
  />
);
const RemarksText = ({ label = 'Remarks', value }) => (
  <FormControlBlock
    hideBottomLabel
    label={label}
    control={
      <TextareaAutosizeWrapper
        readOnly
        disabled
        initialValue={value}
        className="request-textarea"
      />
    }
  />
);
const RecipientsText = ({ recipients }) => (
  <ReadOnlyInput
    value={
      recipients.length > 0 ? recipients.map((p) => p[1]).join(', ') : '--'
    }
  />
);
const DocumentsPreview = ({ value, label = 'Documents' }) => (
  <FormControlBlock
    label={label}
    hideBottomLabel
    control={<DocumentUploadWithPreview documents={value} multiple readOnly />}
  />
);
const ImagesPreview = ({ value, label = 'Photos' }) => (
  <FormControlBlock
    label={label}
    hideBottomLabel
    control={<ImageUpload images={value} readOnly />}
  />
);

const RfaForm = (props) => {
  const {
    handleCancel,
    handleRemoveDocument,
    handleRemoveImage,
    handleUploadDocument,
    handleUploadImage,
    handleEditImages,
    handleSave,
    handleSaveDraft,
    loadContributors,
    recipientOptions,
    tradeCodeOptions,
    handleFormChange,
    getApprovedStatusOptions,
    getByAtText,
    handleAddRecipients,
    handleAccelerate,
    isBasic,
    isForRecord,
    isPRfa,
    isDraft,
    isPending,
    isApproved,
    isSubscriber,
    isAssignee,
    isSubmitted,
    isReviewed,
    isResponded,
    showFormError,
    showAdditionalRecipients,
    inputsAttributes,
    isAccelerated,
  } = useRfaForm(props);
  const { currentUser, readOnly, form } = props;

  const TypeInput = (
    <FormControlBlock
      hideBottomLabel={readOnly}
      label={(readOnly || form.request_type) && 'Type'}
      control={
        <Selector
          className="select-dropdown"
          placeholder={!readOnly ? 'Type' : '--'}
          value={form.request_type}
          onChange={(option) =>
            handleFormChange('request_type', option ? option.value : option)
          }
          options={rfaTypes}
          clearable
          valid={!(form.errors && form.errors.request_type)}
          readOnly={readOnly}
        />
      }
    />
  );
  const TitleInput = (
    <FormControlBlock
      hideBottomLabel
      label={(readOnly || form.request_title) && 'Title'}
      control={
        <CharsCounterTextarea
          limit={50}
          className="title"
          placeholder={!readOnly ? 'Title' : '--'}
          value={form.request_title}
          readOnly={readOnly}
          valid={!(form.errors && form.errors.request_title)}
          onChange={(value) => handleFormChange('request_title', value)}
        />
      }
    />
  );
  const AssignToInput = (
    <FormControlBlock
      hideBottomLabel={
        readOnly && (!isPending || form.response_code !== REASSIGN_STATUS)
      }
      label={(readOnly || form.assigned_to) && 'Assign To'}
      control={
        <SearchableDropDownPaginated
          clearable
          placeholder={!readOnly ? 'Assign To' : '--'}
          readOnly={
            readOnly && (!isPending || form.response_code !== REASSIGN_STATUS)
          }
          defaultArrow={true}
          loadOptions={loadContributors}
          value={form.assigned_to}
          onChange={(option) => handleFormChange('assigned_to', option)}
          valid={!(form.errors && form.errors.assigned_to)}
        />
      }
    />
  );
  const DueDateInput = (
    <FormControlBlock
      hideBottomLabel={readOnly}
      label={(readOnly || form.due_date) && 'Due Date'}
      bottomLabel={
        form.due_date && todayValidation(moment(form.due_date))
          ? calculateDateDuration(moment(), form.due_date) + ' left'
          : '0 Days left'
      }
      control={
        <DatePicker
          className="due-date"
          placeholder={!readOnly ? 'Due Date' : '--'}
          clearable
          readOnly={readOnly}
          hideAdornment={readOnly}
          value={form.due_date}
          onChange={(value) => handleFormChange('due_date', value)}
          isValidDate={todayValidation}
          valid={!(form.errors && form.errors.due_date)}
        />
      }
    />
  );
  const StatusInput = (
    <FormControlBlock
      hideBottomLabel={readOnly}
      label="Status"
      control={<ReadOnlyInput value={form.formatted_status} />}
    />
  );
  const TradeCodeInput = (
    <FormControlBlock
      hideBottomLabel={readOnly}
      label={(readOnly || form.csi_code_id) && 'Cost Code'}
      control={
        readOnly ? (
          <ReadOnlyInput value={form.csi_code_label} />
        ) : (
          <Selector
            placeholder={!readOnly ? 'Cost Code' : '--'}
            value={form.csi_code_id}
            onChange={(option) =>
              handleFormChange('csi_code_id', option ? option.value : option)
            }
            options={tradeCodeOptions}
            readOnly={readOnly}
            valid={!(form.errors && form.errors.csi_code_id)}
          />
        )
      }
    />
  );
  const readOnlyResponse = readOnly && !isAccelerated;
  const ResponseByInput = (
    <FormControlBlock
      hideBottomLabel={readOnlyResponse}
      label={(readOnlyResponse || form.responded_by) && 'Response By'}
      control={
        <SearchableDropDownPaginated
          clearable
          placeholder={!readOnlyResponse ? 'Response By' : '--'}
          readOnly={readOnlyResponse}
          defaultArrow={true}
          loadOptions={loadContributors}
          value={form.responded_by}
          onChange={(option) => handleFormChange('responded_by', option)}
          valid={!(form.errors && form.errors.responded_by)}
        />
      }
    />
  );
  const ResponseDateInput = (
    <FormControlBlock
      hideBottomLabel={readOnlyResponse}
      label={(readOnlyResponse || form.responded_at_date) && 'Response Date'}
      control={
        <DatePicker
          className="response-date"
          placeholder={!readOnlyResponse ? 'Response Date' : '--'}
          clearable={true}
          readOnly={readOnlyResponse}
          value={form.responded_at_date}
          onChange={(value) => handleFormChange('responded_at_date', value)}
          isValidDate={sameOrBeforeTodayValidation}
          valid={!(form.errors && form.errors.responded_at_date)}
        />
      }
    />
  );
  const ResponseTimeInput = (
    <FormControlBlock
      hideBottomLabel={readOnlyResponse}
      label={(readOnlyResponse || form.responded_at_time) && 'Approx Time'}
      control={
        <TimePicker
          className={'clock'}
          placeholder={!readOnlyResponse ? 'Response Time' : '--'}
          readOnly={readOnlyResponse}
          onChange={(value) => handleFormChange('responded_at_time', value)}
          value={form.responded_at_time}
          valid={!(form.errors && form.errors.responded_at_time)}
        />
      }
    />
  );
  const ResponseMethodInput = (
    <FormControlBlock
      hideBottomLabel={readOnlyResponse}
      label={form.response_method && 'Response Method'}
      control={
        <Selector
          className="select-dropdown"
          placeholder={!readOnlyResponse ? 'Response Method' : '--'}
          value={form.response_method}
          onChange={(option) =>
            handleFormChange('response_method', option ? option.value : option)
          }
          options={responseMethodsOptions}
          clearable
          valid={!(form.errors && form.errors.response_method)}
          readOnly={readOnlyResponse}
        />
      }
    />
  );
  const ResponseMethodText = (
    <FormControlBlock
      hideBottomLabel
      label="Response Method & Remarks"
      control={
        <ReadOnlyInput
          value={`${form.formatted_response_method || '--'}${
            form.response_method_description
              ? ` - ${form.response_method_description}`
              : ''
          }`}
        />
      }
    />
  );
  const ResponseRemarksInput = (
    <FormControlBlock
      hideBottomLabel={readOnlyResponse}
      label={form.response_method_description && 'Method Remarks'}
      control={
        <TextareaAutosizeWrapper
          placeholder={!readOnlyResponse ? 'Method Remarks' : '--'}
          initialValue={form.response_method_description}
          readOnly={readOnlyResponse}
          disabled={readOnlyResponse}
          valid={!(form.errors && form.errors.response_method_description)}
          onChange={(value) =>
            handleFormChange('response_method_description', value)
          }
        />
      }
    />
  );
  const CCRecipientsInput = (
    <FormControlBlock
      hideBottomLabel={readOnly}
      label={(readOnly || form.ccs.length > 0) && 'CC Recipients'}
      control={
        readOnly ? (
          <RecipientsText recipients={form.ccs} />
        ) : (
          <MultipleDropdown
            className="recipients-dropdown"
            placeholder="CC Recipients"
            options={recipientOptions()}
            currentValues={form.ccs}
            valid={!(form.errors && form.errors.ccs)}
            onChange={(values) => handleFormChange('ccs', values)}
          />
        )
      }
    />
  );
  const AdditionalRecipientsInput = (
    <FormControlBlock
      hideBottomLabel={isPending}
      className={
        isPending && isSubscriber && !showAdditionalRecipients
          ? 'just-button'
          : ''
      }
      label={
        form.addl_recipients.length > 0 &&
        `Additional CC Recipients${
          form.reviewed_by ? ` by ${form.reviewed_by.full_name}` : ''
        }`
      }
      control={
        isPending ? (
          <Fragment>
            {showAdditionalRecipients && (
              <MultipleDropdown
                valid={!(form.errors && form.errors.addl_recipients)}
                onChange={(values) =>
                  handleFormChange('addl_recipients', values)
                }
                currentValues={form.addl_recipients}
                className="recipients-dropdown"
                placeholder="Additional CC Recipients"
                options={recipientOptions()}
              />
            )}
            {!showAdditionalRecipients && (
              <Button
                type={BUTTON_TYPES.LINK}
                icon={BUTTON_ICONS.PLUS}
                label="Add Recipients"
                onClick={handleAddRecipients}
              />
            )}
          </Fragment>
        ) : (
          <RecipientsText recipients={form.addl_recipients} />
        )
      }
    />
  );
  const SubmittedText = (
    <p>
      {getByAtText(
        form.submitted_by || currentUser,
        form.submitted_at,
        'Submitted',
        currentUser.label
      )}
    </p>
  );
  const AcceleratedText = (
    <Fragment>
      <p>
        {getByAtText(form.accelerated_by, form.accelerated_at, 'Accelerated')}
      </p>
      <p className="note">
        Contact user within 48-hours of submission to report any errors
      </p>
    </Fragment>
  );
  const RequestInput = (
    <FormControlBlock
      hideBottomLabel={readOnly}
      label={(readOnly || form.request_description) && 'Request'}
      control={
        <TextareaAutosizeWrapper
          placeholder={readOnly ? '--' : 'Request'}
          initialValue={form.request_description}
          readOnly={readOnly}
          disabled={readOnly}
          className="request-textarea"
          valid={!(form.errors && form.errors.request_description)}
          onChange={(value) => handleFormChange('request_description', value)}
        />
      }
    />
  );
  const ApprovedStatusInput = (
    <FormControlBlock
      hideBottomLabel
      control={
        <RadioButton
          value={form.response_code}
          onChange={(option) => handleFormChange('response_code', option)}
          options={getApprovedStatusOptions()}
          className={'approve-options'}
          orientation={'table'}
          valid={!(form.errors && form.errors.response_code)}
        />
      }
    />
  );
  const approvedRemarksInputReadonly = (!isDraft || readOnly) && isForRecord;
  const ApprovedRemarksInput = (
    <FormControlBlock
      hideBottomLabel={approvedRemarksInputReadonly}
      label={(readOnly || form[inputsAttributes.remarks]) && 'Remarks'}
      control={
        <TextareaAutosizeWrapper
          placeholder={approvedRemarksInputReadonly ? '--' : 'Remarks'}
          initialValue={form[inputsAttributes.remarks]}
          className="request-textarea"
          valid={!(form.errors && form.errors[inputsAttributes.remarks])}
          readOnly={approvedRemarksInputReadonly}
          disabled={approvedRemarksInputReadonly}
          onChange={(value) =>
            handleFormChange(inputsAttributes.remarks, value)
          }
        />
      }
    />
  );
  const filesInputReadonly =
    readOnly &&
    !(isPending && isSubscriber) &&
    !(isSubmitted && isAssignee) &&
    !isAccelerated;
  const DocumentsInput = (
    <FormControlBlock
      hideBottomLabel={filesInputReadonly}
      label="Documents"
      control={
        <DocumentUploadWithPreview
          multiple
          readOnly={filesInputReadonly}
          documents={form[inputsAttributes.documents]}
          onDrop={handleUploadDocument}
          onRemove={handleRemoveDocument}
        />
      }
    />
  );
  const ImagesInput = (
    <FormControlBlock
      hideBottomLabel={filesInputReadonly}
      label="Photos"
      control={
        <ImageUpload
          extensions={['.jpg', '.jpeg', '.gif', '.png', '.heic']}
          images={form[inputsAttributes.images]}
          readOnly={filesInputReadonly}
          onRemove={handleRemoveImage}
          onUpload={handleUploadImage}
          onEditImages={handleEditImages}
        />
      }
    />
  );

  return (
    <>
      <div className="rfa-form">
        <div className="form-block-container">
          <div className="form-block">{TypeInput}</div>
          <div className="form-block grow-block">{TitleInput}</div>
          {!isForRecord && <div className="form-block">{DueDateInput}</div>}
          {readOnly && <div className="form-block">{StatusInput}</div>}
        </div>

        {!readOnly && isForRecord && (
          <Fragment>
            <div className="form-block-container">
              <div className="form-block grow-block">{ResponseByInput}</div>
              <div className="form-block">{ResponseDateInput}</div>
              <div className="form-block">{ResponseTimeInput}</div>
            </div>
            <div className="form-block-container">
              <div className="form-block">{ResponseMethodInput}</div>
              <div className="form-block grow-block">
                {ResponseRemarksInput}
              </div>
            </div>
          </Fragment>
        )}
        {(isBasic ||
          (isPRfa &&
            !(
              isPending &&
              isSubscriber &&
              form.response_code === REASSIGN_STATUS
            )) ||
          (isSubmitted && form.assigned_to)) && (
          <div className="form-block">{AssignToInput}</div>
        )}

        {/* show only if company has multiple trades on project */}
        {isPRfa && isDraft && tradeCodeOptions.length > 1 && (
          <div className="form-block">{TradeCodeInput}</div>
        )}

        {/* For Prfa show only if company has multiple trades on project */}
        <div className="form-block">{CCRecipientsInput}</div>

        {((isPending && isSubscriber) || form.addl_recipients.length > 0) && (
          <div className="form-block">{AdditionalRecipientsInput}</div>
        )}

        <h3>Request For Approval</h3>

        <div className="rfa-resume-status">
          {SubmittedText}
          {isAccelerated && AcceleratedText}
        </div>

        {(readOnly || isBasic) && (
          <h4 className={isApproved && isResponded ? '' : 'tomato'}>
            Approval Request{isForRecord ? ' - Submitted for Record' : ''}
          </h4>
        )}

        {readOnly && isForRecord && (
          <Fragment>
            <div className="form-block grow-block">
              <ByAtText
                at={form.responded_at}
                by={form.responded_by}
                getByAtText={getByAtText}
                label="Response By"
              />
            </div>
            <div className="form-block grow-block">{ResponseMethodText}</div>
          </Fragment>
        )}

        <div className="form-block">{RequestInput}</div>

        {!isForRecord && (isPending || isSubmitted || isApproved) && (
          <div className="form-block-container">
            {form.request_documents.length > 0 && (
              <div className="form-block half-block">
                <DocumentsPreview value={form.request_documents} />
              </div>
            )}
            {form.request_images.length > 0 && (
              <div className="form-block half-block">
                <ImagesPreview value={form.request_images} />
              </div>
            )}
          </div>
        )}

        {isReviewed && (
          <>
            <div className="form-block">
              <ByAtText
                by={form.reviewed_by}
                at={form.reviewed_at}
                getByAtText={getByAtText}
              />
            </div>
            <div className="form-block">
              <RemarksText value={form.review_description} />
            </div>

            <div className={'form-block-container'}>
              {form.review_documents.length > 0 && (
                <div className="form-block half-block">
                  <DocumentsPreview
                    value={form.review_documents}
                    label={'Documents'}
                  />
                </div>
              )}
              {form.review_images.length > 0 && (
                <div className="form-block half-block">
                  <ImagesPreview value={form.review_images} label={'Photos'} />
                </div>
              )}
            </div>
          </>
        )}

        {((isAccelerated && isSubmitted) ||
          (isPending && isSubscriber) ||
          (isReviewed && isSubmitted && isAssignee) ||
          (isResponded && isApproved)) && (
          <h4 className={isApproved ? 'tomato' : ''}>
            {isAccelerated ? 'Accelerated ' : ''}Approval Response
          </h4>
        )}

        {isSubmitted && isAccelerated && (
          <Fragment>
            <div className="form-block-container">
              <div className="form-block grow-block">{ResponseByInput}</div>
              <div className="form-block">{ResponseDateInput}</div>
              <div className="form-block">{ResponseTimeInput}</div>
            </div>

            <div className="form-block-container">
              <div className="form-block">{ResponseMethodInput}</div>
              <div className="form-block grow-block">
                {ResponseRemarksInput}
              </div>
            </div>
          </Fragment>
        )}

        {!isForRecord && isResponded && isApproved && (
          <Fragment>
            <div className="form-block">
              <ByAtText
                by={form.responded_by}
                at={form.responded_at}
                getByAtText={getByAtText}
                label={
                  isAccelerated ? 'Accelerated Response By' : 'Response By'
                }
              />
            </div>
            {isAccelerated && (
              <div className="form-block grow-block">{ResponseMethodText}</div>
            )}
            <div className="form-block">
              <RemarksText
                value={form.formatted_response_code}
                label={'Approval Response'}
              />
            </div>
            <div className="form-block">
              <RemarksText value={form.response_description} />
            </div>
          </Fragment>
        )}

        {(isForRecord ||
          (isSubmitted && (isAssignee || isAccelerated)) ||
          (isPending && isSubscriber)) && (
          <Fragment>
            {!approvedRemarksInputReadonly && (
              <div className="form-block approved-status">
                {ApprovedStatusInput}
              </div>
            )}

            {form.response_code === REASSIGN_STATUS && (
              <div className="form-block">{AssignToInput}</div>
            )}

            <div className="form-block">{ApprovedRemarksInput}</div>
          </Fragment>
        )}

        {(!filesInputReadonly ||
          form[inputsAttributes.documents].length > 0 ||
          form[inputsAttributes.images].length > 0) && (
          <div
            className={`form-block-container ${
              isForRecord && 'documents-block'
            }`}
          >
            {(!filesInputReadonly ||
              form[inputsAttributes.documents].length > 0) && (
              <div className="form-block half-block">{DocumentsInput}</div>
            )}
            {(!filesInputReadonly ||
              form[inputsAttributes.images].length > 0) && (
              <div className="form-block half-block">{ImagesInput}</div>
            )}
          </div>
        )}
      </div>
      <div className="rfa-actions">
        {showFormError && (
          <h5 className="error-message">
            There are missing fields, please review.
          </h5>
        )}
        <div className="rfa-form-actions">
          {((!readOnly && isDraft) ||
            (isPending && isSubscriber) ||
            (isSubmitted && isAssignee) ||
            (isSubmitted && isAccelerated)) && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Cancel"
              onClick={handleCancel}
            />
          )}

          {!readOnly && isDraft && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Save Draft"
              onClick={handleSaveDraft}
            />
          )}

          {((!readOnly && isDraft) ||
            (isPending && isSubscriber) ||
            (isSubmitted && (isAssignee || isAccelerated))) && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label={'Submit'}
              onClick={handleSave}
            />
          )}

          {readOnly &&
            isSubmitted &&
            !isAccelerated &&
            !isAssignee &&
            isSubscriber && (
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label="Accelerate"
                onClick={handleAccelerate}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    currentUser: getCurrentUser(state),
    readOnly: getRfaReadOnly(state),
    form: getRfaForm(state),
    directory: getDirectory(state),
    currentRfa: getCurrentRfa(state),
    projectProposal: getProjectProposal(state),
    csiCodes: getCsiCodes(state),
  };
})(RfaForm);
