import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { downloadFile } from 'services/utils/download-util';

import FormModal from 'components/shared/modal/FormModal';
import RfaNotes from 'components/admin/projects/details/project-rfa-log/RfaNotes';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import RfaForm from './RfaForm';

import {
  getShowRfaModal,
  getCurrentRfa,
  getRfaReadOnly,
  getRfaForm,
} from 'components/admin/projects/details/project-rfa-log/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import {
  closeRfa,
  setRfaReadOnly,
  setRfaLogPdf,
  startRfaRevision,
  setShowDeleteConfirmation,
} from 'components/admin/projects/details/project-rfa-log/store/actions';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';

import Rfa from 'domain/rfa';
import RfaPermissions from 'permissions/rfa-permissions';

const ModalRfa = (props) => {
  const {
    dispatch,
    currentUser,
    currentProject,
    currentRfa,
    form,
    show,
    readOnly,
    onClose,
  } = props;

  if (!currentProject) return null;

  const pdfUrl = currentRfa && Rfa.getPdfUrl(currentRfa);

  const handleClose = () => {
    dispatch(closeRfa());
    if (onClose) onClose();
  };
  const handleEdit = () => dispatch(setRfaReadOnly(false));
  const handleRevise = () =>
    dispatch(startRfaRevision(currentUser, currentProject, currentRfa.id));
  const handleSendEmail = () => dispatch(setShowEmailModal(true));
  const handleOpenPdf = () => {
    dispatch(setRfaLogPdf(`${pdfUrl}?time=${moment().unix()}`, true));
  };
  const handleDownload = () => {
    const { name } = currentProject;
    const { number } = currentRfa;

    const file = `${pdfUrl}?time=${moment().unix()}`;
    const fileName = `${name} - RFA ${number ? number : 'Draft'}`;
    downloadFile(file, fileName);
  };
  const handleDelete = () => dispatch(setShowDeleteConfirmation(true));

  const renderRfaActions = () => {
    const actions = [];
    if (readOnly && currentRfa) {
      if (RfaPermissions.canEdit(currentUser, currentProject, currentRfa))
        actions.push({ icon: BUTTON_ICONS.EDIT, action: handleEdit });
      if (RfaPermissions.canRevise(currentUser, currentProject, currentRfa))
        actions.push({ icon: BUTTON_ICONS.REVISE, action: handleRevise });
      if (RfaPermissions.canEmail(currentUser, currentProject, currentRfa))
        actions.push({ icon: BUTTON_ICONS.EMAIL, action: handleSendEmail });
      if (pdfUrl) {
        actions.push({ icon: BUTTON_ICONS.PDF, action: handleOpenPdf });
        actions.push({ icon: BUTTON_ICONS.DOWNLOAD, action: handleDownload });
      }
      if (RfaPermissions.canDelete(currentUser, currentProject, currentRfa))
        actions.push({ icon: BUTTON_ICONS.DELETE_BIN, action: handleDelete });
    }

    return actions.map((a, index) => (
      <Button
        key={`action_${index}`}
        type={BUTTON_TYPES.LINK}
        color={BUTTON_COLORS.GREEN}
        label={a.label}
        icon={a.icon}
        onClick={a.action}
      />
    ));
  };

  return (
    <FormModal
      className={`rfi-modal ${!currentRfa ? 'create-rfi' : ''}`}
      show={show}
      onClose={handleClose}
      subTitle={`${currentProject.name} - RFA ${
        currentRfa && currentRfa.number
          ? currentRfa.formatted_number
          : form.revised_rfa_id
          ? 'Revision'
          : 'Draft'
      }`}
      sidebar={<RfaNotes />}
      actions={renderRfaActions()}
    >
      <RfaForm />
    </FormModal>
  );
};

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    currentRfa: getCurrentRfa(state),
    form: getRfaForm(state),
    show: getShowRfaModal(state),
    readOnly: getRfaReadOnly(state),
  };
})(ModalRfa);
