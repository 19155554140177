import React from 'react';
import Project from 'domain/project';

export class DailyReportUtil {
  static tradeLabel = ({ csi_code: csiCode, company }) => (
    <span>
      {Project.csiCodeLabel(csiCode)} - <b>{company.name}</b>
    </span>
  );
}
