import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import './NewClientModal.css';
import FormModal from 'components/shared/modal/FormModal';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import {
  getClientForm,
  getShowNewClientModal,
  getClients,
} from 'components/admin/projects/creation/store/selectors';
import Input from 'components/shared/input/Input';
import {
  setClientFormValue,
  findSimilarClient,
  createClient,
  inviteExistentClient,
  setShowNewClientModal,
  cleanClientFormValues,
  addClientToBeginning,
} from 'components/admin/projects/creation/store/actions';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import FormControlBlock from 'components/shared/form/FormControlBlock';

class NewClientModal extends Component {
  constructor(props) {
    super(props);
    props.dispatch(cleanClientFormValues());
    this.state = {
      confirmSimilarClient: null,
    };
  }

  handleClientFormChange = (attribute, value) => {
    this.props.dispatch(setClientFormValue(attribute, value));
  };

  handleClose = () => {
    this.props.dispatch(setShowNewClientModal(false));
  };

  handleCreateClient = (existentClientId) => {
    this.props
      .dispatch(
        existentClientId
          ? inviteExistentClient(existentClientId)
          : createClient(this.props.form)
      )
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(addClientToBeginning(response.client));
          this.props.dispatch(setShowNewClientModal(false));
        }
      });
  };

  handleSimilarClient = (client, similarMsg) => {
    const message = `${similarMsg} Do you want to use it?`;
    this.setState({
      confirmSimilarClient: { clientId: client.id, message: message },
    });
  };

  similarClientConfirmed = (confirmed) => {
    const clientId = this.state.confirmSimilarClient.clientId;
    this.setState({ confirmSimilarClient: null });
    this.handleCreateClient(confirmed ? clientId : null);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(findSimilarClient(this.props.form)).then((response) => {
      if (response.ok) {
        if (response.client) {
          this.handleSimilarClient(response.client, response.message);
        } else {
          this.handleCreateClient();
        }
      }
    });
  };

  clientFormValid = () => {
    const clientForm = this.props.form;
    return (
      !!clientForm.name &&
      !!clientForm.firstName &&
      !!clientForm.lastName &&
      !!clientForm.email &&
      !!clientForm.phoneNumber
    );
  };

  render() {
    const { confirmSimilarClient } = this.state;
    const {
      form: { name, firstName, lastName, email, phoneNumber },
    } = this.props;
    return (
      <div id="new-client-form-modal">
        <FormModal
          className="new-client-modal"
          show={this.props.showNewClientModal}
          subTitle={'New Owner Information'}
          onClose={this.handleClose}
        >
          <Form horizontal onSubmit={this.handleSubmit}>
            <div className="form-block">
              <FormControlBlock
                label={!!name && 'Company Name'}
                control={
                  <Input
                    id="company-name"
                    placeholder="Company Name"
                    value={name}
                    onChange={this.handleClientFormChange.bind(this, 'name')}
                  />
                }
              />
            </div>

            <div className="form-block">
              <FormControlBlock
                label={!!firstName && 'First Name'}
                control={
                  <Input
                    id="first-name"
                    placeholder="First Name"
                    value={firstName}
                    onChange={this.handleClientFormChange.bind(
                      this,
                      'firstName'
                    )}
                  />
                }
              />
            </div>

            <div className="form-block">
              <FormControlBlock
                label={!!lastName && 'Last Name'}
                control={
                  <Input
                    id="last-name"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={this.handleClientFormChange.bind(
                      this,
                      'lastName'
                    )}
                  />
                }
              />
            </div>

            <div className="form-block">
              <FormControlBlock
                label={!!email && 'Email'}
                control={
                  <Input
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={this.handleClientFormChange.bind(this, 'email')}
                  />
                }
              />
            </div>

            <div className="form-block">
              <FormControlBlock
                label={!!phoneNumber && 'Phone Number'}
                control={
                  <Input
                    id="phone-number"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={this.handleClientFormChange.bind(
                      this,
                      'phoneNumber'
                    )}
                  />
                }
              />
            </div>

            <div className="buttons-container">
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label="Add"
                onClick={this.handleSubmit}
                disabled={!this.clientFormValid()}
              />
            </div>
          </Form>
          {confirmSimilarClient && (
            <ConfirmationModal
              show={confirmSimilarClient ? true : false}
              message={confirmSimilarClient.message}
              onHide={this.similarClientConfirmed}
            />
          )}
        </FormModal>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    form: getClientForm(state),
    showNewClientModal: getShowNewClientModal(state),
    clients: getClients(state),
  };
})(NewClientModal);
