export const rfaTabs = [
  { id: 1, title: 'P-RFA Log' },
  { id: 2, title: 'RFA Log' },
];
export const PRFA_TAB = 1;

export const dueFilterOptions = [
  { id: 'all', label: 'All' },
  { id: 'ascending', label: 'Ascending' },
  { id: 'descending', label: 'Descending' },
  { id: 'overdue', label: 'Overdue' },
];
export const DEFAULT_DUE_OPTION = 'all';

export const statusFilterOptions = [
  { id: 'all', label: 'All' },
  { id: 'draft', label: 'Draft' },
  { id: 'pending', label: 'Pending' },
  { id: 'submitted', label: 'Submitted' },
  { id: 'for_record', label: 'For Record' },
  { id: 'approved', label: 'Approved' },
  { id: 'approved_as_noted', label: 'Approved as Noted' },
  { id: 'revise', label: 'Revise' },
  { id: 'rejected', label: 'Rejected' },
  { id: 'discarded', label: 'Deleted' },
];
export const DEFAULT_STATUS_OPTION = 'all';

export const rfaTypes = [
  { id: '1a', value: 'budget', label: 'Budget' },
  { id: '1b', value: 'contract', label: 'Contract' },
  { id: '1c', value: 'change_order', label: 'Change Order' },
  { id: '1d', value: 'invoice', label: 'Invoice' },
  { id: '2', value: 'schedule', label: 'Schedule' },
  { id: '3', value: 'design', label: 'Design' },
  { id: '4', value: 'other_type', label: 'Other' },
];

export const responseMethodsOptions = [
  { value: 'in_person', label: 'In Person' },
  { value: 'phone_conference', label: 'Phone Conference' },
  { value: 'web_conference', label: 'Web Conference' },
  { value: 'email_response', label: 'Email' },
  { value: 'sms_response', label: 'Text Message' },
  { value: 'other_response', label: 'Other' },
];
export const OTHER_RESPONSE_METHOD = 'other';

export const approveStatusOptions = [
  { value: 'approved', label: 'Approved' },
  { value: 'approved_as_noted', label: 'Approved as Noted' },
  { value: 'revise', label: 'Revise' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'reassign', label: 'Reassign' },
];
export const APPROVED_STATUS = 'approved';
export const REASSIGN_STATUS = 'reassign';

export const rfaVariants = [
  { value: 'prfa', label: 'P-RFA' },
  { value: 'basic', label: 'RFA' },
];

export const DEFAULT_PRE_OPTION = false;

class Rfa {
  getPdfUrl = (rfa) =>
    (rfa &&
      rfa.pdf_document &&
      rfa.pdf_document.document &&
      rfa.pdf_document.document.url) ||
    null;

  isDraft = ({ status }) => (status || '').indexOf('draft') !== -1;

  isPending = ({ status }) => status === 'pending';

  isDeleted = ({ status }) => status === 'discarded';

  isClosed = ({ status }) => ['resolved', 'for_record'].includes(status);
}

export default new Rfa();
