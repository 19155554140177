import { SubmittalStatusEnum } from './submittal-status-enum';
import User from './user';

export default class Submittal {
  static addlAssignee(submittal, user) {
    if (!submittal || !submittal.submittal_addl_assignees) return null;

    return submittal.submittal_addl_assignees.find(
      (addlAssignee) => addlAssignee.assignee_id === user.id
    );
  }

  static assignee(submittal, user) {
    if (!submittal) return false;

    return (
      submittal.assignee_id === user.id || !!this.addlAssignee(submittal, user)
    );
  }

  static responded(submittal, user) {
    if (!submittal) return false;

    const addlAssignee = this.addlAssignee(submittal, user);
    return (
      submittal.responded_by_id === user.id ||
      !!(addlAssignee && addlAssignee.stamp_action)
    );
  }

  static readOnly(submittal, user) {
    if (!submittal) return false;

    return (
      !this.assignee(submittal, user) ||
      !!submittal.closed ||
      this.responded(submittal, user)
    );
  }

  static isDraft = (status) =>
    SubmittalStatusEnum.DRAFT.equals(SubmittalStatusEnum.create(status));

  static isPending = (status) =>
    SubmittalStatusEnum.PENDING.equals(SubmittalStatusEnum.create(status));

  static isDraftPending = (status) =>
    SubmittalStatusEnum.DRAFT_PENDING.equals(
      SubmittalStatusEnum.create(status)
    );

  static isAssigned = (status) =>
    SubmittalStatusEnum.ASSIGNED.equals(SubmittalStatusEnum.create(status));

  static isDraftAssigned = (status) =>
    SubmittalStatusEnum.DRAFT_ASSIGNED.equals(
      SubmittalStatusEnum.create(status)
    );

  static isDiscarded = (status) =>
    SubmittalStatusEnum.DISCARDED.equals(SubmittalStatusEnum.create(status));

  static isDiscartable = (status) => {
    const statusInstance = SubmittalStatusEnum.create(status);
    return (
      SubmittalStatusEnum.DRAFT.equals(statusInstance) ||
      SubmittalStatusEnum.PENDING.equals(statusInstance) ||
      SubmittalStatusEnum.DRAFT_PENDING.equals(statusInstance)
    );
  };

  static isDraftOrPending = (status) => {
    const statusInstance = SubmittalStatusEnum.create(status);
    return (
      SubmittalStatusEnum.DRAFT.equals(statusInstance) ||
      SubmittalStatusEnum.PENDING.equals(statusInstance)
    );
  };

  static isPendingOrAssigned = (status) => {
    const statusInstance = SubmittalStatusEnum.create(status);
    return (
      SubmittalStatusEnum.PENDING.equals(statusInstance) ||
      SubmittalStatusEnum.ASSIGNED.equals(statusInstance) ||
      SubmittalStatusEnum.DRAFT_PENDING.equals(statusInstance) ||
      SubmittalStatusEnum.DRAFT_ASSIGNED.equals(statusInstance)
    );
  };

  static isApprovedOrAsNoted = (status) => {
    const statusInstance = SubmittalStatusEnum.create(status);
    return (
      SubmittalStatusEnum.APPROVED.equals(statusInstance) ||
      SubmittalStatusEnum.APPROVED_AS_NOTED.equals(statusInstance)
    );
  };

  static isAssignedToUser = (user, submittal) =>
    Submittal.assignee(submittal, user);

  static isAddlAssignedToUser = (user, submittal) =>
    Submittal.addlAssignee(submittal, user);

  static isAssignedOrAddlAssignedToUser = (user, submittal) =>
    this.isAssignedToUser(submittal, user) ||
    this.isAddlAssignedToUser(submittal, user);

  static isSubcontractorUser = (user, project, submittal) =>
    !!submittal &&
    user.accountable_id === submittal.contractor_id &&
    User.isContractor(user, project);

  static isUserResponse = (user, submittal, gcAcceleration) =>
    submittal &&
    (gcAcceleration || this.isAssignedOrAddlAssignedToUser(user, submittal));

  static isUserResponder = (user, submittal) => {
    if (submittal && submittal.responded_by_id === user.id) return true;

    const addlAssignee = Submittal.addlAssignee(submittal, user);
    return !!addlAssignee && !!addlAssignee.stamp_action;
  };

  static isSubmittedByUserCompany = (user, submittal) =>
    !!submittal &&
    !!submittal.submitted_by &&
    User.isSameCompany(user, submittal.submitted_by);

  static isSubmittedByGcOar = (directory, submittal) =>
    !!submittal &&
    !!submittal.submitted_by &&
    User.isGcOar(User.userContributor(directory, submittal.submitted_by));

  static isNewRevision = (submittal) => !!submittal && !!submittal.newRevision;
}
