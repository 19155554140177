import FileSaver from 'file-saver';

/**
 *
 * @param file
 * @param type
 * @return {*}
 */
export function downloadFile(file, fileName, type = 'url') {
  if (typeof file !== 'undefined' && typeof fileName !== 'undefined') {
    if (type === 'url' || type === 'blob') {
      FileSaver.saveAs(file, fileName);
    }
  }
}
