import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import './ProjectDailyReportLog.css';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import {
  getDailyReports,
  getShowAddDailyReportModal,
  getShowProjectReportPermissionsModal,
  getDailyReportLogPdf,
  getDailyReportLogPdfViewer,
  getCurrentPage,
  getReportsPageSize,
  getReportsTotal,
  getDailyReportsTabSelected,
  getFieldWorkOrders,
  getShowFieldWorkOrderModal,
  getProjectReportPdfViewer,
  getFieldProjectReports,
  getprojectReportOpenDetails,
  getLastProjectReport,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { loadSettings } from 'components/admin/projects/details/project-settings/store/actions';
import {
  loadDailyReportsRequest,
  loadFieldWorkOrdersRequest,
  setShowDailyReportModal,
  setShowProjectReportPermissionsModal,
  loadDailyReportLogPdfRequest,
  setDailyReportLogPdfViewer,
  setDailyReportTab,
  setProjectReportPdfViewer,
  loadProjectReportsRequest,
  setProjectReportOpenDetails,
  loadProjectReportsLogPdfRequest,
  setProjectReportsPermissions,
  addProjectReport,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import { loadFieldReportsRequest } from 'components/admin/projects/details/project-daily-report-log/field-reports/store/actions';
import DailyReportLogTable from 'components/admin/projects/details/project-daily-report-log/DailyReportLogTable';
import FieldWorkOrderLogTable from 'components/admin/projects/details/project-daily-report-log/FieldWorkOrderLogTable';
import FieldProjectReportLogTable from 'components/admin/projects/details/project-daily-report-log/FieldProjectReportLogTable';
import FieldReportTable from 'components/admin/projects/details/project-daily-report-log/field-reports/FieldReportTable';
import AddDailyReportModal from 'components/admin/projects/details/project-daily-report-log/AddDailyReportModal';
import ProjectReportPermissionsModal from 'components/admin/projects/details/project-daily-report-log/ProjectReportPermissionsModal';
import FieldWorkOrderModal from 'components/admin/projects/details/project-daily-report-log/FieldWorkOrderModal';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import AddProjectReportLog from 'components/admin/projects/details/project-daily-report-log/AddProjectReportLog';
import DailyReportPermissions from 'permissions/daily-report-permissions';
import ProjectReportPermissions from 'permissions/project-report-permissions';
import {
  loadFieldReportsLogs,
  setCurrentFieldReport,
  setFieldReportForm,
} from './field-reports/store/actions';
import {
  getFieldReportFilters,
  getFieldReportForm,
} from './field-reports/store/selectors';
import { withRouter } from 'react-router-dom';
import FieldReportTabMenu from './field-reports/FieldReportTabMenu';
import FieldReportForm from './field-reports/FieldReportForm';

const DailyReportTabValue = 1;
const SubDailyReportTabValue = 2;
const FieldWorkOrdersTabValue = 3;
const FieldProjectReportTabValue = 4;
const FieldReportTabValue = 5;

class ProjectDailyReportLog extends Component {
  constructor(props) {
    super(props);
    this.state = { reportTabValue: 1 };
  }

  componentDidMount() {
    const { dispatch, currentUser, currentProject, location } = this.props;

    const state =
      location && (location.state || queryString.parse(location.search));

    if (!currentProject) return;

    if (
      DailyReportPermissions.canSetMultipleFloors(currentUser, currentProject)
    )
      dispatch(loadSettings(currentProject.id));

    if (state && Object.keys(state).length > 0 && state.fieldReports) {
      this.handleDailyReportsTabSelection(FieldReportTabValue);
      this.handleLoadFieldReports({ page: 1 });
      if (state.form) this.handleOpenAddFieldReport();
    } else if (state && Object.keys(state).length > 0 && state.dailyReportId) {
      const tab = state.subDailyReport
        ? SubDailyReportTabValue
        : DailyReportTabValue;
      this.handleDailyReportsTabSelection(tab);
    } else {
      if (this.isViewProjectReport()) {
        this.handleDailyReportsTabSelection(FieldProjectReportTabValue);
        dispatch(loadProjectReportsRequest(currentProject.id, 1, ''));
        dispatch(setProjectReportOpenDetails({ value: false, id: 0 }));
      } else {
        const tab = !DailyReportPermissions.canView(currentUser, currentProject)
          ? SubDailyReportTabValue
          : DailyReportTabValue;
        this.handleDailyReportsTabSelection(tab);
      }
    }
  }

  handleOpenReportModal = () => {
    this.props.dispatch(setShowDailyReportModal(true));
  };

  handleOpenProjectReportPermissionsModal = () => {
    this.props.dispatch(setShowProjectReportPermissionsModal(true));
  };

  handleLogPdfViewerOpen = () => {
    const { currentProject, dispatch, dailyReportsTabSelected } = this.props;
    const showActionsProjectReport = this.isFieldProjectReportTab();

    if (showActionsProjectReport) {
      dispatch(loadProjectReportsLogPdfRequest(currentProject.id)).then(
        (response) => {
          if (response.ok) {
            dispatch(setDailyReportLogPdfViewer(true));
          }
        }
      );
    } else if (this.isFieldReportTab()) {
      dispatch(loadFieldReportsLogs(currentProject.id)).then((response) => {
        if (response.ok) dispatch(setDailyReportLogPdfViewer(true));
      });
    } else {
      dispatch(
        loadDailyReportLogPdfRequest(
          currentProject.id,
          dailyReportsTabSelected === SubDailyReportTabValue
        )
      ).then((response) => {
        if (response.ok) {
          dispatch(setDailyReportLogPdfViewer(true));
        }
      });
    }
  };

  handleLogPdfViewerClose = () => {
    this.props.dispatch(setDailyReportLogPdfViewer(false));
    this.props.dispatch(setProjectReportPdfViewer(false));
  };

  isEditProjectReport = () => {
    const { currentUser, currentProject } = this.props;
    return ProjectReportPermissions.canEdit(currentUser, currentProject);
  };

  isViewProjectReport = () => {
    const { currentUser, currentProject } = this.props;
    return ProjectReportPermissions.canView(currentUser, currentProject);
  };

  isFieldWorkOrderTab = () => {
    const { dailyReportsTabSelected } = this.props;
    return dailyReportsTabSelected === FieldWorkOrdersTabValue;
  };

  isFieldReportTab = () => {
    const { dailyReportsTabSelected } = this.props;
    return dailyReportsTabSelected === FieldReportTabValue;
  };

  isFieldProjectReportTab = () => {
    const { dailyReportsTabSelected } = this.props;
    return dailyReportsTabSelected === FieldProjectReportTabValue;
  };

  handlePageChange = (newPage) => {
    const {
      currentUser,
      currentProject,
      dispatch,
      dailyReportsTabSelected,
    } = this.props;
    if (this.isFieldWorkOrderTab()) {
      dispatch(
        loadFieldWorkOrdersRequest(
          currentProject.id,
          newPage,
          DailyReportPermissions.canViewFieldWorkOrdersGcStage(
            currentUser,
            currentProject
          )
        )
      );
    }
    if (this.isFieldProjectReportTab()) {
      dispatch(loadProjectReportsRequest(currentProject.id, newPage));
    } else if (this.isFieldReportTab()) {
      this.handleLoadFieldReports({ page: newPage });
    } else {
      dispatch(
        loadDailyReportsRequest(
          currentProject.id,
          newPage,
          SubDailyReportTabValue === dailyReportsTabSelected
        )
      );
    }
  };

  handleLoadProjectReports = (page, filter) => {
    const { dispatch, currentProject } = this.props;
    if (currentProject) {
      dispatch(loadProjectReportsRequest(currentProject.id, page, filter));
    }
    dispatch(setProjectReportOpenDetails({ value: false, id: 0 }));
  };

  handleLoadFieldReports = (filters) => {
    const { fieldReportFilters, currentProject, dispatch } = this.props;
    const newFilters = { ...fieldReportFilters, ...filters };
    if (typeof filters.page === 'undefined') newFilters.page = 1;
    dispatch(loadFieldReportsRequest(currentProject.id, newFilters));
  };

  handleDailyReportsTabSelection = (reportTab) => {
    this.setState({ reportTabValue: reportTab });
    const { dispatch, currentUser, currentProject } = this.props;
    dispatch(setDailyReportTab(reportTab));
    if (reportTab === FieldWorkOrdersTabValue) {
      dispatch(
        loadFieldWorkOrdersRequest(
          currentProject.id,
          1,
          DailyReportPermissions.canViewFieldWorkOrdersGcStage(
            currentUser,
            currentProject
          )
        )
      );
    } else if (reportTab === FieldProjectReportTabValue) {
      this.handleLoadProjectReports(1, null);
      dispatch(
        setProjectReportsPermissions(currentProject.project_report_permissions)
      );
    } else if (reportTab === FieldReportTabValue) {
    } else {
      dispatch(
        loadDailyReportsRequest(
          currentProject.id,
          1,
          SubDailyReportTabValue === reportTab
        )
      );
    }
  };

  handleOpenAddProjectReport = () => {
    const { currentProject, dispatch } = this.props;
    dispatch(addProjectReport(currentProject.id));
  };

  handleCloseAddProjectReport = () => {
    const { dispatch } = this.props;
    dispatch(setProjectReportOpenDetails({ value: false, id: 0 }));
    this.setState({ reportTabValue: FieldProjectReportTabValue });
  };

  handleOpenAddFieldReport = () => {
    this.props.dispatch(setCurrentFieldReport(null));
    this.props.dispatch(setFieldReportForm(true));
  };

  handleCloseAddFieldReport = () => {
    this.props.dispatch(setFieldReportForm(false));
    this.setState({ reportTabValue: FieldReportTabValue });
    this.handleLoadFieldReports({ page: 1 });
  };

  buildTabs() {
    const { dailyReportsTabSelected, currentUser, currentProject } = this.props;
    const activeTabs = [];
    const canSeeDailyReports = DailyReportPermissions.canView(
      currentUser,
      currentProject
    );
    const canSeeSubDailyReports = DailyReportPermissions.canViewSub(
      currentUser,
      currentProject
    );
    const canSeeEwos = DailyReportPermissions.canViewEwos(
      currentUser,
      currentProject
    );

    if (this.isViewProjectReport()) {
      activeTabs.push({
        id: FieldProjectReportTabValue,
        title: 'Project Reports',
        class: this.isFieldProjectReportTab() ? 'sel' : '',
      });
    }

    if (canSeeDailyReports) {
      activeTabs.push({
        id: DailyReportTabValue,
        title: 'Daily Reports',
        class: dailyReportsTabSelected === DailyReportTabValue ? 'sel' : '',
      });
    }

    if (canSeeSubDailyReports) {
      activeTabs.push({
        id: SubDailyReportTabValue,
        title: canSeeDailyReports ? 'Sub Daily Reports' : 'Daily Reports',
        class: dailyReportsTabSelected === SubDailyReportTabValue ? 'sel' : '',
      });
    }

    if (canSeeEwos) {
      activeTabs.push({
        id: FieldWorkOrdersTabValue,
        title: 'EWOs',
        class: this.isFieldWorkOrderTab() ? 'sel' : '',
      });
    }

    activeTabs.push({
      id: FieldReportTabValue,
      title: 'Field Reports',
      class: this.isFieldReportTab() ? 'sel' : '',
      render: (props) => <FieldReportTabMenu {...props} />,
    });

    return activeTabs;
  }

  renderHeaderButtons() {
    const {
      dailyReportsTabSelected,
      currentUser,
      currentProject,
      currentPage,
    } = this.props;
    const canAdd =
      DailyReportPermissions.canAddSub(currentUser, currentProject) ||
      (DailyReportPermissions.canAdd(currentUser, currentProject) &&
        dailyReportsTabSelected === DailyReportTabValue);
    const showActionsProjectReport =
      this.isFieldProjectReportTab() && this.isEditProjectReport();

    return [
      canAdd && !this.isFieldWorkOrderTab() && !this.isFieldReportTab() && (
        <Button
          key="add-submittal"
          type={BUTTON_TYPES.LINK}
          icon={BUTTON_ICONS.PLUS}
          label="Add Daily Report"
          onClick={this.handleOpenReportModal}
        />
      ),
      showActionsProjectReport && currentPage === 1 && (
        <Button
          key="add-project-report"
          type={BUTTON_TYPES.LINK}
          icon={BUTTON_ICONS.PLUS}
          label="Add Report"
          onClick={this.handleOpenAddProjectReport}
        />
      ),
      this.isFieldReportTab() && (
        <Button
          key="add-field-report"
          type={BUTTON_TYPES.LINK}
          icon={BUTTON_ICONS.PLUS}
          label="Add Report"
          onClick={this.handleOpenAddFieldReport}
        />
      ),
      !this.isFieldWorkOrderTab() &&
        DailyReportPermissions.canViewLog(currentUser, currentProject) && (
          <Button
            key="view-log"
            type={BUTTON_TYPES.LINK}
            color={BUTTON_COLORS.GREEN}
            label="View Log"
            onClick={this.handleLogPdfViewerOpen}
          />
        ),
      showActionsProjectReport && (
        <Button
          key="permission"
          type={BUTTON_TYPES.LINK}
          color={BUTTON_COLORS.GREEN}
          label="Permission"
          onClick={this.handleOpenProjectReportPermissionsModal}
        />
      ),
    ].filter((component) => component);
  }

  render() {
    const {
      currentUser,
      currentProject,
      dailyReports,
      showAddDailyReportModal,
      showProjectReportPermissionsModal,
      dailyReportLogPdf,
      dailyReportLogPdfViewer,
      currentPage,
      reportsPageSize,
      reportsTotal,
      dailyReportsTabSelected,
      fieldWorkOrders,
      showFieldWorkOrderModal,
      renderHeaders,
      projectReportPdf,
      projectReportPdfViewer,
      fieldProjectReports,
      projectReportOpenDetails,
      lastProjectReport,
      showFieldReportFormModal,
    } = this.props;
    const newReportNumber =
      (lastProjectReport ? lastProjectReport.number : 0) + 1;
    const projectReportData = fieldProjectReports.filter(
      (report) => report.status !== 'deleted'
    );

    return (
      <React.Fragment>
        {currentProject &&
          !projectReportOpenDetails.value &&
          !showFieldReportFormModal && (
            <div className="content-container daily-report-log">
              {renderHeaders(this.renderHeaderButtons())}
              <div className="daily-report-log-navigation">
                <NavigationBar
                  items={this.buildTabs()}
                  onSelected={this.handleDailyReportsTabSelection}
                  selectedValue={this.state.reportTabValue}
                />
              </div>
              <div className="daily-report-log-table-container">
                {this.isFieldProjectReportTab() && (
                  <FieldProjectReportLogTable
                    fieldProjectReports={projectReportData}
                    onLoad={(filter) =>
                      this.handleLoadProjectReports(1, filter)
                    }
                  />
                )}
                {this.isFieldWorkOrderTab() && (
                  <FieldWorkOrderLogTable
                    fieldWorkOrders={fieldWorkOrders}
                    canSeeDailyReports={DailyReportPermissions.canView(
                      currentUser,
                      currentProject
                    )}
                  />
                )}
                {this.isFieldReportTab() && (
                  <FieldReportTable
                    handleFilterChange={this.handleLoadFieldReports}
                  />
                )}
                {!this.isFieldWorkOrderTab() &&
                  !this.isFieldProjectReportTab() &&
                  !this.isFieldReportTab() && (
                    <DailyReportLogTable
                      dailyReports={dailyReports}
                      isSubTabSelected={
                        dailyReportsTabSelected === SubDailyReportTabValue
                      }
                      isSubDailyReport={
                        !DailyReportPermissions.canView(
                          currentUser,
                          currentProject
                        )
                      }
                    />
                  )}
                <div className="daily-report-pagination-container">
                  <SimplePagination
                    currentPage={currentPage}
                    pageSize={reportsPageSize}
                    total={reportsTotal}
                    onPageChange={this.handlePageChange}
                  />
                </div>
              </div>
              {showAddDailyReportModal && (
                <AddDailyReportModal
                  projectId={currentProject.id}
                  show={showAddDailyReportModal}
                />
              )}
              {showProjectReportPermissionsModal && (
                <ProjectReportPermissionsModal
                  projectId={currentProject.id}
                  show={showProjectReportPermissionsModal}
                />
              )}
              {dailyReportLogPdfViewer && (
                <PdfViewer
                  content={{
                    source: dailyReportLogPdf,
                    type: 'blob',
                  }}
                  show={dailyReportLogPdfViewer}
                  onClose={this.handleLogPdfViewerClose}
                />
              )}
              {projectReportPdfViewer && (
                <PdfViewer
                  content={{
                    source: projectReportPdf,
                    type: 'blob',
                  }}
                  show={projectReportPdfViewer}
                  onClose={this.handleLogPdfViewerClose}
                />
              )}
              {showFieldWorkOrderModal && <FieldWorkOrderModal />}
            </div>
          )}
        {currentProject && projectReportOpenDetails.value && (
          <div className="content-container">
            <AddProjectReportLog
              projectId={currentProject.id}
              onClose={this.handleCloseAddProjectReport}
              number={projectReportOpenDetails.id === 0 && newReportNumber}
              projectReportId={projectReportOpenDetails.id}
            />
          </div>
        )}
        {currentProject && showFieldReportFormModal && (
          <FieldReportForm onClose={this.handleCloseAddFieldReport} />
        )}
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    currentUser: getCurrentUser(state),
    dailyReports: getDailyReports(state),
    showAddDailyReportModal: getShowAddDailyReportModal(state),
    showProjectReportPermissionsModal: getShowProjectReportPermissionsModal(
      state
    ),
    showFieldReportFormModal: getFieldReportForm(state),
    dailyReportLogPdf: getDailyReportLogPdf(state),
    dailyReportLogPdfViewer: getDailyReportLogPdfViewer(state),
    currentPage: getCurrentPage(state),
    reportsPageSize: getReportsPageSize(state),
    reportsTotal: getReportsTotal(state),
    dailyReportsTabSelected: getDailyReportsTabSelected(state),
    fieldWorkOrders: getFieldWorkOrders(state),
    showFieldWorkOrderModal: getShowFieldWorkOrderModal(state),
    projectReportPdfViewer: getProjectReportPdfViewer(state),
    fieldProjectReports: getFieldProjectReports(state),
    projectReportOpenDetails: getprojectReportOpenDetails(state),
    lastProjectReport: getLastProjectReport(state),
    fieldReportFilters: getFieldReportFilters(state),
  };
})(withRouter(ProjectDailyReportLog));
