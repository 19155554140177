import Rfa from 'domain/rfa';
import User from 'domain/user';

class RfaPermissions {
  canViewDeleted = (user, _project) => User.isAdmin(user);

  canSubmit = (user, project) => User.isAdminOrProjectSubscriber(user, project);

  canEdit = (_user, _project, rfa) => Rfa.isDraft(rfa);

  canEmail = (_user, _project, rfa) => !Rfa.isDeleted(rfa);

  canDelete = (user, _project, rfa) => {
    if (Rfa.isDeleted(rfa)) return false;

    if (User.isAdmin(user)) return true;

    if (Rfa.isDraft(rfa) || Rfa.isPending(rfa))
      return User.isSameCompany(user, rfa.created_by);

    return false;
  };

  canRevise = (user, project, rfa) => {
    if (rfa.revised) return false;

    return (
      (Rfa.isPending(rfa) &&
        (User.isAdmin(user) ||
          User.isSameCompany(user, rfa.created_by) ||
          User.isSameCompany(user, rfa.reviewed_by))) ||
      (Rfa.isClosed(rfa) &&
        (User.isSameCompany(user, rfa.created_by) ||
          this.canSubmit(user, project)))
    );
  };
}

export default new RfaPermissions();
