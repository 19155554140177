import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class CompaniesApi extends BaseApi {
  //TODO: move filters into a map
  getCompanies(
    byType,
    byName,
    page,
    loadingDisabled = true,
    subscribers = false
  ) {
    let queryString = `page=${page || 1}`;
    if (byType) queryString += `&by_contributor_type=${byType}`;
    if (byName) queryString += `&by_name=${encodeURIComponent(byName)}`;
    if (subscribers) queryString += '&subscriber=true';

    return axios
      .get(`${this.baseUrl}/companies?${queryString}`, {
        loadingDisabled: loadingDisabled,
      })
      .then((response) => {
        return {
          ok: true,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
          companies: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  showCompanyById(id, loadingDisabled = true) {
    return axios
      .get(`${this.baseUrl}/companies/${id}`, {
        loadingDisabled: loadingDisabled,
      })
      .then((response) => {
        return {
          ok: true,
          company: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getCompanyUsers(companyId, enabled = false) {
    let url = `${this.baseUrl}/companies/${companyId}/users`;
    if (enabled) {
      url += '?enabled=true';
    }
    return axios
      .get(url)
      .then((response) => {
        return {
          ok: true,
          users: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  mapContributorProjects = (contributors) =>
    contributors
      .reduce((projects, contributor) => {
        const contributorProject = projects.find(
          (project) => project.id === contributor.project.id
        );
        if (contributorProject) {
          contributorProject.contributors.push({
            ...contributor,
            project: null,
          });
          return projects;
        }

        return [
          ...projects,
          {
            ...contributor.project,
            contributors: [{ ...contributor, project: null }],
          },
        ];
      }, [])
      .sort((project1, project2) => project1.name.localeCompare(project2.name));

  getCompanyProjects(companyId, status = null) {
    return axios
      .get(
        `${this.baseUrl}/companies/${companyId}/projects/project_contributors${
          status ? `?${status}=true` : ''
        }`
      )
      .then((response) => {
        return {
          ok: true,
          projects: this.mapContributorProjects(response.data),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createCompany(values, skipInvitation = false) {
    const data = this.companyParams(values, skipInvitation);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(`${this.baseUrl}/companies`, data, headers)
      .then((response) => {
        return {
          ok: true,
          company: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  inviteExistent(companyId) {
    return axios
      .post(`${this.baseUrl}/companies/${companyId}/invite_existent`, {})
      .then((response) => {
        return {
          ok: true,
          company: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateCompany(id, values) {
    const data = this.companyParams(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .put(`${this.baseUrl}/companies/${id}`, data, headers)
      .then((response) => {
        return {
          ok: true,
          company: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  editUser(values) {
    const data = this.userParams(values);

    return axios
      .put(
        `${this.baseUrl}/companies/${values.accountableId}/users/${values.id}`,
        data
      )
      .then((response) => {
        return {
          ok: true,
          updatedUser: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteUser(values) {
    return axios
      .delete(
        `${this.baseUrl}/companies/${values.accountableId}/users/${values.id}`
      )
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createCompanyDirectory(values) {
    let data = new FormData();
    data.append('company[name]', values.name);
    data.append('company[contact_attributes][first_name]', values.firstName);
    data.append('company[contact_attributes][last_name]', values.lastName);
    data.append('company[contact_attributes][email]', values.email);
    data.append(
      'company[contact_attributes][phone_number]',
      values.phoneNumber
    );
    if (values.phoneExt)
      data.append('company[contact_attributes][phone_ext]', values.phoneExt);
    if (values.officePhoneNumber)
      data.append(
        'company[contact_attributes][office_phone_number]',
        values.officePhoneNumber
      );
    if (values.officeFaxNumber)
      data.append(
        'company[contact_attributes][office_fax_number]',
        values.officeFaxNumber
      );
    data.append('company[contact_attributes][title]', values.title);

    if (values.image && values.image.name && values.image.preview) {
      data.append('company[logo_attributes][image]', values.image);
    }

    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

    return axios
      .post(`${this.baseUrl}/companies`, data, headers)
      .then((response) => {
        return {
          ok: true,
          company: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  inviteUser(companyId, values) {
    const data = this.userParams(values);

    return axios
      .post(`${this.baseUrl}/companies/${companyId}/invite_user`, data)
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  sendInvite(user) {
    const { id, accountable_id } = user;

    return axios
      .put(
        `${this.baseUrl}/companies/${accountable_id}/users/${id}/send_invite`
      )
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  activationLink(user) {
    const { id, accountable_id } = user;

    return axios
      .get(
        `${this.baseUrl}/companies/${accountable_id}/users/${id}/accept_invitation_url`
      )
      .then((response) => {
        return {
          ok: true,
          instructions: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  registerToNewCompany(values) {
    const data = this.companyParams(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(`${this.baseUrl}/companies/register_to_new_company`, data, headers)
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  registerToExistentCompany(id, values) {
    const data = this.companyParams(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(
        `${this.baseUrl}/companies/${id}/register_to_existent_company`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  verifyUser(user) {
    const { id, accountable_id } = user;

    return axios
      .put(
        `${this.baseUrl}/companies/${accountable_id}/users/${id}/verify_user`
      )
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  rejectUser(user) {
    const { id, accountable_id } = user;

    return axios
      .put(
        `${this.baseUrl}/companies/${accountable_id}/users/${id}/reject_user`
      )
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  makeSimilarCompanyMsg = (params, company, criteria) => {
    const companyName = company.name;

    switch (criteria) {
      case 'company_name':
        return `Already exists a company with name ${companyName}.`;
      case 'email':
        return `Company ${companyName} has same email ${params.email}.`;
      case 'phone_number':
        return `Company ${companyName} has same primary contact phone number ${params.phone_number}.`;
      case 'office_phone_number':
        return `Company ${companyName} has same office phone number ${params.office_phone_number}.`;
      case 'office_fax_number':
        return `Company ${companyName} has same office fax number ${params.office_fax_number}.`;
      case 'company_name_similar':
        return `A similar company ${companyName} was found.`;
      default:
        return '';
    }
  };

  makeSimilarCompanyDisclaimer = (params, company, criteria) => {
    switch (criteria) {
      case 'company_name':
        return 'Name';
      case 'email':
        return 'Email';
      case 'phone_number':
        return 'Contact Phone';
      case 'office_phone_number':
        return 'Office Phone';
      case 'office_fax_number':
        return 'Office Fax';
      case 'company_name_similar':
        return 'Similar Name';
      default:
        return '';
    }
  };

  makeFindSimilarResult = (params, data) => {
    switch (params.mode) {
      case 'list':
        return this.makeFindSimilarListResult(params, data);
      default:
        return this.makeFindSimilarFirstResult(params, data);
    }
  };

  makeFindSimilarListResult = (params, data) => {
    return {
      ok: true,
      companies: data.map((field) => ({
        ...field,
        disclaimer: this.makeSimilarCompanyDisclaimer(
          params,
          field.company,
          field.criteria
        ),
      })),
    };
  };

  makeFindSimilarFirstResult = (params, data) => {
    if (data.length === 0) return { ok: true };

    const { company, criteria } = data[0];
    return {
      ok: true,
      company: company,
      criteria: criteria,
      message: this.makeSimilarCompanyMsg(params, company, criteria),
    };
  };

  findSimilar(params) {
    return axios
      .post(`${this.baseUrl}/companies/find_similar`, {
        mode: params.mode,
        name: params.name,
        email: params.email,
        phone_number: params.phone_number,
        office_phone_number: params.office_phone_number,
        office_fax_number: params.office_fax_number,
      })
      .then((response) => this.makeFindSimilarResult(params, response.data))
      .catch((error) => {
        return {
          ok: false,
          //TODO check why test is failing
          errors: (error.response && error.response.data.errors) || '',
        };
      });
  }

  updateNotes(companyId, text = '', reminder = null) {
    const data = {
      company: {
        note: text,
        note_reminder_date: reminder,
      },
    };
    return axios
      .put(`${this.baseUrl}/companies/${companyId}`, data)
      .then((response) => {
        return {
          ok: true,
          company: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateCompanyOnboardingData(onboardingData) {
    const id = onboardingData.company.id;
    let data = new FormData();
    if (onboardingData.licenceNumber)
      data.append('company[license_number]', onboardingData.licenceNumber);
    data.append('company[confirmed]', onboardingData.confirmed);
    if (onboardingData.companyLogo && onboardingData.companyLogo.image)
      data.append(
        'company[logo_attributes][image]',
        onboardingData.companyLogo.image
      );
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .put(`${this.baseUrl}/companies/${id}`, data, headers)
      .then((response) => {
        return {
          ok: true,
          company: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  companyParams(values, skipInvitation = false) {
    const data = new FormData();
    if (skipInvitation) data.append('skip_invitation', true);
    if (values.name !== undefined) {
      data.append('company[name]', values.name);
      if (values.contactId) {
        data.append('company[contact_attributes][id]', values.contactId);
      }

      data.append('company[contact_attributes][first_name]', values.firstName);
      data.append('company[contact_attributes][last_name]', values.lastName);
      data.append('company[contact_attributes][email]', values.email);
      data.append(
        'company[contact_attributes][phone_number]',
        values.phoneNumber
      );
      data.append(
        'company[contact_attributes][office_phone_number]',
        values.officePhoneNumber
      );
      data.append(
        'company[contact_attributes][office_fax_number]',
        values.officeFaxNumber
      );
      if (values.title) {
        data.append('company[contact_attributes][title]', values.title);
      }
      if (values.titleRole) {
        data.append(
          'company[contact_attributes][title_role]',
          values.titleRole
        );
      }

      if (values.locationId) {
        data.append('company[location_attributes][id]', values.locationId);
      }

      data.append(
        'company[location_attributes][street_address_1]',
        values.streetAddress
      );
      data.append(
        'company[location_attributes][street_address_2]',
        values.unitNumber || ''
      );
      data.append('company[location_attributes][city]', values.city);
      data.append('company[location_attributes][state]', values.state);
      data.append('company[location_attributes][zip]', values.zipCode);

      if (values.licenceNumber) {
        data.append('company[license_number]', values.licenceNumber);
      }
      if (values.otherUnion) {
        data.append('company[other_union]', values.otherUnion);
      }
      if (values.selectedUnionsId) {
        values.selectedUnionsId.forEach((unionId) =>
          data.append('company[union_ids][]', unionId)
        );
      }
    }

    if (values.logo) {
      if (values.logo.id) {
        data.append('company[logo_attributes][id]', values.logo.id);
      }

      if (values.logo.destroy) {
        data.append('company[logo_attributes][_destroy]', true);
      } else if (values.logo.file) {
        const { file } = values.logo;
        data.append('company[logo_attributes][image]', file, file.name);
      }
    }

    if (values.stamp) {
      const { stamp } = values;
      if (stamp.id) {
        data.append('company[stamp_attributes][id]', stamp.id);
      }

      if (stamp.destroy) {
        data.append('company[stamp_attributes][_destroy]', true);
      } else {
        data.append('company[stamp_attributes][legal]', stamp.legal);
        stamp.stampActions &&
          stamp.stampActions.forEach((stampAction, index) => {
            if (stampAction.id) {
              data.append(
                `company[stamp_attributes][stamp_actions_attributes][${index}][id]`,
                stampAction.id
              );
            }

            if (stampAction.destroy) {
              data.append(
                `company[stamp_attributes][stamp_actions_attributes][${index}][_destroy]`,
                stampAction.destroy
              );
            } else {
              data.append(
                `company[stamp_attributes][stamp_actions_attributes][${index}][action]`,
                stampAction.action
              );
              data.append(
                `company[stamp_attributes][stamp_actions_attributes][${index}][title]`,
                stampAction.title
              );
              data.append(
                `company[stamp_attributes][stamp_actions_attributes][${index}][subtitle]`,
                stampAction.subtitle || ''
              );
            }
          });
      }
    }

    if (values.user) {
      data.append('user[first_name]', values.user.firstName);
      data.append('user[last_name]', values.user.lastName);
      data.append('user[email]', values.user.email);
      data.append('user[phone_number]', values.user.phoneNumber);
      if (values.user.title) data.append('user[title]', values.user.title);
      if (values.user.assignedRole)
        data.append('user[assigned_role]', values.user.assignedRole);
      if (values.user.assignedAccountAdmin)
        data.append(
          'user[assigned_account_admin]',
          values.user.assignedAccountAdmin
        );
      if (values.user.removedAccountAdmin)
        data.append(
          'user[removed_account_admin]',
          values.user.removedAccountAdmin
        );
    }

    return data;
  }

  userParams(values) {
    return {
      user: {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone_number: values.phoneNumber,
        phone_ext: values.phoneExt,
        title: values.title,
        assigned_role: values.assignedRole,
        assigned_account_admin: values.assignedAccountAdmin,
        removed_account_admin: values.removedAccountAdmin,
      },
    };
  }

  userFormParams(values) {
    return {
      user: {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone_number: values.phoneNumber,
        phone_ext: values.phoneExt,
        title: values.title,
        assigned_role: values.assignedRole,
        assigned_account_admin: values.assignedAccountAdmin,
        removed_account_admin: values.removedAccountAdmin,
      },
    };
  }
}

export const companiesApi = new CompaniesApi();
