import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './RadioButton.css';

class RadioButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      })
    ).isRequired,
    orientation: PropTypes.oneOf([
      'horizontal',
      'vertical',
      'table',
      'horizontal-row',
    ]),
    readOnly: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    valid: PropTypes.bool,
  };

  static defaultProps = {
    orientation: 'horizontal',
    readOnly: false,
    valid: true,
  };

  handleChange = (event, value) => {
    event.preventDefault();
    if (this.props.onChange && !this.props.readOnly) {
      this.props.onChange(value);
    }
  };

  render() {
    return (
      <div
        className={
          `radio-button radio-button-${this.props.orientation}` +
          (this.props.className !== undefined
            ? ' ' + this.props.className
            : '') +
          (this.props.darkMode ? ' dark-mode' : '') +
          (!this.props.valid ? ' invalid' : '')
        }
      >
        {this.props.orientation === 'table'
          ? this.renderAsTable()
          : this.renderSimple()}
      </div>
    );
  }

  renderAsTable() {
    const rows = [];
    let row = [];
    this.props.options.forEach((element, index) => {
      if (index % 2 === 0) {
        row = [];

        row.push(<div key={index}>{this.renderItemContent(element)}</div>);

        rows.push(row);
      } else {
        row.push(<div key={index}>{this.renderItemContent(element)}</div>);
      }
    });

    return (
      <table className="orientation-table">
        <tbody>
          {rows.map((row, index) => {
            return (
              <tr key={index}>
                <td>{row[0]}</td>
                <td>{row[1]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderSimple() {
    return (
      <React.Fragment>
        {this.props.options.map((element, index) => {
          return (
            <div
              key={index}
              className={'orientation-simple ' + this.props.orientation}
            >
              {this.renderItemContent(element)}
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  renderItemContent(element) {
    const { value, readOnly } = this.props;
    const handleClick = (event) => this.handleChange(event, element.value);

    return (
      <React.Fragment>
        <div
          className={`radiobutton-control ${
            element.value === value ? 'checked' : ''
          } ${readOnly ? 'read-only' : ''}`}
          alt={element.selected ? 'selected' : 'not-selected'}
          onClick={handleClick}
        />
        <span onClick={handleClick}>{element.label}</span>
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(RadioButton);
