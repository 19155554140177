import moment from 'moment';
import { DailyReportUtil } from 'services/utils/daily-report-util';
import { showErrorMessage } from 'actions/messages';
import { dailyReportsApi } from 'services/api/daily-reports-api';
import { subDailyReportsApi } from 'services/api/sub-daily-reports-api';
import { fieldWorkOrdersApi } from 'services/api/field-work-orders-api';
import { dailyReportsEntriesApi } from 'services/api/daily-reports-entries-api';
import { subDailyReportsEntriesApi } from 'services/api/sub-daily-reports-entries-api';
import { projectResourceMailerApi } from 'services/api/project-resource-mailer-api';
import { csiCodesApi } from 'services/api/csi-codes-api';
import { projectReportsApi } from 'services/api/project-reports-api';
import {
  DAILY_REPORTS_SET,
  DAILY_REPORTS_UPDATE,
  SHOW_ADD_DAILY_REPORT_SET,
  SHOW_PROJECT_REPORT_PERMISSIONS_SET,
  SET_CONTRIBUTORS,
  SET_CURRENT_DAILY_REPORT,
  LOAD_PRESET_TASKS,
  ADD_DAILY_REPORT_SUCCESS,
  SET_ACTIVE_CONTRIBUTOR,
  SET_ACTIVE_ENTRIES_ID,
  INITIALIZE_TRADE_FORM,
  UPDATE_TRADE_FORM,
  RESTORE_TRADE_FORM,
  RESTORE_MEMBER_FORM,
  CHANGE_AMOUNT_OF_TECHNICIANS,
  HANDLE_WORKED_FORM,
  HANDLE_WORKED_TO,
  HANDLE_ATTRIBUTE_CHANGE,
  HANDLE_HOURS_AMOUNT,
  SET_REVIEW_FORM,
  SET_REPORT_SUBMITTED,
  CLEAR_FORM,
  SET_DAILY_REPORT_LOG_PDF,
  SET_DAILY_REPORT_LOG_PDF_VIEWER,
  SET_EDIT_MODE,
  SET_REPORTS_IDS,
  DELETE_DAILY_REPORT,
  SET_REPORT_PDF,
  SET_REPORT_PDF_VIEWER,
  SET_REPORT_DATE,
  SET_REPORT_EXTRA_INPUTS,
  SET_REPORT_EXTRA_INPUT,
  SET_REPORT_OPEN_ITEM,
  SET_REPORT_OPEN_ITEM_VALUE,
  SET_GENERAL_CONTACTOR_NAME,
  SET_MEMBER_FORM,
  SET_SUPERVISOR_TASKS,
  SET_MEMBERS,
  SET_DELETED_MEMBERS,
  SET_CURRENT_PAGE,
  SET_REPORTS_TOTAL,
  SET_REPORTS_PAGE_SIZE,
  SET_GCX_IMAGES,
  SET_GCX_DELETED_IMAGES,
  SET_DELIVERY,
  DELETE_DELIVERY,
  ADD_EXTRA_INPUT,
  REMOVE_DELIVERIES_EXTRA_INPUT,
  SET_DAILY_REPORT_TAB,
  SUB_DAILY_REPORTS_COUNT_SUCCESS,
  SUB_DAILY_REPORTS_COUNT_INIT,
  HANDLE_ATTRIBUTE_CHANGE_FOR_SUB,
  HANDLE_HOURS_AMOUNT_MORE_INFO,
  HANDLE_DATE_MORE_INFO,
  HANDLE_ATTRIBUTE_CHANGE_FOR_SUB_MORE_ACTION,
  ADDED_DELETED_TECHNICIANS,
  FIELD_WORK_ORDERS_SET,
  SET_SHOW_FIELD_WORK_ORDER,
  FIELD_WORK_ORDER_SET,
  FIELD_WORK_ORDER_FORM_INIT,
  FIELD_WORK_ORDER_FORM_ATTRIBUTE_SET,
  SET_FIELD_WORK_ORDER_EDIT,
  UPDATE_FIELD_WORK_ORDER_SUCCESS,
  SET_SHOW_FIELD_WORK_ORDER_ERROR,
  SET_DAILY_REPORT_ADD_FLOOR,
  DUPLICATE_DAILY_REPORT_SUCCESS,
  SET_PROJECT_REPORT_ATTR,
  ADD_PROJECT_REPORT_CONTACT_INFORMATION_ITEM,
  SET_PROJECT_REPORT_DOCUMENTS_PHOTOS_ATTR,
  SET_PROJECT_REPORT_PDF_VIEWER,
  PROJECT_REPORTS_SET,
  PROJECT_REPORT_RELOAD,
  DELETE_PROJECT_REPORT,
  OPEN_PROJECT_REPORT_DETAILS,
  PROJECT_REPORT_SET,
  PROJECT_REPORT_SET_NUMBER,
  ADD_PROJECT_REPORT_PROJECT_STATUS_ITEM,
  RESET_PROJECT_REPORT_FORM,
  ADD_ITEM_PROJECT_REPORT_TABLE,
  ADD_PROJECT_REPORT_SCHEDULE_DESCRIPTION_BY_ID,
  DELETE_PROJECT_REPORT_TABLE_ITEM_BY_TYPE,
  SET_PROJECT_REPORT_PROJECT_TABLE_ITEM_ATTR_BY_TYPE,
  ADD_PROJECT_REPORT_BUDGET_DESCRIPTION_BY_ID,
  GET_PROJECT_REPORT_RESOLVED_INFO_BY_ATTR,
  PROJECT_REPORT_RESOLVED_TABLE_FILTER,
  SET_PROJECT_REPORTS_PERMISSIONS,
  PROJECT_REPORT_TABLES_ORDER_CHANGE,
  PROJECT_REPORT_PHOTOS_AND_DOCUMENTS_FILTER,
  PROJECT_REPORT_PHOTOS_AND_DOCUMENTS_FILTER_CLEAR,
  DELETE_PROJECT_REPORT_PROJECT_STATUS,
  SET_PROJECT_PHOTO_AND_DOCUMENTS_FILTERING_ACTIVE,
  SET_LAST_PROJECT_REPORT,
  SET_PROJECT_REPORT_PREVIEW,
  SET_PROJECT_REPORT_INITIAL_TAB,
  SET_PROJECT_REPORT_TABLE_MULTIPLE_ITEMS_BY_TYPE,
} from 'components/admin/projects/details/project-daily-report-log/store/action-types';
import { getContributorsForm } from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import { utcTime } from 'services/utils/date-util';
import { SET_PROJECT_REPORT_PDF } from './action-types';

function prepareResponseForSubDailyReports(response) {
  const resultResponse = {
    ...response,
    dailyReport: {
      ...response.dailyReport,
      daily_report_entries: response.dailyReport.sub_daily_report_entries
        ? response.dailyReport.sub_daily_report_entries.map((entry) => ({
            ...entry,
            daily_report_id: entry.sub_daily_report_id,
          }))
        : [],
      daily_report_id: response.dailyReport.sub_daily_report_id,
    },
  };
  return resultResponse;
}

export function resetScroll() {
  const container = document.getElementsByClassName('daily-report-form-body');
  if (container.length > 0) {
    container[0].scrollTop = 0;
  }
}

export function setContributors(contributors) {
  return {
    type: SET_CONTRIBUTORS,
    payload: contributors,
  };
}

export function setCurrentDailyReport(dailyReport) {
  return {
    type: SET_CURRENT_DAILY_REPORT,
    payload: dailyReport,
  };
}

export function setShowDailyReportModal(showEditUserModal) {
  return {
    type: SHOW_ADD_DAILY_REPORT_SET,
    payload: showEditUserModal,
  };
}

export function setShowProjectReportPermissionsModal(showPermissionsModal) {
  return {
    type: SHOW_PROJECT_REPORT_PERMISSIONS_SET,
    payload: showPermissionsModal,
  };
}

export function setShowFieldWorkOrderModal(showFieldWorkOrderModal) {
  return {
    type: SET_SHOW_FIELD_WORK_ORDER,
    payload: showFieldWorkOrderModal,
  };
}

export function loadFieldWorkOrderSuccess(fieldWorkOrder) {
  return {
    type: FIELD_WORK_ORDER_SET,
    payload: fieldWorkOrder,
  };
}

export function loadDailyReportsSuccess(dailyReports) {
  return {
    type: DAILY_REPORTS_SET,
    payload: dailyReports,
  };
}

export function loadProjectReportsSuccess(projectReports) {
  return {
    type: PROJECT_REPORTS_SET,
    payload: projectReports,
  };
}

export function loadProjectReportSuccess(projectReport) {
  return {
    type: PROJECT_REPORT_SET,
    payload: projectReport,
  };
}

export function reloadProjectReportSuccess(projectReport) {
  return {
    type: PROJECT_REPORT_RELOAD,
    payload: projectReport,
  };
}

export function setProjectReportNumber(number) {
  return {
    type: PROJECT_REPORT_SET_NUMBER,
    payload: { number },
  };
}

export function updateDailyReports(dailyReport) {
  return {
    type: DAILY_REPORTS_UPDATE,
    payload: dailyReport,
  };
}

export function addDailyReportSuccess(dailyReport) {
  return {
    type: ADD_DAILY_REPORT_SUCCESS,
    payload: dailyReport,
  };
}

export function afterDailyReportSave(
  response,
  editTrades = false,
  generalContactorName,
  isSubDailyReports = false
) {
  return (dispatch, getState) => {
    const { dailyReport } = response;
    const currentContribForm = getContributorsForm(getState());
    const currentContribFormIds = Object.keys(currentContribForm);

    if (!editTrades) {
      if (generalContactorName) {
        dispatch(setMemberForm(true));
      }

      dispatch(addDailyReportSuccess(dailyReport));
      if (dailyReport.daily_report_entries.length > 0) {
        dispatch(setActiveEntriesId(dailyReport.daily_report_entries[0].id));
        dispatch(setActiveContributor(1));
      }
    } else {
      dispatch(updateDailyReports(dailyReport));
    }

    const setupTradeForm = {};
    const defaultInfo = {
      hoursAmount: '',
      workedFrom: '',
      workedTo: '',
      location: '',
      customTask: '',
      workPerformed: '',
      presetTasks: [],
    };
    const defaultTechnician = { hoursAmount: '', workedFrom: '', workedTo: '' };
    const defaultTechnicianForSub = {
      ...defaultInfo,
      tech: { value: 1, label: 'Technician 1', custom: true },
      additionalInfo: [],
    };
    let defaultTechnicianValue = isSubDailyReports
      ? defaultTechnicianForSub
      : defaultTechnician;

    if (
      dailyReport.daily_report_entries &&
      dailyReport.daily_report_entries.length > 0
    ) {
      dailyReport.daily_report_entries.forEach((report) => {
        const existingReportId = currentContribFormIds.find(
          (id) => report.id === +id
        );
        if (existingReportId) {
          setupTradeForm[report.id] = currentContribForm[existingReportId];
        } else {
          setupTradeForm[report.id] = {
            technicians: [defaultTechnicianValue],
            deletedTechnicians: [],
            isDelay: false,
            isFieldWorkOrder: false,
            floor: '',
            area: '',
          };
        }
      });

      dispatch(updateTradeForm(setupTradeForm));
    }
  };
}

export function addDailyReport(
  values,
  reportDate,
  projectId,
  generalContactorName,
  isSubDailyReports = false
) {
  const resultApi = isSubDailyReports ? subDailyReportsApi : dailyReportsApi;
  return (dispatch) => {
    const resultValues = values.map((item) => {
      return { project_contributor_id: item[0] };
    });
    return resultApi
      .addDailyReport(resultValues, reportDate, projectId, generalContactorName)
      .then((response) => {
        if (response.ok) {
          let resultResponse = response;
          if (isSubDailyReports) {
            resultResponse = prepareResponseForSubDailyReports(response);
          }
          dispatch(
            afterDailyReportSave(
              resultResponse,
              false,
              generalContactorName,
              isSubDailyReports
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function dailyReportFinaleUpdate(
  projectId,
  reportId,
  values,
  reportStatus,
  isSubDailyReports
) {
  const resultApi = isSubDailyReports ? subDailyReportsApi : dailyReportsApi;
  return (dispatch) => {
    return resultApi
      .updateDailyReport(values, projectId, reportId)
      .then((response) => {
        if (response.ok) {
          let resultResponse = response;
          if (isSubDailyReports) {
            resultResponse = prepareResponseForSubDailyReports(response);
          }
          if (reportStatus !== 'submitted') {
            dispatch(setSubmittedReport(true));
          }
          dispatch(updateDailyReports(resultResponse.dailyReport));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function updateDailyReport(
  values,
  projectId,
  dailyReport,
  editTrades,
  reportDate,
  generalContactorName,
  isSubDailyReports = false
) {
  const resultApi = isSubDailyReports ? subDailyReportsApi : dailyReportsApi;
  return (dispatch) => {
    const resultValues = [
      ...values.map((item) => {
        let contrib = { project_contributor_id: item[0] };
        const dailyReportEntry = dailyReport.daily_report_entries.find(
          (entry) => entry.project_contributor_id === item[0]
        );
        if (dailyReportEntry) {
          contrib.id = dailyReportEntry.id;
        }
        return contrib;
      }),
      ...dailyReport.daily_report_entries.map((entry) => {
        const contrib = values.find(
          (item) => entry.project_contributor_id === item[0]
        );
        return !contrib && { id: entry.id, _destroy: true };
      }),
    ].filter((entry) => !!entry);
    const results = {
      reportDate: reportDate,
      gcStaffOnSite: !!generalContactorName,
      dailyReportEntriesAttributes: resultValues,
    };
    return resultApi
      .updateDailyReport(results, projectId, dailyReport.id)
      .then((response) => {
        if (response.ok) {
          let resultResponse = response;
          if (isSubDailyReports) {
            resultResponse = prepareResponseForSubDailyReports(response);
          }
          dispatch(
            afterDailyReportSave(
              resultResponse,
              editTrades,
              generalContactorName,
              isSubDailyReports
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function addDailyReportEntries(
  values,
  reportId,
  imagesFromStore,
  isLastContributor,
  editMode,
  projectId,
  isSubDailyReports,
  isChildFloor,
  isDraft
) {
  const resultApi = isSubDailyReports
    ? subDailyReportsEntriesApi
    : dailyReportsEntriesApi;
  return (dispatch) => {
    return resultApi
      .updateDailyReportEntry(values, reportId)
      .then((response) => {
        if (response.ok) {
          let resultResponse = response;
          if (isSubDailyReports) {
            resultResponse = prepareResponseForSubDailyReports(response);
          }
          const { dailyReport } = resultResponse;
          let techList = null;
          if (dailyReport.technicians && dailyReport.technicians.length) {
            techList = dailyReport.technicians;
          }
          if (
            dailyReport.sub_daily_report_teches &&
            dailyReport.sub_daily_report_teches.length
          ) {
            techList = dailyReport.sub_daily_report_teches;
          }
          const technicians = [];
          let techId = null;
          let techRes = {};
          if (techList && techList.length) {
            let resultTechList = techList;
            if (isSubDailyReports) {
              const techListTechNumbers = techList
                .filter((tech) => !tech.sub_technician_id && tech.tech_number)
                .sort((tech1, tech2) => tech1.tech_number - tech2.tech_number);
              const techListSubTechIds = techList
                .filter((tech) => tech.sub_technician_id)
                .sort(
                  (tech1, tech2) =>
                    tech1.sub_technician_id - tech2.sub_technician_id
                );
              resultTechList = [...techListTechNumbers, ...techListSubTechIds];
            }
            resultTechList.forEach((technician) => {
              const resultValue = {
                ...technician,
                id: technician.id,
                workPerformed: technician.work_performed,
                location: technician.location,
                presetTasks:
                  technician.preset_tasks &&
                  technician.preset_tasks.map((task) => [task.id, task.label]),
                customTask: technician.preset_task_other,
              };
              if (technician.sub_technician) {
                resultValue.sub_technician = {
                  value: technician.sub_technician.id,
                  label: `${technician.sub_technician.first_name} ${
                    technician.sub_technician.last_name || ''
                  }`,
                };
              }
              resultValue.tech = {
                value: technician.tech_number,
                label: `Technician ${technician.tech_number}`,
                custom: true,
              };
              if (technician.started_at || technician.ended_at) {
                resultValue.hoursAmount = '';
                resultValue.workedFrom = technician.started_at;
                resultValue.workedTo = technician.ended_at;
              } else {
                resultValue.hoursAmount = `${technician.hours_worked || ''}`;
                resultValue.workedFrom = '';
                resultValue.workedTo = '';
              }
              if (isSubDailyReports) {
                let newTechId =
                  technician.sub_technician_id || technician.tech_number;
                if (techId !== newTechId) {
                  techRes = resultValue;
                  techId = newTechId;
                  technicians.push(techRes);
                } else {
                  if (techRes.additionalInfo) {
                    const isExisting = techRes.additionalInfo.find(
                      (el) => el.id === technician.id
                    );
                    if (!isExisting) {
                      techRes.additionalInfo.push(resultValue);
                    }
                  } else {
                    techRes.additionalInfo = [resultValue];
                  }
                }
              } else {
                technicians.push(resultValue);
              }
            });
          }
          if (imagesFromStore && imagesFromStore.length > 0) {
            const newImages = dailyReport.images.map((file) => {
              return {
                ...file,
                url: file.image.gallery.url,
              };
            });
            dispatch(
              handleAttributeChangeAction('images', newImages, dailyReport.id)
            );
          }
          if (technicians.length) {
            technicians.sort(
              (tech1, tech2) => tech1.tech.value - tech2.tech.value
            );
            dispatch(
              handleAttributeChangeAction(
                'technicians',
                technicians,
                dailyReport.id
              )
            );
          }
          dispatch(
            handleAttributeChangeAction(
              'deletedTechnicians',
              [],
              dailyReport.id
            )
          );
          dispatch(
            handleAttributeChangeAction('deletedImages', [], dailyReport.id)
          );
          dispatch(
            handleAttributeChangeAction(
              'openItemDeletedImages',
              [],
              dailyReport.id
            )
          );
          dispatch(
            handleAttributeChangeAction(
              'openItemDeletedDocuments',
              [],
              dailyReport.id
            )
          );

          if (!dailyReport.project_open_item) {
            dispatch(
              handleAttributeChangeAction('openItemId', '', dailyReport.id)
            );
            dispatch(
              handleAttributeChangeAction(
                'openItemAssigneeId',
                '',
                dailyReport.id
              )
            );
            dispatch(
              handleAttributeChangeAction('openItemDesc', '', dailyReport.id)
            );
            dispatch(
              handleAttributeChangeAction(
                'openItemResolveBy',
                '',
                dailyReport.id
              )
            );
            dispatch(
              handleAttributeChangeAction(
                'openItemCritical',
                false,
                dailyReport.id
              )
            );
            dispatch(
              handleAttributeChangeAction(
                'openItemRecipients',
                [],
                dailyReport.id
              )
            );
            dispatch(
              handleAttributeChangeAction('openItemImages', [], dailyReport.id)
            );
            dispatch(
              handleAttributeChangeAction(
                'openItemDocuments',
                [],
                dailyReport.id
              )
            );
          } else {
            dispatch(
              handleAttributeChangeAction(
                'openItemId',
                dailyReport.project_open_item.id,
                dailyReport.id
              )
            );
            dispatch(
              handleAttributeChangeAction(
                'openItemRecipients',
                dailyReport.project_open_item.recipients.map((rcpt) => [
                  rcpt.id,
                ])
              )
            );
            dispatch(
              handleAttributeChangeAction(
                'openItemImages',
                dailyReport.project_open_item.images.map((image) => {
                  return {
                    ...image,
                    url: image.image.gallery.url,
                  };
                }),
                dailyReport.id
              )
            );
            dispatch(
              handleAttributeChangeAction(
                'openItemDocuments',
                dailyReport.project_open_item.documents.map((doc) => {
                  return {
                    ...doc,
                    url: doc.document.url,
                  };
                }),
                dailyReport.id
              )
            );
          }

          if (!dailyReport.field_work_order) {
            dispatch(handleAttributeChangeAction('fwoId', '', dailyReport.id));
            dispatch(
              handleAttributeChangeAction('fwoNumber', '', dailyReport.id)
            );
            dispatch(
              handleAttributeChangeAction('fwoPresetTasks', [], dailyReport.id)
            );
            dispatch(
              handleAttributeChangeAction('fwoCustomTask', '', dailyReport.id)
            );
            dispatch(
              handleAttributeChangeAction(
                'fwoWorkPerformed',
                '',
                dailyReport.id
              )
            );
            dispatch(
              handleAttributeChangeAction('fwoComments', '', dailyReport.id)
            );
            dispatch(
              handleAttributeChangeAction('fwoStartDate', '', dailyReport.id)
            );
            dispatch(
              handleAttributeChangeAction(
                'fwoCompletionDate',
                '',
                dailyReport.id
              )
            );
            dispatch(
              handleAttributeChangeAction('fwoLabor', '', dailyReport.id)
            );
            dispatch(
              handleAttributeChangeAction('fwoMaterials', '', dailyReport.id)
            );
          } else {
            dispatch(
              handleAttributeChangeAction(
                'fwoId',
                dailyReport.field_work_order.id,
                dailyReport.id
              )
            );
          }
          dispatch(
            loadFullReportsRequest(
              projectId,
              dailyReport.daily_report_id,
              isSubDailyReports
            )
          ).then((response) => {
            if (
              response.ok &&
              !isChildFloor &&
              !isDraft &&
              (isLastContributor || editMode)
            ) {
              dispatch(setReviewFormAction(true));
              dispatch(setEditMode(false));
              dispatch(setActiveContributor(0));
              dispatch(setActiveEntriesId(null));
            }
          });
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export function loadFullReportsRequest(
  projectId,
  dailyReportId,
  isSubDailyReport = false,
  newContributors
) {
  const resultApi = isSubDailyReport ? subDailyReportsApi : dailyReportsApi;
  return (dispatch) => {
    return resultApi
      .getDailyReport(projectId, dailyReportId)
      .then((response) => {
        if (response.ok) {
          let resultResponse = response;
          if (isSubDailyReport) {
            resultResponse = prepareResponseForSubDailyReports(response);
          }
          const {
            inspections,
            deliveries,
            visitors,
            phone_calls,
            received_submittals,
            is_safety_accepted,
            safety,
          } = response.dailyReport;
          const images =
            resultResponse.dailyReport.images &&
            resultResponse.dailyReport.images.length > 0
              ? resultResponse.dailyReport.images.map((img) => ({
                  ...img,
                  url: img.image.url,
                }))
              : [];
          dispatch(setCurrentDailyReport(resultResponse.dailyReport));
          dispatch(setGCImagesAction(images));
          dispatch(
            setReportExtraInputs({
              inspections,
              deliveries,
              visitors,
              phone_calls,
              is_safety_accepted,
              safety,
            })
          );
          if (received_submittals) {
            dispatch(setDelivery(received_submittals));
          }
          if (newContributors && newContributors.length > 0) {
            const setupTradeForm = {};
            newContributors.forEach((contrib) => {
              const defaultInfo = {
                hoursAmount: '',
                workedFrom: '',
                workedTo: '',
                location: '',
                customTask: '',
                workPerformed: '',
                presetTasks: [],
              };
              const defaultTechnicianForSub = {
                ...defaultInfo,
                tech: { value: 1, label: 'Technician 1', custom: true },
                additionalInfo: [],
              };

              setupTradeForm[contrib] = {
                technicians: [defaultTechnicianForSub],
                deletedTechnicians: [],
                isDelay: false,
                isFieldWorkOrder: false,
                floor: '',
                area: '',
              };
            });
            dispatch(initializeTradeForm(setupTradeForm));
          }
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadDailyReportsRequest(
  projectId,
  currentPage,
  isSubDailyReports = false
) {
  const resultApi = isSubDailyReports ? subDailyReportsApi : dailyReportsApi;
  return (dispatch) => {
    return resultApi
      .getDailyReports(projectId, currentPage)
      .then((response) => {
        if (response.ok) {
          dispatch(setCurrentPage(currentPage));
          dispatch(setReportsTotal(response.total));
          dispatch(setReportsPageSize(response.pageSize));
          dispatch(loadDailyReportsSuccess(response.dailyReports));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadProjectReportsRequest(projectId, currentPage, filter) {
  return (dispatch) => {
    return projectReportsApi
      .getProjectReports(projectId, filter, currentPage)
      .then((response) => {
        if (response.ok) {
          dispatch(setCurrentPage(currentPage));
          dispatch(setReportsTotal(response.total));
          dispatch(setReportsPageSize(response.pageSize));
          dispatch(loadProjectReportsSuccess(response.projectReports));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function reloadProjectReportRequest(projectId, projectReportId) {
  return (dispatch) => {
    return projectReportsApi
      .getProjectReport(projectId, projectReportId, true)
      .then((response) => {
        if (response.ok) {
          dispatch(reloadProjectReportSuccess(response.data));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadProjectReportRequest(
  projectId,
  projectReportId,
  prevent_data = false,
  number = null,
  userId = null
) {
  return (dispatch) => {
    return projectReportsApi
      .getProjectReport(projectId, projectReportId)
      .then((response) => {
        if (response.ok) {
          if (prevent_data) {
            dispatch(setProjectReportNumber(number));
            const prevent_report = response.data;
            const prevent_attach_documents = {};
            prevent_report.attach_documents.forEach((item) => {
              prevent_attach_documents[item] = {
                key: item,
                value: true,
              };
            });
            const values = {
              prevent_report_id: prevent_report.id,
              prevent_report_number: prevent_report.number,
              date: moment(new Date()).format('YYYY-MM-DD'),
              directory_details: prevent_report.directory_details,
              directory_details_type:
                prevent_report.directory_details_type || 'nonee',
              attach_documents: prevent_attach_documents,
              recipients: prevent_report.users.map((item) => [
                item.id,
                item.label,
              ]),
              remarks: '',
              budget_remarks: '',
              completion_date: prevent_report.completion_date || '',
              original_completion_date:
                prevent_report.original_completion_date || '',
              previous_completion_date:
                prevent_report.previous_completion_date || '',
              schedule_remarks: '',
              disclaimer_remarks: prevent_report.disclaimer_remarks || '',
              contract_info: prevent_report.project_report_contract_infos
                .filter((item) => item.status === 'active')
                .map((item) => ({
                  ...item,
                  previous_published_report_id: prevent_report.id,
                })),
              statuses: prevent_report.project_report_statuses
                .filter((item) => item.status === 'active')
                .map((item) => ({
                  ...item,
                  previous_published_report_id: prevent_report.id,
                })),
              budgets: prevent_report.project_report_budgets
                .filter((item) => item.status === 'active')
                .map((item) => ({
                  ...item,
                  previous_published_report_id: prevent_report.id,
                })),
              schedule_categories: prevent_report.schedule_categories
                .filter((item) => item.status === 'active')
                .map((item) => item.id),
              // cover_image_attributes: {
              //   image: prevent_report.cover_image[0].file
              // }
            };

            dispatch(
              addProjectReports(
                projectId,
                values,
                number,
                'draft',
                userId,
                true
              )
            ).then((response) => {
              if (response.ok) {
                dispatch(loadProjectReportRequest(projectId, response.data.id));
                dispatch(
                  setProjectReportOpenDetails({
                    value: true,
                    id: response.data.id,
                  })
                );
              }
            });
          } else {
            dispatch(loadProjectReportSuccess(response.data));
          }
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setLastProjectReport(report) {
  return {
    type: SET_LAST_PROJECT_REPORT,
    payload: report,
  };
}

export function addProjectReport(projectId) {
  return (dispatch) => {
    projectReportsApi.getLastProjectReport(projectId).then((response) => {
      if (!response.ok) return dispatch(showErrorMessage(response.errors));

      const report = response.data;
      if (report && (report.status === 'draft' || report.status === 'reviewed'))
        return dispatch(
          showErrorMessage(
            'Unable to create a new item while a draft is pending. Please update or remove the draft file.'
          )
        );

      dispatch(setLastProjectReport(report));
      dispatch(setProjectReportOpenDetails({ value: true, id: 0 }));
      dispatch(resetProjectReportForm());
    });
  };
}

export function loadFieldWorkOrdersRequest(projectId, currentPage, gcStage) {
  return (dispatch) => {
    return fieldWorkOrdersApi
      .getFieldWorkOrders(projectId, currentPage, gcStage)
      .then((response) => {
        if (response.ok) {
          dispatch(setCurrentPage(currentPage));
          dispatch(setReportsTotal(response.total));
          dispatch(setReportsPageSize(response.pageSize));
          dispatch(loadFieldWorkOrdersSuccess(response.fieldWorkOrders));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadFieldWorkOrdersSuccess(fieldWorkOrders) {
  return {
    type: FIELD_WORK_ORDERS_SET,
    payload: fieldWorkOrders,
  };
}

export function setDailyReportsIds(ids) {
  return {
    type: SET_REPORTS_IDS,
    payload: ids,
  };
}

export function openFieldWorkOrder(projectId, fieldWorkOrderId) {
  return (dispatch) => {
    return fieldWorkOrdersApi
      .getFieldWorkOrder(projectId, fieldWorkOrderId)
      .then((response) => {
        if (response.ok) {
          dispatch(loadFieldWorkOrderSuccess(response.fieldWorkOrder));
          dispatch(setShowFieldWorkOrderModal(true));
        }
      });
  };
}

export function openDraftReport(
  projectId,
  dailyReportId,
  isSubDailyReport = false,
  fromNotification = false
) {
  return (dispatch) => {
    dispatch(
      loadFullReportsRequest(projectId, dailyReportId, isSubDailyReport)
    ).then((response) => {
      if (response.ok) {
        let resultResponse = response;
        if (isSubDailyReport) {
          resultResponse = prepareResponseForSubDailyReports(response);
          if (fromNotification) {
            dispatch(setDailyReportTab(2));
            dispatch(loadDailyReportsRequest(projectId, 1, true));
          }
        }
        dispatch(setReviewFormAction(true));
        dispatch(setShowDailyReportModal(true));

        const contributors = [];
        const currentDailyReportIds = [];
        const setupTradeForm = {};
        let members = [];

        if (
          resultResponse.dailyReport &&
          resultResponse.dailyReport.daily_report_entries
        ) {
          resultResponse.dailyReport.daily_report_entries.forEach((entry) => {
            currentDailyReportIds.push(entry.id);
            if (
              entry.project_contributor &&
              !entry.daily_report_entry_id &&
              !entry.sub_daily_report_entry_id
            ) {
              const label = DailyReportUtil.tradeLabel(
                entry.project_contributor
              );
              const contributor = [entry.project_contributor.id, label];
              contributors.push(contributor);
            }

            setupTradeForm[entry.id] = {};
            setupTradeForm[entry.id].workPerformed = entry.work_performed;
            setupTradeForm[entry.id].floor = entry.floor || '';
            setupTradeForm[entry.id].area = entry.area || '';
            setupTradeForm[entry.id].comments = entry.comments || '';
            if (entry.project_open_item) {
              setupTradeForm[entry.id].isDelay = true;
              setupTradeForm[entry.id].openItemId = entry.project_open_item.id;
              setupTradeForm[entry.id].openItemAssigneeId =
                entry.project_open_item.assignee_id;
              setupTradeForm[entry.id].openItemDesc =
                entry.project_open_item.description;
              setupTradeForm[entry.id].openItemResolveBy =
                entry.project_open_item.resolve_by;
              setupTradeForm[entry.id].openItemCritical =
                entry.project_open_item.critical;
              setupTradeForm[
                entry.id
              ].openItemRecipients = entry.project_open_item.recipients.map(
                (rcpt) => [rcpt.id]
              );
              setupTradeForm[
                entry.id
              ].openItemImages = entry.project_open_item.images.map((image) => {
                return {
                  ...image,
                  url: image.image.gallery.url,
                };
              });
              setupTradeForm[
                entry.id
              ].openItemDocuments = entry.project_open_item.documents.map(
                (doc) => {
                  return {
                    ...doc,
                    url: doc.document.url,
                  };
                }
              );
            }
            if (entry.field_work_order) {
              setupTradeForm[entry.id].isFieldWorkOrder = true;
              setupTradeForm[entry.id].fwoId = entry.field_work_order.id;
              setupTradeForm[entry.id].fwoNumber =
                entry.field_work_order.number || '';
              setupTradeForm[entry.id].fwoPresetTasks =
                entry.field_work_order && entry.field_work_order.preset_tasks
                  ? entry.field_work_order.preset_tasks.map((task) => [
                      task.id,
                      task.label,
                    ])
                  : [];
              setupTradeForm[entry.id].fwoCustomTask =
                entry.field_work_order.preset_task_other || '';
              setupTradeForm[entry.id].fwoWorkPerformed =
                entry.field_work_order.work_performed || '';
              setupTradeForm[entry.id].fwoComments =
                entry.field_work_order.comments || '';
              setupTradeForm[entry.id].fwoStartDate =
                entry.field_work_order.start_date || '';
              setupTradeForm[entry.id].fwoCompletionDate =
                entry.field_work_order.completion_date || '';
              setupTradeForm[entry.id].fwoLabor =
                entry.field_work_order.labor || '';
              setupTradeForm[entry.id].fwoMaterials =
                entry.field_work_order.materials || '';
            }
            setupTradeForm[entry.id].presetTasks =
              entry.preset_tasks && entry.preset_tasks.length > 0
                ? entry.preset_tasks.map((task) => [task.id, task.label])
                : [];
            setupTradeForm[entry.id].customTask = entry.preset_task_other || '';
            setupTradeForm[entry.id].images =
              (entry.images.length > 0 &&
                entry.images.map((image) => {
                  return {
                    ...image,
                    url: image.image.gallery.url,
                  };
                })) ||
              [];
            if (entry.technicians && entry.technicians.length > 0) {
              setupTradeForm[entry.id].technicians = entry.technicians.map(
                (technician) => {
                  const techValues = {
                    id: technician.id,
                    workedFrom: technician.started_at,
                    workedTo: technician.ended_at,
                  };
                  if (!technician.started_at && !technician.workedTo) {
                    techValues.hoursAmount = `${technician.hours_worked || ''}`;
                  } else {
                    techValues.hoursAmount = '';
                  }
                  return techValues;
                }
              );
            } else {
              const defaultTechnician = {
                hoursAmount: '',
                workedFrom: '',
                workedTo: '',
              };
              setupTradeForm[entry.id].technicians = [defaultTechnician];
            }
            setupTradeForm[entry.id].deletedImages = [];
            setupTradeForm[entry.id].deletedTechnicians = [];

            if (isSubDailyReport && entry.sub_daily_report_teches.length) {
              let techId = 0;
              const subTeches = {};
              const subDailyReportTradesValues = entry.sub_daily_report_teches;
              subDailyReportTradesValues.forEach((tech) => {
                techId = tech.sub_technician_id || tech.tech_number;
                const technicianValue = {};
                if (tech.sub_technician) {
                  technicianValue.sub_technician = {
                    value: tech.sub_technician.id,
                    label: `${tech.sub_technician.first_name} ${
                      tech.sub_technician.last_name || ''
                    }`,
                  };
                }
                technicianValue.tech = {
                  value: tech.tech_number,
                  label: `Technician ${tech.tech_number}`,
                  custom: true,
                };
                technicianValue.id = tech.id;
                technicianValue.hoursAmount = tech.hours_worked
                  ? tech.hours_worked.toString()
                  : '';
                technicianValue.workedFrom = tech.started_at || '';
                technicianValue.workedTo = tech.ended_at || '';
                technicianValue.customTask = tech.preset_task_other || '';
                technicianValue.location = tech.location || '';
                technicianValue.workPerformed = tech.work_performed || '';
                technicianValue.presetTasks = tech.preset_tasks.length
                  ? tech.preset_tasks.map((task) => {
                      return [task.id, task.label];
                    })
                  : [];
                if (!subTeches.hasOwnProperty(techId)) {
                  subTeches[techId] = technicianValue;
                } else {
                  const value = {
                    id: tech.id,
                    hoursAmount: tech.hours_worked
                      ? tech.hours_worked.toString()
                      : '',
                    workedFrom: tech.started_at || '',
                    workedTo: tech.ended_at || '',
                    customTask: tech.preset_task_other || '',
                    location: tech.location,
                    workPerformed: tech.work_performed,
                    presetTasks: tech.preset_tasks.length
                      ? tech.preset_tasks.map((task) => {
                          return [task.id, task.label];
                        })
                      : [],
                  };
                  if (subTeches[techId].additionalInfo) {
                    subTeches[techId].additionalInfo = [
                      ...subTeches[techId].additionalInfo,
                      value,
                    ];
                  } else {
                    subTeches[techId].additionalInfo = [value];
                  }
                }
              });
              setupTradeForm[entry.id].technicians = Object.keys(subTeches)
                .map((key) => subTeches[key])
                .sort((tech1, tech2) => tech1.tech.value - tech2.tech.value);
            } else if (
              isSubDailyReport &&
              !entry.sub_daily_report_teches.length
            ) {
              const defaultInfo = {
                hoursAmount: '',
                workedFrom: '',
                workedTo: '',
                location: '',
                customTask: '',
                workPerformed: '',
                presetTasks: [],
              };
              const defaultTechnicianForSub = {
                ...defaultInfo,
                tech: { value: 1, label: 'Technician 1', custom: true },
                additionalInfo: [],
              };

              setupTradeForm[entry.id] = {
                technicians: [defaultTechnicianForSub],
                deletedTechnicians: [],
                isDelay: false,
                isFieldWorkOrder: false,
                floor: '',
                area: '',
              };
            }
          });
        }

        if (
          resultResponse.dailyReport &&
          resultResponse.dailyReport.gc_staff_members &&
          resultResponse.dailyReport.gc_staff_members.length > 0
        ) {
          members = [];
          resultResponse.dailyReport.gc_staff_members.forEach((member) => {
            const res = {};
            res.user = {};
            res.supervisorTasks = [];
            res.id = member.id;
            res.user.value = member.user.id;
            res.user.label = `${member.user.first_name} ${member.user.last_name}`;

            if (member.supervisor_tasks && member.supervisor_tasks.length > 0) {
              member.supervisor_tasks.forEach((task, index) => {
                res.supervisorTasks[index] = [task.id, task.label];
              });
            }

            res.isChildFloor = !!members.find(
              (member2) => member2.user.value === member.user_id
            );
            res.floor = member.floor || '';
            res.area = member.area || '';
            res.comments = member.comments || '';
            res.customTask = member.supervisor_task_other || '';
            res.workedFrom = member.started_at || '';
            res.workedTo = member.ended_at || '';
            res.hoursAmount =
              member.started_at || member.ended_at
                ? ''
                : `${member.hours_worked || ''}`;

            members.push(res);
          });
        }

        if (members.length > 0) {
          dispatch(setMembers(members));
        }

        if (
          resultResponse.dailyReport &&
          resultResponse.dailyReport.project_open_item
        ) {
          const { project_open_item } = resultResponse.dailyReport;
          dispatch(
            setReportOpenItem({
              isDelay: true,
              openItemId: project_open_item.id,
              openItemAssigneeId: project_open_item.assignee_id,
              openItemDesc: project_open_item.description,
              openItemResolveBy: project_open_item.resolve_by,
              openItemCritical: project_open_item.critical,
              openItemRecipients: project_open_item.recipients.map((rcpt) => [
                rcpt.id,
              ]),
              openItemImages: project_open_item.images.map((image) => {
                return {
                  ...image,
                  url: image.image.gallery.url,
                };
              }),
              openItemDocuments: project_open_item.documents.map((doc) => {
                return {
                  ...doc,
                  url: doc.document.url,
                };
              }),
            })
          );
        }

        if (
          resultResponse.dailyReport &&
          resultResponse.dailyReport.report_date
        ) {
          dispatch(setReportDate(resultResponse.dailyReport.report_date));
        }

        dispatch(setDailyReportsIds(currentDailyReportIds));
        dispatch(setContributors(contributors));
        dispatch(initializeTradeForm(setupTradeForm));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadPresetTasksSuccess(presetTasks) {
  return {
    type: LOAD_PRESET_TASKS,
    payload: presetTasks,
  };
}

export function loadPresetTasksRequest(projectId) {
  return (dispatch) => {
    return csiCodesApi.getPresetTasks(projectId).then((response) => {
      if (response.ok) {
        dispatch(loadPresetTasksSuccess(response.presetTasks));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadSupervisorTasksSuccess(tasks) {
  return {
    type: SET_SUPERVISOR_TASKS,
    payload: tasks,
  };
}

export function loadSupervisorTasksRequest() {
  return (dispatch) => {
    return csiCodesApi.getSupervisorTasks().then((response) => {
      if (response.ok) {
        dispatch(loadSupervisorTasksSuccess(response.presetTasks));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

function prepareDataForMembers(members, isDeleted) {
  const resultValues = members.map((value) => {
    const res = {};
    if (value.id) {
      res.id = value.id;
    }
    res.user_id = value.user.value;
    res.floor = value.floor;
    res.area = value.area;
    res.supervisor_task_other = value.customTask;
    res.comments = value.comments;
    res.hours_worked = value.hoursAmount;
    res.started_at = utcTime(value.workedFrom);
    res.ended_at = utcTime(value.workedTo);
    if (value.supervisorTasks.length > 0) {
      res.supervisor_task_ids = value.supervisorTasks.map((task) => task[0]);
    } else {
      res.supervisor_task_ids = [];
    }
    if (isDeleted) {
      res._destroy = true;
    }
    return res;
  });
  return resultValues;
}

function prepareDataForOpenItem(openItem) {
  if (openItem.isDelay) {
    return {
      id: openItem.openItemId,
      assignee_id: openItem.openItemAssigneeId || '',
      recipient_ids:
        openItem.openItemRecipients &&
        openItem.openItemRecipients.map((rcpt) => rcpt[0]),
      description: openItem.openItemDesc || '',
      resolve_by: openItem.openItemResolveBy || '',
      critical: !!openItem.openItemCritical,
      images_attributes:
        openItem.openItemImages &&
        openItem.openItemImages.filter((img) => !!img.file),
      deleted_images_attributes:
        openItem.openItemDeletedImages &&
        openItem.openItemDeletedImages.filter((img) => !!img.id),
      documents_attributes:
        openItem.openItemDocuments &&
        openItem.openItemDocuments.filter((doc) => !!doc.file),
      deleted_documents_attributes:
        openItem.openItemDeletedDocuments &&
        openItem.openItemDeletedDocuments.filter((doc) => !!doc.id),
    };
  } else if (openItem.openItemId) {
    return {
      id: openItem.openItemId,
      _destroy: true,
    };
  }
}

export function addDailyReportMembers(
  projectId,
  reportId,
  members,
  deletedMembers,
  openItem,
  withSaveDraft,
  editMode,
  gcImages,
  gcDeletedImages
) {
  return (dispatch) => {
    const addedValues = prepareDataForMembers(members);
    let deletedValues = [];
    if (deletedMembers && deletedMembers.length > 0) {
      deletedValues = prepareDataForMembers(deletedMembers, true);
    }
    const resValues = {
      gc_staff_members_attributes: addedValues.concat(deletedValues),
      project_open_item_attributes: prepareDataForOpenItem(openItem),
      gcImages: gcImages.filter((img) => img.file),
      gcDeletedImages: gcDeletedImages.filter((img) => img.id),
    };

    return dailyReportsApi
      .addDailyReportMembers(resValues, projectId, reportId)
      .then((response) => {
        if (response.ok) {
          const { dailyReport } = response;
          const updatedValues = [...members];
          dailyReport.gc_staff_members.forEach((member, index) => {
            if (updatedValues[index]) {
              updatedValues[index] = { ...members[index] };
              updatedValues[index].id = member.id;
            }
          });

          dispatch(setMembers(updatedValues));
          dispatch(setDeletedMembers([]));
          const images =
            response.dailyReport.images.length > 0
              ? response.dailyReport.images.map((img) => ({
                  ...img,
                  url: img.image.url,
                }))
              : [];
          if (
            response.dailyReport.daily_report_entries &&
            response.dailyReport.daily_report_entries.length
          ) {
            response.dailyReport.daily_report_entries.sort(
              (entry1, entry2) => entry1.id - entry2.id
            );
          }
          dispatch(setCurrentDailyReport(response.dailyReport));
          dispatch(setGCImagesAction(images));
          dispatch(setGCDeletedImagesAction([]));
          if (!withSaveDraft && !editMode) {
            dispatch(setMemberForm(false));
            if (dailyReport.daily_report_entries.length > 0) {
              dispatch(setActiveContributor(1));
            } else {
              dispatch(setCurrentDailyReport(dailyReport));
              dispatch(setReviewFormAction(true));
            }
          }
          if (editMode) {
            dispatch(setCurrentDailyReport(dailyReport));
            dispatch(setMemberForm(false));
            dispatch(setReviewFormAction(true));
            dispatch(setEditMode(false));
          }

          if (!dailyReport.project_open_item) {
            dispatch(setReportOpenItem({}));
          } else {
            dispatch(
              setReportOpenItemValue(
                'openItemId',
                dailyReport.project_open_item.id
              )
            );
            dispatch(
              setReportOpenItemValue(
                'openItemRecipients',
                dailyReport.project_open_item.recipients.map((rcpt) => [
                  rcpt.id,
                ])
              )
            );
            dispatch(
              setReportOpenItemValue(
                'openItemImages',
                dailyReport.project_open_item.images.map((image) => {
                  return {
                    ...image,
                    url: image.image.gallery.url,
                  };
                })
              )
            );
            dispatch(
              setReportOpenItemValue(
                'openItemDocuments',
                dailyReport.project_open_item.documents.map((doc) => {
                  return {
                    ...doc,
                    url: doc.document.url,
                  };
                })
              )
            );
          }
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function addProjectReports(
  projectId,
  values,
  number,
  status,
  userId,
  isPrevent = false
) {
  return (dispatch) => {
    return projectReportsApi
      .addProjectReports(projectId, values, number, status, userId, isPrevent)
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));
        else
          dispatch(
            setProjectReportOpenDetails({ value: true, id: response.data.id })
          );

        return response;
      });
  };
}

export function updateProjectReports(
  projectId,
  projectReportId,
  values,
  status,
  userId
) {
  return (dispatch) => {
    return projectReportsApi
      .updateProjectReports(projectId, projectReportId, values, status, userId)
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));
        else
          dispatch(
            setProjectReportOpenDetails({ value: true, id: response.data.id })
          );

        return response;
      });
  };
}

export function getProjectReportResolveInfoByAttrSuccess(attr, data) {
  return {
    type: GET_PROJECT_REPORT_RESOLVED_INFO_BY_ATTR,
    payload: { attr, data },
  };
}

export function getProjectReportResolveInfoByAttr(
  projectId,
  projectReportId,
  attr
) {
  return (dispatch) => {
    return projectReportsApi
      .getProjectReportResolveInfoByAttr(projectId, projectReportId, attr)
      .then((response) => {
        if (response.ok) {
          const { data } = response;
          dispatch(getProjectReportResolveInfoByAttrSuccess(attr, data));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setActiveContributor(number) {
  resetScroll();
  return {
    type: SET_ACTIVE_CONTRIBUTOR,
    payload: number,
  };
}

export function setActiveEntriesId(number) {
  return {
    type: SET_ACTIVE_ENTRIES_ID,
    payload: number,
  };
}

export function restoreTradeForm() {
  return {
    type: RESTORE_TRADE_FORM,
  };
}

export function restoreMemberForm() {
  return {
    type: RESTORE_MEMBER_FORM,
  };
}

export function initializeTradeForm(value) {
  return {
    type: INITIALIZE_TRADE_FORM,
    payload: value,
  };
}

export function updateTradeForm(value) {
  return {
    type: UPDATE_TRADE_FORM,
    payload: value,
  };
}

export function handleWorkedFrom(value, contribId, techId) {
  return {
    type: HANDLE_WORKED_FORM,
    payload: { value, contribId, techId },
  };
}

export function handleDateMoreInfo(value, contribId, techId, key, infoIndex) {
  return {
    type: HANDLE_DATE_MORE_INFO,
    payload: { value, contribId, techId, key, infoIndex },
  };
}

export function handleWorkedTo(value, contribId, techId) {
  return {
    type: HANDLE_WORKED_TO,
    payload: { value, contribId, techId },
  };
}

export function handleAttributeChangeForSub(
  value,
  contribId,
  techId,
  attribute
) {
  return {
    type: HANDLE_ATTRIBUTE_CHANGE_FOR_SUB,
    payload: { value, contribId, techId, attribute },
  };
}

export function handleAttributeChangeForSubMoreAction(
  value,
  contribId,
  techId,
  attribute,
  infoIndex
) {
  return {
    type: HANDLE_ATTRIBUTE_CHANGE_FOR_SUB_MORE_ACTION,
    payload: { value, contribId, techId, attribute, infoIndex },
  };
}

export function handleHoursAmountAction(value, contribId, techId) {
  return {
    type: HANDLE_HOURS_AMOUNT,
    payload: { value, contribId, techId },
  };
}

export function handleHoursAmountActionMoreAction(
  value,
  contribId,
  techId,
  infoIndex
) {
  return {
    type: HANDLE_HOURS_AMOUNT_MORE_INFO,
    payload: { value, contribId, techId, infoIndex },
  };
}

export function changeAmountOfTechnicians(technicians, contribId) {
  return {
    type: CHANGE_AMOUNT_OF_TECHNICIANS,
    payload: { technicians, contribId },
  };
}

export function addedDeletedTechnicians(technician, contribId) {
  return {
    type: ADDED_DELETED_TECHNICIANS,
    payload: { technician, contribId },
  };
}

export function handleAttributeChangeAction(attribute, value, contribId) {
  return {
    type: HANDLE_ATTRIBUTE_CHANGE,
    payload: { attribute, value, contribId },
  };
}

export function setReviewFormAction(value) {
  return {
    type: SET_REVIEW_FORM,
    payload: value,
  };
}

export function setSubmittedReport(value) {
  return {
    type: SET_REPORT_SUBMITTED,
    payload: value,
  };
}

export function clearFormAction() {
  return {
    type: CLEAR_FORM,
  };
}

export function loadDailyReportLogPdfSuccess(dailyReportLogPdf) {
  return {
    type: SET_DAILY_REPORT_LOG_PDF,
    payload: dailyReportLogPdf,
  };
}

export function loadDailyReportLogPdfRequest(
  projectId,
  isSubDailyReports = false
) {
  const resultApi = isSubDailyReports ? subDailyReportsApi : dailyReportsApi;
  return (dispatch) => {
    return resultApi.getDailyReportLogPdf(projectId).then((response) => {
      if (response.ok) {
        dispatch(loadDailyReportLogPdfSuccess(response.dailyReportLogPdf));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function setDailyReportLogPdfViewer(dailyReportLogPdfViewer) {
  return {
    type: SET_DAILY_REPORT_LOG_PDF_VIEWER,
    payload: dailyReportLogPdfViewer,
  };
}

export function setEditMode(value) {
  resetScroll();
  return {
    type: SET_EDIT_MODE,
    payload: value,
  };
}

export function deleteDailyReportSuccess(dailyReportId) {
  return {
    type: DELETE_DAILY_REPORT,
    payload: dailyReportId,
  };
}

export function deleteReportRequest(
  projectId,
  dailyReportId,
  isSubDailyReports = false
) {
  const resultApi = isSubDailyReports ? subDailyReportsApi : dailyReportsApi;
  return (dispatch) => {
    return resultApi
      .deleteDailyReport(projectId, dailyReportId)
      .then((response) => {
        if (response.ok) {
          dispatch(deleteDailyReportSuccess(dailyReportId));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function deleteProjectReportSuccess(id) {
  return {
    type: DELETE_PROJECT_REPORT,
    payload: id,
  };
}

export function deleteProjectReport(projectId, projectReportId) {
  return (dispatch) => {
    return projectReportsApi
      .deleteProjectReports(projectId, projectReportId)
      .then((response) => {
        if (response.ok) {
          dispatch(deleteProjectReportSuccess(projectReportId));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setReportPdfViewer(pdfViewer) {
  return {
    type: SET_REPORT_PDF_VIEWER,
    payload: pdfViewer,
  };
}

export function loadReportPdfSuccess(reportPdf) {
  return {
    type: SET_REPORT_PDF,
    payload: reportPdf,
  };
}

export function loadDailyReportPdf(
  projectId,
  dailyReportId,
  isSubDailyReports = false
) {
  const resultApi = isSubDailyReports ? subDailyReportsApi : dailyReportsApi;
  return (dispatch) => {
    return resultApi
      .getDailyReportPdf(projectId, dailyReportId)
      .then((response) => {
        if (response.ok) {
          dispatch(loadReportPdfSuccess(response.reportPdf));
          dispatch(setReportPdfViewer(true));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function sendDailyReportEmails(resourceId, resourceType, emailData) {
  return (dispatch) => {
    return projectResourceMailerApi
      .postResourceEmails(resourceId, resourceType, emailData)
      .then((response) => {
        if (response.ok) {
          dispatch(setShowEmailModal(false));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setReportDate(date) {
  return {
    type: SET_REPORT_DATE,
    payload: date,
  };
}

export function setReportExtraInputs(value) {
  return {
    type: SET_REPORT_EXTRA_INPUTS,
    payload: value,
  };
}

export function setReportExtraInput(attribute, value) {
  return {
    type: SET_REPORT_EXTRA_INPUT,
    payload: { attribute: attribute, value: value },
  };
}

export function setReportOpenItem(openItem) {
  return {
    type: SET_REPORT_OPEN_ITEM,
    payload: openItem,
  };
}

export function setReportOpenItemValue(attribute, value) {
  return {
    type: SET_REPORT_OPEN_ITEM_VALUE,
    payload: { attribute, value },
  };
}

export function setGeneralContractorName(contrib) {
  return {
    type: SET_GENERAL_CONTACTOR_NAME,
    payload: contrib,
  };
}

export function setMemberForm(value) {
  return {
    type: SET_MEMBER_FORM,
    payload: value,
  };
}

export function setMembers(members) {
  return {
    type: SET_MEMBERS,
    payload: members,
  };
}

export function setDeletedMembers(members) {
  return {
    type: SET_DELETED_MEMBERS,
    payload: members,
  };
}

export function setCurrentPage(currentPage) {
  return {
    type: SET_CURRENT_PAGE,
    payload: currentPage,
  };
}

export function setReportsTotal(amount) {
  return {
    type: SET_REPORTS_TOTAL,
    payload: amount,
  };
}

export function setReportsPageSize(size) {
  return {
    type: SET_REPORTS_PAGE_SIZE,
    payload: size,
  };
}

export function setGCImagesAction(images) {
  return {
    type: SET_GCX_IMAGES,
    payload: images,
  };
}

export function setGCDeletedImagesAction(images) {
  return {
    type: SET_GCX_DELETED_IMAGES,
    payload: images,
  };
}

export function setDelivery(delivery) {
  return {
    type: SET_DELIVERY,
    payload: delivery,
  };
}

export function addExtraInput(value) {
  return {
    type: ADD_EXTRA_INPUT,
    payload: value,
  };
}

export function deleteDelivery(delivery) {
  return {
    type: DELETE_DELIVERY,
    payload: delivery,
  };
}

export function removeDeliveriesExtraInput() {
  return {
    type: REMOVE_DELIVERIES_EXTRA_INPUT,
  };
}

export function setDailyReportTab(tab) {
  return {
    type: SET_DAILY_REPORT_TAB,
    payload: tab,
  };
}

export function subDailyReportsCountSuccess(total) {
  return {
    type: SUB_DAILY_REPORTS_COUNT_SUCCESS,
    payload: total,
  };
}

export function subDailyReportsCount(projectId) {
  return (dispatch) => {
    return subDailyReportsApi
      .getDailyReportsCount(projectId)
      .then((response) => {
        if (response.ok) {
          dispatch(subDailyReportsCountSuccess(response.total));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function initSubDailyReportsCount() {
  return {
    type: SUB_DAILY_REPORTS_COUNT_INIT,
  };
}

export function buildFieldWorkOrderForm(fieldWorkOrder) {
  let status = 'pending';
  if (fieldWorkOrder) {
    if (
      fieldWorkOrder.status === 'gc_approved' ||
      fieldWorkOrder.status === 'sub_approved'
    ) {
      status = 'approved';
    }
    if (
      fieldWorkOrder.status === 'gc_denied' ||
      fieldWorkOrder.status === 'sub_denied'
    ) {
      status = 'denied';
    }
  }
  const formStatus = {
    value: status,
    label: status.charAt(0).toUpperCase() + status.slice(1),
  };
  return {
    fwoId: fieldWorkOrder && fieldWorkOrder.id,
    fwoNumber: fieldWorkOrder && fieldWorkOrder.number,
    fwoStatus: formStatus,
    fwoDeniedReason: fieldWorkOrder && (fieldWorkOrder.denied_reason || ''),
    fwoPresetTasks:
      fieldWorkOrder && fieldWorkOrder.preset_tasks
        ? fieldWorkOrder.preset_tasks.map((task) => [task.id, task.label])
        : [],
    fwoCustomTask: fieldWorkOrder && (fieldWorkOrder.preset_task_other || ''),
    fwoWorkPerformed: fieldWorkOrder && (fieldWorkOrder.work_performed || ''),
    fwoComments: fieldWorkOrder && (fieldWorkOrder.comments || ''),
    fwoStartDate: fieldWorkOrder && (fieldWorkOrder.start_date || ''),
    fwoCompletionDate: fieldWorkOrder && (fieldWorkOrder.completion_date || ''),
    fwoCostImpact: fieldWorkOrder && fieldWorkOrder.cost_impact,
    fwoCost: fieldWorkOrder && (fieldWorkOrder.cost || ''),
    fwoDelay: fieldWorkOrder && fieldWorkOrder.delay,
    fwoDelayReason: fieldWorkOrder && (fieldWorkOrder.delay_reason || ''),
    fwoLabor: fieldWorkOrder && (fieldWorkOrder.labor || ''),
    fwoMaterials: fieldWorkOrder && (fieldWorkOrder.materials || ''),
  };
}

export function initFieldWorkOrderForm(fieldWorkOrder) {
  return {
    type: FIELD_WORK_ORDER_FORM_INIT,
    payload: buildFieldWorkOrderForm(fieldWorkOrder),
  };
}

export function setFieldWorkOrderFormAttribute(attribute, value) {
  return {
    type: FIELD_WORK_ORDER_FORM_ATTRIBUTE_SET,
    payload: { attribute, value },
  };
}

export function setFieldWorkOrderEdit(value) {
  return {
    type: SET_FIELD_WORK_ORDER_EDIT,
    payload: value,
  };
}

export function setShowFieldWorkOrderError(value) {
  return {
    type: SET_SHOW_FIELD_WORK_ORDER_ERROR,
    payload: value,
  };
}

export function updateFieldWorkOrderSuccess(fieldWorkOrder) {
  return {
    type: UPDATE_FIELD_WORK_ORDER_SUCCESS,
    payload: fieldWorkOrder,
  };
}

export function updateFieldWorkOrder(projectId, fieldWorkOrderId, values) {
  return (dispatch) => {
    return fieldWorkOrdersApi
      .updateFieldWorkOrder(projectId, fieldWorkOrderId, values)
      .then((response) => {
        if (response.ok) {
          dispatch(updateFieldWorkOrderSuccess(response.fieldWorkOrder));
          dispatch(setFieldWorkOrderEdit(false));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setDailyReportAddFloor(value) {
  return {
    type: SET_DAILY_REPORT_ADD_FLOOR,
    payload: value,
  };
}

export function addDailyReportEntriesFloor(entryId, currentReport) {
  return (dispatch) => {
    return dailyReportsEntriesApi
      .postDailyReportEntry(entryId)
      .then((response) => {
        if (response.ok) {
          const newForm = {};
          const defaultTechnician = {
            hoursAmount: '',
            workedFrom: '',
            workedTo: '',
          };
          newForm[response.newEntry.id] = response.newEntry;
          newForm[response.newEntry.id].technicians = [defaultTechnician];
          const newReport = {
            ...currentReport,
            daily_report_entries: [
              ...currentReport.daily_report_entries,
              response.newEntry,
            ],
          };
          dispatch(initializeTradeForm(newForm));
          dispatch(setActiveEntriesId(response.newEntry.id));
          dispatch(addDailyReportSuccess(newReport));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}
export function deleteDailyReportEntry(entryId, currentReport) {
  return (dispatch) => {
    return dailyReportsEntriesApi
      .deleteDailyReportEntry(entryId)
      .then((response) => {
        if (response.ok) {
          const newEntries = currentReport.daily_report_entries.filter(
            (entry) => entry.id !== entryId
          );
          const newReport = {
            ...currentReport,
            daily_report_entries: newEntries,
          };
          dispatch(addDailyReportSuccess(newReport));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function addSubDailyReportEntriesFloor(entryId, currentReport) {
  return (dispatch) => {
    return subDailyReportsEntriesApi
      .postDailyReportEntry(entryId)
      .then((response) => {
        if (response.ok) {
          const newForm = {};
          const defaultInfo = {
            hoursAmount: '',
            workedFrom: '',
            workedTo: '',
            location: '',
            customTask: '',
            workPerformed: '',
            presetTasks: [],
          };
          const defaultTechnicianForSub = {
            ...defaultInfo,
            tech: { value: 1, label: 'Technician 1', custom: true },
            additionalInfo: [],
          };
          newForm[response.newEntry.id] = response.newEntry;
          newForm[response.newEntry.id].technicians = [defaultTechnicianForSub];
          const newReport = {
            ...currentReport,
            sub_daily_report_entries: [
              ...currentReport.sub_daily_report_entries,
              response.newEntry,
            ],
          };
          dispatch(initializeTradeForm(newForm));
          dispatch(setActiveEntriesId(response.newEntry.id));
          dispatch(addDailyReportSuccess(newReport));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}
export function deleteSubDailyReportEntry(entryId, currentReport) {
  return (dispatch) => {
    return subDailyReportsEntriesApi
      .deleteDailyReportEntry(entryId)
      .then((response) => {
        if (response.ok) {
          const newEntries = currentReport.sub_daily_report_entries.filter(
            (entry) => entry.id !== entryId
          );
          const newReport = {
            ...currentReport,
            sub_daily_report_entries: newEntries,
          };
          dispatch(addDailyReportSuccess(newReport));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function duplicateDailyReportSuccess(dailyReport) {
  return {
    type: DUPLICATE_DAILY_REPORT_SUCCESS,
    payload: dailyReport,
  };
}

export function duplicateDailyReport(
  projectId,
  dailyReportId,
  values,
  isSubDailyReport = false
) {
  const resultApi = isSubDailyReport ? subDailyReportsApi : dailyReportsApi;
  return (dispatch) => {
    return resultApi
      .duplicateDailyReport(values, projectId, dailyReportId)
      .then((response) => {
        if (response.ok) {
          dispatch(duplicateDailyReportSuccess(response.dailyReport));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadDailyReportInBkg(
  projectId,
  dailyReportId,
  isSubDailyReport = false
) {
  const resultApi = isSubDailyReport ? subDailyReportsApi : dailyReportsApi;
  return (dispatch) => {
    return resultApi.getDailyReport(projectId, dailyReportId, true);
  };
}

export function setProjectReportAttr(attr, value) {
  return {
    type: SET_PROJECT_REPORT_ATTR,
    payload: { attr, value },
  };
}

export function addProjectReportContactInformationItem(value = null) {
  return {
    type: ADD_PROJECT_REPORT_CONTACT_INFORMATION_ITEM,
    payload: value,
  };
}

export function addProjectReportProjectStatusItem(
  id,
  value = null,
  number,
  projectReportId,
  options
) {
  return {
    type: ADD_PROJECT_REPORT_PROJECT_STATUS_ITEM,
    payload: {
      id,
      value,
      number,
      projectReportId,
      restore: options && options.restore,
    },
  };
}

export function addProjectReportScheduleDescriptionById(id, value = null) {
  return {
    type: ADD_PROJECT_REPORT_SCHEDULE_DESCRIPTION_BY_ID,
    payload: { id, value },
  };
}

export function addProjectReportBudgetDescriptionById(
  id,
  value = null,
  parent_id = null
) {
  return {
    type: ADD_PROJECT_REPORT_BUDGET_DESCRIPTION_BY_ID,
    payload: { id, value, parent_id },
  };
}

export function deleteProjectReportTableItemByType(
  item,
  destroy = false,
  type
) {
  return {
    type: DELETE_PROJECT_REPORT_TABLE_ITEM_BY_TYPE,
    payload: { item, destroy, type },
  };
}

export function setProjectReportTableItemAttrByType(item, attr, value, type) {
  return {
    type: SET_PROJECT_REPORT_PROJECT_TABLE_ITEM_ATTR_BY_TYPE,
    payload: { item, attr, value, type },
  };
}

export function setProjectReportTableMultipleItemsByType(first, items, type) {
  return {
    type: SET_PROJECT_REPORT_TABLE_MULTIPLE_ITEMS_BY_TYPE,
    payload: { first, items, type },
  };
}

export function setProjectReportDocumentsPhotosAttr(attr, value) {
  return {
    type: SET_PROJECT_REPORT_DOCUMENTS_PHOTOS_ATTR,
    payload: { attr, value },
  };
}

export function loadProjectReportPdfSuccess(pdf) {
  return {
    type: SET_PROJECT_REPORT_PDF,
    payload: { pdf },
  };
}

export function setProjectReportPdfViewer(projectReportPdfViewer) {
  return {
    type: SET_PROJECT_REPORT_PDF_VIEWER,
    payload: projectReportPdfViewer,
  };
}

export function setProjectReportOpenDetails(value) {
  return {
    type: OPEN_PROJECT_REPORT_DETAILS,
    payload: value,
  };
}

export function resetProjectReportForm() {
  return {
    type: RESET_PROJECT_REPORT_FORM,
  };
}

export function setResolvedStatusByAttr(
  projectId,
  projectReportId,
  attr,
  item
) {
  return (dispatch) => {
    projectReportsApi
      .setResolvedStatusByAttr(projectId, projectReportId, attr, item)
      .then((response) => {
        if (response.ok) {
          const { data } = response;
          dispatch(deleteProjectReportTableItemByType(data, false, attr));
          dispatch(
            getProjectReportResolveInfoByAttr(projectId, projectReportId, attr)
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function projectReportRestoreItemByAttr(
  projectId,
  projectReportId,
  attr,
  item
) {
  return (dispatch) => {
    projectReportsApi
      .projectReportRestoreItemByAttr(projectId, projectReportId, attr, item)
      .then((response) => {
        if (response.ok) {
          switch (attr) {
            case 'contract_info':
              dispatch(addProjectReportContactInformationItem(response.data));
              break;
            case 'schedules':
              dispatch(
                addProjectReportScheduleDescriptionById(
                  item.project_report_schedule_category_id,
                  response.data
                )
              );
              break;
            case 'budgets':
              dispatch(
                addProjectReportBudgetDescriptionById(
                  item.project_report_budget_category_id,
                  response.data
                )
              );
              break;
            case 'statuses':
              dispatch(
                addProjectReportProjectStatusItem(
                  item.project_report_status_type_id,
                  response.data,
                  null,
                  null,
                  { restore: true }
                )
              );
              break;
            default:
              break;
          }
          dispatch(
            getProjectReportResolveInfoByAttr(projectId, projectReportId, attr)
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function addNewItemToProjectReportsTable(item, reportId) {
  return {
    type: ADD_ITEM_PROJECT_REPORT_TABLE,
    payload: { item, reportId },
  };
}

export function projectReportResolvedTableFilter(type, search, attr) {
  return {
    type: PROJECT_REPORT_RESOLVED_TABLE_FILTER,
    payload: { type, search, attr },
  };
}

export function projectReportPhotosAndDocumentsFilter(startDate, endDate) {
  return {
    type: PROJECT_REPORT_PHOTOS_AND_DOCUMENTS_FILTER,
    payload: { startDate, endDate },
  };
}

export function projectReportPhotosAndDocumentsFilterClear() {
  return {
    type: PROJECT_REPORT_PHOTOS_AND_DOCUMENTS_FILTER_CLEAR,
    payload: {},
  };
}

export function deleteProjectReportProjectStatus(id) {
  return {
    type: DELETE_PROJECT_REPORT_PROJECT_STATUS,
    payload: { id },
  };
}

export function sendToReviewProjectReport(
  projectId,
  projectReportId,
  emails,
  values
) {
  return (dispatch) => {
    projectReportsApi
      .sendToReviewProjectReport(projectId, projectReportId, emails, values)
      .then((response) => {
        if (response.ok) {
          dispatch(loadProjectReportsRequest(projectId, 1));
          dispatch(setProjectReportOpenDetails({ value: false, id: 0 }));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setProjectReportPreview(pdf = null) {
  return {
    type: SET_PROJECT_REPORT_PREVIEW,
    payload: { pdf },
  };
}

const WAIT_PREVIEW_TIMEOUT = 180 * 1000; // 3 minutes

function waitProjectReportPreview(
  projectId,
  projectReportId,
  originalPdf,
  dispatch,
  startDate
) {
  projectReportsApi
    .getProjectReport(projectId, projectReportId, false, true)
    .then((response) => {
      if (!response.ok) return dispatch(showErrorMessage(response.errors));

      if (new Date() - startDate > WAIT_PREVIEW_TIMEOUT) {
        dispatch(setProjectReportPreview());
        dispatch(showErrorMessage('timeout waiting for preview'));
        return;
      }

      const pdf = response.data.pdf;
      if (
        !pdf ||
        !pdf.document ||
        !pdf.document.url ||
        (originalPdf && originalPdf.updated_at === pdf.updated_at)
      )
        return window.setTimeout(
          () =>
            waitProjectReportPreview(
              projectId,
              projectReportId,
              originalPdf,
              dispatch,
              startDate
            ),
          3000
        );

      return window.setTimeout(
        () => dispatch(setProjectReportPreview(pdf.document.url)),
        3000
      );
    });
}

export function previewProjectReport(projectId, projectReportId, values) {
  return (dispatch) => {
    projectReportsApi
      .previewProjectReport(projectId, projectReportId, values)
      .then((response) => {
        if (response.ok) {
          dispatch(setProjectReportPreview('loading'));
          window.setTimeout(() => {
            dispatch(loadProjectReportRequest(projectId, projectReportId));
            dispatch(
              setProjectReportOpenDetails({ value: true, id: projectReportId })
            );
            waitProjectReportPreview(
              projectId,
              projectReportId,
              response.data.pdf,
              dispatch,
              new Date()
            );
          }, 50);
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setProjectReportsPermissions(permissions) {
  return {
    type: SET_PROJECT_REPORTS_PERMISSIONS,
    payload: permissions,
  };
}

export function sendProjectReportsPermissions(projectId, permissions) {
  return (dispatch) => {
    projectReportsApi
      .sendProjectReportsPermissions(projectId, permissions)
      .then((response) => {
        if (response.ok) {
          const { data } = response;
          dispatch(
            setProjectReportsPermissions(data.project_report_permissions)
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadProjectReportsLogPdfSuccess(projectReportsLogPdf) {
  return {
    type: SET_DAILY_REPORT_LOG_PDF,
    payload: projectReportsLogPdf,
  };
}

export function loadProjectReportsLogPdfRequest(projectId) {
  return (dispatch) => {
    return projectReportsApi
      .getProjectReportsPdf(projectId)
      .then((response) => {
        if (response.ok) {
          dispatch(
            loadProjectReportsLogPdfSuccess(response.projectReportsLogPdf)
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function sendToEmailProjectReport(
  projectId,
  projectReportId,
  emails,
  subject,
  message
) {
  return (dispatch) => {
    return projectReportsApi
      .sendToEmailProjectReport(
        projectId,
        projectReportId,
        emails,
        subject,
        message
      )
      .then((response) => {
        if (response.ok) {
          dispatch(loadProjectReportsRequest(projectId, 1));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function getProjectReportsPreviousImageAndDocuments(projectId) {
  return (dispatch) => {
    return projectReportsApi
      .getProjectReportsPreviousImageAndDocuments(projectId)
      .then((response) => {
        if (response.ok) {
          dispatch(
            getProjectReportResolveInfoByAttrSuccess(
              'photosAndDocuments',
              response.data
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadProjectReportPdfPrint(projectId, projectReportId, type) {
  return (dispatch) => {
    return projectReportsApi
      .getProjectReportPdfPrint(projectId, projectReportId, type)
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));
        return response;
      });
  };
}

export function loadProjectReportExcelExport(projectId, projectReportId, type) {
  return (dispatch) => {
    return projectReportsApi
      .getProjectReportExcelExport(projectId, projectReportId, type)
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));

        return response;
      });
  };
}

export const setProjectReportTablesOrderChange = (result, type) => (
  dispatch
) => {
  dispatch({
    type: PROJECT_REPORT_TABLES_ORDER_CHANGE,
    payload: { result, type },
  });
  return Promise.resolve();
};

export function updateContractInfoOrderRequest(
  projectId,
  projectReportId,
  contractInfo
) {
  return (dispatch) => {
    return projectReportsApi
      .updateContractInfoOrder(projectId, projectReportId, contractInfo)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export function updateBudgetOrderRequest(
  projectId,
  projectReportId,
  budgetId,
  budget,
  result
) {
  return (dispatch) => {
    return projectReportsApi
      .updateBudgetOrder(projectId, projectReportId, budgetId, budget, result)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export function updateScedulesOrderRequest(
  projectId,
  projectReportId,
  scheduleId,
  schedules
) {
  return (dispatch) => {
    return projectReportsApi
      .updateScheduleOrder(projectId, projectReportId, scheduleId, schedules)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export function setProjectPhotosAndDocumentsFilteringActive(active) {
  return {
    type: SET_PROJECT_PHOTO_AND_DOCUMENTS_FILTERING_ACTIVE,
    payload: active,
  };
}

export function setProjectReportInitialTab(initalTab = null) {
  return {
    type: SET_PROJECT_REPORT_INITIAL_TAB,
    payload: initalTab,
  };
}
