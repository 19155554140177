import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SubmittalModal.css';
import FormModal from 'components/shared/modal/FormModal';
import SubmittalForm from 'components/admin/projects/details/project-submittal-log/SubmittalForm';
import SubmittalCard from 'components/admin/projects/details/project-submittal-log/SubmittalCard';
import SubmittalComments from 'components/admin/projects/details/project-submittal-log/SubmittalComments';
import SubmittalLeadTimeForm from 'components/admin/projects/details/project-submittal-log/SubmittalLeadTimeForm';
import SubmittalActions from 'components/admin/projects/details/project-submittal-log/SubmittalActions';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { setSubmittalPdfViewer } from 'components/admin/projects/details/project-submittal-log/store/actions';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import moment from 'moment';
import { downloadFile } from 'services/utils/download-util';
import SubmittalPermissions from 'permissions/submittal-permissions';
import Submittal from 'domain/submittal';

class SubmittalModal extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({}).isRequired,
    project: PropTypes.shape({}).isRequired,
    directory: PropTypes.shape({}).isRequired,
    submittal: PropTypes.shape({}),
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  handleViewPdf = () => {
    this.props.dispatch(setSubmittalPdfViewer(true));
  };

  handleShowEmailModal = () => {
    this.props.dispatch(setShowEmailModal(true));
  };

  hasPdf = () => {
    const {
      submittal: { pdf_document },
    } = this.props;
    return pdf_document && pdf_document.document && pdf_document.document.url
      ? true
      : false;
  };

  handleDownloadPdf = () => {
    const { submittal, project } = this.props;
    const { formatted_number: number, pdf_document } = submittal;
    const file = pdf_document.document.url + `?time=${moment().unix()}`;
    const fileName = `${project.name} - Submittal ${number ? number : 'Draft'}`;
    downloadFile(file, fileName);
  };

  render() {
    const {
      currentUser,
      project,
      directory,
      submittal,
      show,
      handleClose,
    } = this.props;

    const newRevision = Submittal.isNewRevision(submittal);
    const showLeadTime = SubmittalPermissions.canViewLeadTime(submittal);

    return (
      <FormModal
        className={`submittal-modal ${!submittal ? 'create-submittal' : ''}`}
        show={show}
        onClose={handleClose}
        subTitle={`${project.name} - ${
          newRevision
            ? 'New Revision'
            : submittal
            ? `Submittal ${submittal.formatted_number}`
            : 'Add Submittal'
        }`}
        sidebar={submittal && <SubmittalComments submittal={submittal} />}
        actions={[
          submittal && !newRevision && (
            <Button
              key="email"
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label="Email"
              onClick={this.handleShowEmailModal}
            />
          ),
          submittal && !newRevision && this.hasPdf() && (
            <Button
              key="view_pdf"
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label="View PDF"
              onClick={this.handleViewPdf}
            />
          ),
          submittal && !newRevision && this.hasPdf() && (
            <Button
              key="download_pdf"
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label="Download"
              onClick={this.handleDownloadPdf}
            />
          ),
        ].filter((action) => action)}
      >
        <div className="submittal-body">
          <SubmittalForm
            currentUser={currentUser}
            project={project}
            directory={directory}
            submittal={submittal}
          />

          <SubmittalCard
            currentUser={currentUser}
            project={project}
            directory={directory}
            submittal={submittal}
          />

          {showLeadTime && !newRevision && <SubmittalLeadTimeForm />}
        </div>

        <SubmittalActions
          currentUser={currentUser}
          project={project}
          directory={directory}
          submittal={submittal}
          handleClose={handleClose}
        />
      </FormModal>
    );
  }
}

export default connect()(SubmittalModal);
