import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import RfaLogTable from './RfaLogTable';
import RfaSearchFilter from './RfaSearchFilter';
import ModalRfaLogPdf from './ModalRfaLogPdf';
import ModalRfaLogEmail from './ModalRfaLogEmail';
import ModalRfaLogDeleteConfirmation from './ModalRfaLogDeleteConfirmation';
import ModalRfaCreateOptions from './ModalRfaCreateOptions';
import ModalRfa from './ModalRfa';
import RfaFilters from './RfaFilters';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import NavigationBar from 'components/shared/navigation/NavigationBar';

import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getRfaTabNavigation,
  getRfaFilters,
  getRfaDataChanged,
} from 'components/admin/projects/details/project-rfa-log/store/selectors';

import {
  setRfaTabNavigation,
  loadRfasRequest,
  loadRfasLogPdfRequest,
  startNewRfa,
} from 'components/admin/projects/details/project-rfa-log/store/actions';

import { rfaTabs, PRFA_TAB } from 'domain/rfa';
import RfaPermissions from 'permissions/rfa-permissions';

import './ProjectRfaLog.css';

const ProjectRfaLog = (props) => {
  const {
    dispatch,
    renderHeaders,
    currentUser,
    currentProject,
    currentFilters,
    tabNavigation,
    dataChanged,
  } = props;

  if (!currentProject) return null;

  const canSubmit = RfaPermissions.canSubmit(currentUser, currentProject);

  const handleFilterChange = (filters) => {
    dispatch(loadRfasRequest(currentProject.id, filters));
  };
  const handleTabNavChange = (item) => {
    dispatch(setRfaTabNavigation(item));
    handleFilterChange({ pre: item === PRFA_TAB });
  };
  const handleAdd = () => dispatch(startNewRfa(currentUser, currentProject));
  const handleViewPdfLogs = () => {
    dispatch(loadRfasLogPdfRequest(currentProject.id, currentFilters.pre));
  };

  useEffect(() => {
    if (!canSubmit) currentFilters.pre = null;

    handleFilterChange(currentFilters);
  }, []);

  useEffect(() => {
    if (!dataChanged) return;

    handleFilterChange(currentFilters);
  }, [dataChanged]);

  const headerButtons = [
    <RfaSearchFilter
      key="search-rfa"
      handleFilterChange={handleFilterChange}
    />,
    <Button
      key="add-rfa"
      type={BUTTON_TYPES.LINK}
      icon={BUTTON_ICONS.PLUS}
      label="Add RFA"
      onClick={handleAdd}
    />,
    <Button
      key="view-rfa-pdf-log"
      type={BUTTON_TYPES.LINK}
      color={BUTTON_COLORS.GREEN}
      label="View Log"
      onClick={handleViewPdfLogs}
    />,
  ];

  const navigationBar = canSubmit && (
    <div className="rfa-log-header">
      <NavigationBar
        items={rfaTabs}
        selectedValue={tabNavigation}
        onSelected={handleTabNavChange}
      />
    </div>
  );

  return (
    <div className="rfa-log-content-container">
      <div style={{ marginLeft: 20, paddingTop: 20 }}>
        {renderHeaders(headerButtons)}
      </div>
      {navigationBar}
      <RfaLogTable handleFilterChange={handleFilterChange} />
      <ModalRfaLogPdf />
      <ModalRfaLogEmail />
      <ModalRfaLogDeleteConfirmation />
      <ModalRfaCreateOptions />
      <ModalRfa />
      <RfaFilters handleFilterChange={handleFilterChange} />
    </div>
  );
};

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    currentFilters: getRfaFilters(state),
    tabNavigation: getRfaTabNavigation(state),
    dataChanged: getRfaDataChanged(state),
  };
})(ProjectRfaLog);
