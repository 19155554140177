import {
  NEW_PROJECT,
  EDIT_PROJECT,
  NEXT_STAGE,
  PREV_STAGE,
  NEW_PROJECT_FORM_VALUE_CHANGE,
  NEW_PROJECT_GC_SET,
  NEW_PROJECT_CURRENT_PAGE_SET,
  NEW_PROJECT_CLIENT_FORM_VALUE_CHANGE,
  NEW_PROJECT_PROJECT_LOCATION_FORM_VALUE_CHANGE,
  NEW_PROJECT_COMPANY_FORM_VALUE_CHANGE,
  NEW_PROJECT_NEW_CLIENT_MODAL_SHOW,
  NEW_PROJECT_NEW_CLIENT_FORM_CLEAN,
  NEW_PROJECT_NEW_PROJECT_LOCATION_MODAL_SHOW,
  NEW_PROJECT_NEW_PROJECT_LOCATION_FORM_CLEAN,
  NEW_PROJECT_NEW_COMPANY_MODAL_SHOW,
  NEW_PROJECT_NEW_COMPANY_FORM_CLEAN,
  NEW_PROJECT_ID_SET,
  EDIT_PROJECT_FORM_SET_VALUES,
  SUBSCRIBERS_ADD_TO_BEGINNING,
  NEW_PROJECT_CLIENTS_ADD_TO_BEGINNING,
  NEW_PROJECT_CLIENTS_SET,
  NEW_PROJECT_CLIENTS_SET_PAGINATION_DATA,
} from 'components/admin/projects/creation/store/action-types';
import { showErrorMessage } from 'actions/messages';
import { projectsApi } from 'services/api/projects-api';
import { companiesApi } from 'services/api/companies-api';
import { projectLocationsApi } from 'services/api/project-locations-api';

export function newProject() {
  return {
    type: NEW_PROJECT,
  };
}

export function editProject() {
  return {
    type: EDIT_PROJECT,
  };
}

export function nextStage() {
  return {
    type: NEXT_STAGE,
  };
}

export function prevStage() {
  return {
    type: PREV_STAGE,
  };
}

export function setProjectFormValue(attribute, value) {
  return {
    type: NEW_PROJECT_FORM_VALUE_CHANGE,
    payload: { attribute, value },
  };
}

export function setProjectFormValues(project) {
  return {
    type: EDIT_PROJECT_FORM_SET_VALUES,
    payload: project,
  };
}

export function setNewProjectId(newProjectId) {
  return {
    type: NEW_PROJECT_ID_SET,
    payload: newProjectId,
  };
}

export function createProject(values) {
  return (dispatch) => {
    return projectsApi.createProject(values).then((response) => {
      if (response.ok) {
        dispatch(setNewProjectId(response.project.id));
        dispatch(nextStage());
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function updateProject(id, values) {
  return (dispatch) => {
    return projectsApi.updateProject(id, values).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function setSubscribers(subscribers) {
  return {
    type: NEW_PROJECT_GC_SET,
    payload: subscribers,
  };
}

export function changePage(page) {
  return {
    type: NEW_PROJECT_CURRENT_PAGE_SET,
    payload: page,
  };
}

export function loadSubscribersRequest(page, name) {
  return (dispatch) => {
    return companiesApi
      .getCompanies(null, name, page, true, true)
      .then((response) => {
        if (response.ok) {
          dispatch(
            setSubscribers({
              ...response,
              data: response.companies,
            })
          );
          dispatch(changePage(page));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setClientFormValue(attribute, value) {
  return {
    type: NEW_PROJECT_CLIENT_FORM_VALUE_CHANGE,
    payload: { attribute, value },
  };
}

export function setShowNewClientModal(value) {
  return {
    type: NEW_PROJECT_NEW_CLIENT_MODAL_SHOW,
    payload: value,
  };
}

export function cleanClientFormValues() {
  return {
    type: NEW_PROJECT_NEW_CLIENT_FORM_CLEAN,
  };
}

export function findSimilarClient(values) {
  return (dispatch) => {
    return companiesApi
      .findSimilar({
        name: values.name,
        email: values.email,
        phone_number: values.phoneNumber,
      })
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        }

        return { ...response, client: response.company };
      });
  };
}

export function createClient(values) {
  return (dispatch) => {
    return companiesApi.createCompany(values, true).then((response) => {
      if (response.ok) {
        dispatch(setProjectFormValue('clientId', response.company.id));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function inviteExistentClient(clientId) {
  return (dispatch) => {
    return companiesApi.inviteExistent(clientId).then((response) => {
      if (response.ok) {
        dispatch(setProjectFormValue('clientId', clientId));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function setProjectLocationFormValue(attribute, value) {
  return {
    type: NEW_PROJECT_PROJECT_LOCATION_FORM_VALUE_CHANGE,
    payload: { attribute, value },
  };
}

export function cleanProjectLocationFormValues() {
  return {
    type: NEW_PROJECT_NEW_PROJECT_LOCATION_FORM_CLEAN,
  };
}

export function setShowNewProjectLocationModal(value) {
  return {
    type: NEW_PROJECT_NEW_PROJECT_LOCATION_MODAL_SHOW,
    payload: value,
  };
}

export function createProjectLocation(values) {
  return (dispatch) => {
    return projectLocationsApi
      .createProjectLocation(values)
      .then((response) => {
        if (response.ok) {
          dispatch(
            setProjectFormValue(
              'projectLocationId',
              response.project_location.id
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setCompanyFormValue(attribute, value) {
  return {
    type: NEW_PROJECT_COMPANY_FORM_VALUE_CHANGE,
    payload: { attribute, value },
  };
}

export function setShowNewCompanyModal(value) {
  return {
    type: NEW_PROJECT_NEW_COMPANY_MODAL_SHOW,
    payload: value,
  };
}

export function cleanCompanyFormValues() {
  return {
    type: NEW_PROJECT_NEW_COMPANY_FORM_CLEAN,
  };
}

export function createSubscriber(values) {
  return (dispatch) => {
    return companiesApi.createCompany(values).then((response) => {
      if (response.ok) {
        dispatch(setProjectFormValue('creatorCompanyId', response.company.id));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function addSubscriberToBeginning(company) {
  return {
    type: SUBSCRIBERS_ADD_TO_BEGINNING,
    payload: company,
  };
}

export function addClientToBeginning(company) {
  return {
    type: NEW_PROJECT_CLIENTS_ADD_TO_BEGINNING,
    payload: company,
  };
}

export function loadClientsSuccess(clients) {
  return {
    type: NEW_PROJECT_CLIENTS_SET,
    payload: clients,
  };
}

export function setClientsPaginationData(page, total, pageSize) {
  return {
    type: NEW_PROJECT_CLIENTS_SET_PAGINATION_DATA,
    payload: { page: page, total: total, pageSize: pageSize },
  };
}

export function loadClientsRequest(page, name) {
  return (dispatch) => {
    return companiesApi.getCompanies(null, name, page).then((response) => {
      if (response.ok) {
        dispatch(loadClientsSuccess(response.companies));
        if (page)
          dispatch(
            setClientsPaginationData(page, response.total, response.pageSize)
          );
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}
