const keys = {};

export const SubmittalStatusKeys = {
  DRAFT: 'draft',
  PENDING: 'pending',
  DRAFT_PENDING: 'draft_pending',
  ASSIGNED: 'assigned',
  DRAFT_ASSIGNED: 'draft_assigned',
  APPROVED: 'approved',
  APPROVED_AS_NOTED: 'approved_as_noted',
  REVISE: 'revise',
  REJECTED: 'rejected',
  NOT_REVIEWED: 'not_reviewed',
  FOR_RECORD: 'for_record',
  DISCARDED: 'discarded',
};

export class SubmittalStatusEnum {
  constructor(status, label) {
    this.status = status;
    this.label = label;

    keys[status] = this;
  }

  static create(status) {
    if (keys[status]) {
      return keys[status];
    }

    return SubmittalStatusEnum.UNKNOWN;
  }

  equals(submittalStatusEnum) {
    return this.status === submittalStatusEnum.status;
  }
}

SubmittalStatusEnum.DRAFT = new SubmittalStatusEnum('draft', 'Draft');
SubmittalStatusEnum.PENDING = new SubmittalStatusEnum('pending', 'Pending');
SubmittalStatusEnum.DRAFT_PENDING = new SubmittalStatusEnum(
  'draft_pending',
  'Draft - Pending'
);
SubmittalStatusEnum.ASSIGNED = new SubmittalStatusEnum('assigned', 'Assigned');
SubmittalStatusEnum.DRAFT_ASSIGNED = new SubmittalStatusEnum(
  'draft_assigned',
  'Draft - Assigned'
);
SubmittalStatusEnum.APPROVED = new SubmittalStatusEnum(
  'approved',
  'No Exceptions Taken/Approved'
);
SubmittalStatusEnum.APPROVED_AS_NOTED = new SubmittalStatusEnum(
  'approved_as_noted',
  'Corrections Required as Noted'
);
SubmittalStatusEnum.REVISE = new SubmittalStatusEnum(
  'revise',
  'Revise and Resubmit as Noted'
);
SubmittalStatusEnum.REJECTED = new SubmittalStatusEnum('rejected', 'Rejected');
SubmittalStatusEnum.NOT_REVIEWED = new SubmittalStatusEnum(
  'not_reviewed',
  'Not Reviewed'
);
SubmittalStatusEnum.FOR_RECORD = new SubmittalStatusEnum(
  'for_record',
  'For Record Only'
);
SubmittalStatusEnum.DISCARDED = new SubmittalStatusEnum(
  'discarded',
  'Discarded'
);

export function submittalStatusLabel(submittal) {
  const statusEnum = SubmittalStatusEnum.create(submittal.status);
  let label = submittal.stamp_title || statusEnum.label;

  if (
    !SubmittalStatusEnum.DRAFT.equals(statusEnum) &&
    !SubmittalStatusEnum.DRAFT_PENDING.equals(statusEnum) &&
    !SubmittalStatusEnum.PENDING.equals(statusEnum) &&
    !SubmittalStatusEnum.DRAFT_ASSIGNED.equals(statusEnum) &&
    !SubmittalStatusEnum.DISCARDED.equals(statusEnum)
  ) {
    let status = 'Pending';
    if (submittal.revised) status = 'Revised';
    else if (submittal.closed) status = 'Closed';

    label = `${status} - ${label}`;
  } else if (submittal.revised) label = 'Revised';

  return label;
}
