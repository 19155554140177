import React, { useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Swiper from 'components/shared/swiper/Swiper';
import Date from 'components/shared/text/Date';
import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';
import MessageModal, {
  MODAL_ICONS,
} from 'components/shared/modal/MessageModal';
import { getDarkMode } from 'selectors/theme';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getReports } from './store/selectors';
import { goToProjectDetails } from 'components/admin/projects/store/actions';
import './ProjectReports.css';
import reportSvg from './images/report.svg';
import reportSvgDark from './images/report-dark.svg';
import reportDisabledSvg from './images/report-disabled.svg';
import reportDisabledSvgDark from './images/report-disabled-dark.svg';

const ProjectReports = (props) => {
  const { currentProject, reports, dispatch, darkMode } = props;
  const [warningMsg, setWarningMsg] = useState(null);
  const [reportPdf, setReportPdf] = useState(null);

  const hasReports = !!reports && reports.length;

  const getReportPdfUrl = (report) =>
    report.pdf && report.pdf.document && report.pdf.document.url;

  const handleViewMore = () => {
    dispatch(goToProjectDetails(currentProject.id, { projectReports: true }));
  };

  const handleOpenReport = (report) => {
    const url = getReportPdfUrl(report);
    if (url) {
      setReportPdf({ url: url });
    } else {
      setWarningMsg({
        message:
          'Your report is being prepared, please try again in a few minutes.',
      });
    }
  };

  const getIcon = (disabled) => {
    return disabled
      ? darkMode
        ? reportDisabledSvgDark
        : reportDisabledSvg
      : darkMode
      ? reportSvgDark
      : reportSvg;
  };

  const makeSlide = (report, index) => {
    const { number, date, disabled } = report;
    return {
      className: 'report-item',
      content: (
        <React.Fragment>
          <div className="report-item-tint" />
          <div className="report-item-icon">
            <img src={getIcon(disabled)} alt="report" />
          </div>
          <div className="report-item-details">
            {!disabled ? (
              <React.Fragment>
                <div className="report-item-name">Report No {number}</div>
                <div className="report-item-date">
                  <Date value={date} />
                </div>
              </React.Fragment>
            ) : (
              <div className="report-item-disabled-text">No Reports Added</div>
            )}
          </div>
        </React.Fragment>
      ),
      onClick: () => handleOpenReport(report),
    };
  };

  return (
    <div
      className={classnames('project-dashboard-project-reports', {
        'dark-mode': darkMode,
        disabled: !hasReports,
      })}
    >
      <div className="reports-title">Project Report</div>
      {hasReports ? (
        <Swiper
          className="reports-swiper"
          slides={reports.map(makeSlide)}
          spaceBetween={32}
          viewMore={{
            onClick: handleViewMore,
          }}
        />
      ) : (
        <div className="report-item">
          {makeSlide({ disabled: true }, 0).content}
        </div>
      )}
      {!!warningMsg && (
        <MessageModal
          show={true}
          message={warningMsg.message}
          icon={MODAL_ICONS.EXCLAMATION}
          onHide={() => setWarningMsg(null)}
        />
      )}
      {!!reportPdf && (
        <PdfViewer
          content={{
            source: reportPdf.url,
            type: 'url',
          }}
          show={true}
          onClose={() => setReportPdf(null)}
        />
      )}
    </div>
  );
};

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    reports: getReports(state),
    darkMode: getDarkMode(state),
  };
})(ProjectReports);
