import heic2any from 'heic2any';

const addData = (blob, data) => {
  const { newName, webkitRelativePath, lastModified, preview } = data;
  const blobData = blob.length > 0 ? blob[0] : blob;
  return new File([blobData], newName, {
    webkitRelativePath,
    lastModified,
    preview,
  });
};

const convertFile = async (blob, data) => {
  const conversionResult = await heic2any({ blob });
  if (!conversionResult) throw new Error('Conversion failed');
  return addData(conversionResult, data);
};

const processBlob = async (file) => {
  let blobUrl = URL.createObjectURL(file);
  if (!blobUrl) throw new Error('Blob URL creation failed');

  let blobRes = await fetch(blobUrl);
  if (!blobRes.ok) throw new Error('Failed to fetch blob URL');

  let blob = await blobRes.blob();
  return blob;
};

const convertHeicFile = async (file) => {
  if (file.name.split('.')[1] !== 'heic') return file;

  const { name, webkitRelativePath, lastModified, preview } = file;
  const newName = name.split('.heic')[0] + '.png';

  const processedBlob = await processBlob(file);

  return await convertFile(processedBlob, {
    newName,
    webkitRelativePath,
    lastModified,
    preview,
  });
};

export default convertHeicFile;
