import Submittal from 'domain/submittal';
import User from 'domain/user';

class SubmittalPermissions {
  canManage = (user, project, directory, submittal) => {
    if (User.isAdminOrProjectSubscriber(user, project)) return true;

    if (Submittal.isSubmittedByGcOar(directory, submittal))
      return Submittal.isSubmittedByUserCompany(user, submittal);

    return User.isGcOar(user, project);
  };

  canEdit = (user, project, directory, submittal) =>
    !submittal ||
    this.canManage(user, project, directory, submittal) ||
    Submittal.isSubcontractorUser(user, project, submittal);

  canExportTransmittal = (
    user,
    project,
    directory,
    submittal,
    { status, physicalCopies }
  ) =>
    physicalCopies > '0' &&
    this.canEdit(user, project, directory, submittal) &&
    Submittal.isPendingOrAssigned(status);

  canMarkResolved = (
    user,
    project,
    directory,
    submittal,
    { status, stampAction, closed }
  ) =>
    !!stampAction &&
    !closed &&
    this.canManage(user, project, directory, submittal) &&
    !Submittal.isPendingOrAssigned(status);

  canDiscard = (user, project, directory, submittal, { status, newRevision }) =>
    !!submittal &&
    !newRevision &&
    Submittal.isDiscartable(status) &&
    this.canEdit(user, project, directory, submittal);

  canAccelerate = (user, project, directory, submittal, { newRevision }) =>
    !!submittal &&
    !newRevision &&
    this.canManage(user, project, directory, submittal) &&
    !Submittal.isAssignedOrAddlAssignedToUser(user, submittal);

  canSaveDraft = (user, project, directory, submittal) => {
    if (Submittal.isAddlAssignedToUser(user, submittal)) return false;

    const status = submittal && submittal.status;
    if (!status) return true;

    if (
      this.canManage(user, project, directory, submittal) &&
      (Submittal.isDraftPending(status) || Submittal.isPending(status))
    )
      return true;

    if (
      Submittal.isAssignedToUser(user, submittal) &&
      (Submittal.isDraftAssigned(status) || Submittal.isAssigned(status))
    )
      return true;

    return false;
  };

  canEditClosed = (user, project, directory, submittal, { status, closed }) => {
    if (!submittal) return false;

    if (
      (closed || submittal.closed || Submittal.isDiscarded(submittal.status)) &&
      !User.isAdmin(user)
    )
      return false;

    return (
      this.canManage(user, project, directory, submittal) ||
      Submittal.isAssignedToUser(user, submittal) ||
      (Submittal.isSubcontractorUser(user, project, submittal) &&
        Submittal.isDraftOrPending(status))
    );
  };

  canViewLeadTime = (submittal) =>
    !!submittal &&
    !!submittal.closed &&
    Submittal.isApprovedOrAsNoted(submittal.status);

  canEditLeadTime = (user, project, directory, submittal) => {
    if (!this.canViewLeadTime(submittal)) return false;

    if (Submittal.isDiscarded(submittal.status) && !User.isAdmin(user))
      return false;

    return (
      this.canManage(user, project, directory, submittal) ||
      (Submittal.isSubcontractorUser(user, project, submittal) &&
        !submittal.field_delivery)
    );
  };

  canAssign = (user, project, directory, submittal) =>
    this.canManage(user, project, directory, submittal);

  canEditStatus = (user, project, { status, closed }) =>
    !User.isContractor(user, project) &&
    !closed &&
    !Submittal.isDiscarded(status);

  canAddRemarks = (user, submittal, gcAcceleration) =>
    !Submittal.isAddlAssignedToUser(user, submittal) &&
    (gcAcceleration || Submittal.isAssignedToUser(user, submittal));

  canRespond = (user, submittal, gcAcceleration) =>
    gcAcceleration || Submittal.isAssignedToUser(user, submittal);

  canViewPending = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project) ||
    User.isContractor(user, project);

  canViewSubmitted = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project);

  canViewDraft = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project) ||
    User.isContractor(user, project);

  canCreate = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project) ||
    User.isContractor(user, project);

  canRevise = (user, project, directory, submittal) =>
    !submittal.revised &&
    (Submittal.isSubcontractorUser(user, project, submittal) ||
      this.canManage(user, project, directory, submittal));
}

export default new SubmittalPermissions();
