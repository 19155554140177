/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Swiper from 'components/shared/swiper/Swiper';
import Date from 'components/shared/text/Date';
import { getDarkMode } from 'selectors/theme';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getPhotos } from './store/selectors';
import { goToProjectDetails } from 'components/admin/projects/store/actions';
import './Photos.css';
import disabledSvg from './images/disabled-photos.svg';
import disabledSvgDark from './images/disabled-photos-dark.svg';

const Photos = (props) => {
  const { currentProject, photos, darkMode, dispatch } = props;

  const slider =
    photos.length === 0 && currentProject.logo
      ? [{ ...currentProject.logo, image: currentProject.logo.image }]
      : photos;

  const disabled = !slider || slider.length === 0;

  const handleViewMore = () => {
    dispatch(goToProjectDetails(currentProject.id, { photos: true }));
  };

  const makeSlide = ({ image }) => {
    return {
      className: 'photo-slide',
      content: (
        <div
          className="photo-slide-image"
          style={{ backgroundImage: `url(${image.url})` }}
        />
      ),
    };
  };

  const renderDateFooter = (photo) => {
    const { created_at } = photo;

    return (
      <div className="photo-date-footer">
        {`Added `}
        <Date value={created_at} />
      </div>
    );
  };

  return (
    <div
      className={classnames('project-dashboard-photos', {
        'dark-mode': darkMode,
        disabled: disabled,
      })}
    >
      {!disabled ? (
        <Swiper
          className="reports-swiper"
          slides={slider.map(makeSlide)}
          slidesPerView={1}
          fixedDarkMode={true}
          viewMore={{
            onClick: handleViewMore,
            renderSlideLeftContent: (index) => renderDateFooter(slider[index]),
          }}
        />
      ) : (
        <React.Fragment>
          <img src={darkMode ? disabledSvgDark : disabledSvg} alt="disabled" />
          <span className="disabled-photos-text">No Photos Added</span>
        </React.Fragment>
      )}
    </div>
  );
};

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    photos: getPhotos(state),
    darkMode: getDarkMode(state),
  };
})(Photos);
