import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './DailyReportLogTable.css';
import SimpleTable from 'components/shared/table/SimpleTable';
import Date from 'components/shared/text/Date';
import { openDraftReport } from 'components/admin/projects/details/project-daily-report-log/store/actions';
import doneSvg from 'images/done.svg';
import doneDarkModeSvg from 'images/doneDarkMode.svg';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import {
  loadDailyReportPdf,
  setReportPdfViewer,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import {
  getReportPdfViewer,
  getReportPdf,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';
import { getDarkMode } from 'selectors/theme';

class DailyReportLogTable extends Component {
  static propTypes = {
    dailyReports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isSubTabSelected: PropTypes.bool,
    isSubDailyReport: PropTypes.bool,
  };

  handleGenerateLink = (index) => {
    const { dailyReports, isSubTabSelected } = this.props;
    let query = `?dailyReportId=${dailyReports[index].id}`;
    if (isSubTabSelected) {
      query += '&subDailyReport=true';
    }

    return query;
  };

  submittedBy = (user) => {
    const title = user.title ? ` - ${user.title}` : '';
    return `${user.first_name} ${user.last_name}${title}`;
  };

  viewButton = (report) => {
    const { isSubDailyReport, isSubTabSelected } = this.props;
    if (report.status === 'submitted' || report.status === 'approved') {
      return (
        <Button
          type={BUTTON_TYPES.LINK}
          color={BUTTON_COLORS.GREEN}
          label="View"
          onClick={(e) => {
            e.stopPropagation();
            this.handleViewPdf(
              report.id,
              report.project_id,
              isSubDailyReport || isSubTabSelected
            );
          }}
        />
      );
    }
  };

  handleViewPdf = (reportId, projectId, isSubDailyReport) => {
    const { dispatch } = this.props;
    dispatch(loadDailyReportPdf(projectId, reportId, isSubDailyReport));
  };

  handleReportPdfViewerClose = () => {
    this.props.dispatch(setReportPdfViewer(false));
  };

  renderPdfViewer = () => {
    const { reportPdfViewer, reportPdf } = this.props;
    if (reportPdfViewer) {
      return (
        <PdfViewer
          content={{
            source: reportPdf,
            type: 'blob',
          }}
          show={reportPdfViewer}
          onClose={this.handleReportPdfViewerClose}
        />
      );
    }
  };

  status = (report) => {
    const { darkMode } = this.props;

    if (report.status === 'draft') {
      return <div className="report-status draft">Draft</div>;
    } else {
      return (
        <div
          className={`report-status ${
            report.on_schedule && !report.fwo_present ? 'on-schedule' : 'delay'
          }`}
        >
          {report.on_schedule && !report.fwo_present && 'On Schedule'}
          {!report.on_schedule && !report.fwo_present && 'Open Item'}
          {report.on_schedule && report.fwo_present && 'Extra Work Order'}
          {!report.on_schedule &&
            report.fwo_present &&
            'Open Item/Extra Work Order'}
          {report.status === 'approved' && (
            <img
              className="done-icon"
              src={darkMode ? doneDarkModeSvg : doneSvg}
              alt="done"
            />
          )}
        </div>
      );
    }
  };

  mapDailyReports = () => {
    const { dailyReports } = this.props;
    return dailyReports.map((report) => {
      const result = [
        report.number,
        report.report_date ? (
          <Date value={report.report_date} format="MM/DD/YYYY" />
        ) : (
          '-'
        ),
        report.submitted_by ? this.submittedBy(report.submitted_by) : '-',
        (report.submitted_at || report.status === 'approved') &&
        report.total_hours
          ? report.total_hours
          : '-',
        this.status(report),
        this.viewButton(report),
      ];
      if (this.showContractorColumn()) {
        const resultName = report.contractor ? report.contractor.name : '-';
        result.splice(2, 0, resultName);
      }
      return result;
    });
  };

  selectedRow = (id) => {
    const {
      dailyReports,
      dispatch,
      isSubDailyReport,
      isSubTabSelected,
    } = this.props;
    const report = dailyReports[id];
    if (report && report.id && report.project_id) {
      dispatch(
        openDraftReport(
          report.project_id,
          report.id,
          isSubDailyReport || isSubTabSelected
        )
      );
    }
  };

  showContractorColumn() {
    const { isSubTabSelected, isSubDailyReport } = this.props;
    return isSubTabSelected && !isSubDailyReport;
  }

  render() {
    const headers = [
      'NUMBER',
      'DATE',
      'SUBMITTED BY',
      'TOTAL HOURS',
      'STATUS',
      'ACTIONS',
    ];
    if (this.showContractorColumn()) {
      headers.splice(2, 0, 'CONTRACTOR');
    }
    return (
      <React.Fragment>
        <SimpleTable
          className={classnames({
            'simple-table': true,
            'projects-table': true,
            'daily-report-log-table': true,
            'sub-daily-report-log-table': this.showContractorColumn(),
          })}
          headers={headers}
          rows={this.mapDailyReports()}
          emptyMessage="No Daily Reports"
          onRowSelected={this.selectedRow}
          onGenerateLink={this.handleGenerateLink}
        />
        <PdfViewer
          content={{
            source: this.props.reportPdf,
            type: 'blob',
          }}
          show={this.props.reportPdfViewer}
          onClose={this.handleReportPdfViewerClose}
        />
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    reportPdfViewer: getReportPdfViewer(state),
    reportPdf: getReportPdf(state),
    darkMode: getDarkMode(state),
  };
})(DailyReportLogTable);
