import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SubmittalActions.css';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import moment from 'moment';
import SubmittalPermissions from 'permissions/submittal-permissions';
import Submittal from 'domain/submittal';
import {
  SubmittalStatusKeys,
  SubmittalStatusEnum,
} from 'domain/submittal-status-enum';
import {
  setSubmittalFormErrors,
  createSubmittal,
  updateSubmittal,
  updateSubmittalLeadTime,
  respondSubmittal,
  respondSubmittalAddlAssignee,
  setSubmittalReadOnly,
  setSubmittalLeadTimeReadOnly,
  setSubmittalGcAcceleration,
  updateSubmittalFormValue,
  loadTransmittalPdf,
  setSetShowSubmittalErrorMessage,
  deleteSubmittal,
  setSubmittalCreatedRevisionModalShow,
  setSubmittalResponsesViewModalShow,
} from 'components/admin/projects/details/project-submittal-log/store/actions';
import {
  getCurrentPage,
  getCurrentStatus,
  getSubmittalForm,
  getSubmittalReadOnly,
  getSubmittalLeadTimeReadOnly,
  getSubmittalGcAcceleration,
  getSubmittalShowErrorMessage,
} from 'components/admin/projects/details/project-submittal-log/store/selectors';
import { getDirectUploadInProcess } from 'components/admin/projects/details/store/selectors';
import { setSubmittalResponseModalShow } from './store/actions';

class SubmittalActions extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({}).isRequired,
    project: PropTypes.shape({}).isRequired,
    directory: PropTypes.shape({}).isRequired,
    submittal: PropTypes.shape({}),
    handleClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { showDeleteConfirmation: false };
  }

  componentDidMount() {
    const {
      currentUser,
      project,
      directory,
      submittal,
      gcAcceleration,
      form,
      readOnly,
    } = this.props;

    const canRespond =
      SubmittalPermissions.canRespond(currentUser, submittal, gcAcceleration) &&
      !Submittal.isNewRevision(form);
    const canEdit = SubmittalPermissions.canEdit(
      currentUser,
      project,
      directory,
      submittal
    );
    const canAccelerate =
      !readOnly &&
      !gcAcceleration &&
      SubmittalPermissions.canAccelerate(
        currentUser,
        project,
        directory,
        submittal,
        form
      );

    if (canRespond && canEdit && canAccelerate) this.handleGcAcceleration();
  }

  validateForm = (draft = false) => {
    const {
      currentUser,
      project,
      directory,
      submittal,
      gcAcceleration,
      form,
      dispatch,
    } = this.props;

    const errors = {};
    const status = SubmittalStatusEnum.create(form.status);
    const isResponse = Submittal.isUserResponse(
      currentUser,
      submittal,
      gcAcceleration
    );
    const canEdit = SubmittalPermissions.canEdit(
      currentUser,
      project,
      directory,
      submittal
    );

    if (canEdit) {
      if (!form.csiCodeId) {
        errors.csiCodeId = true;
      }
      if (!draft) {
        if (!form.dueDate && !SubmittalStatusEnum.FOR_RECORD.equals(status)) {
          errors.dueDate = true;
        }
        if (!form.status) {
          errors.status = true;
        }
        if (!form.title) {
          errors.title = true;
        }
        if (!form.question) {
          errors.question = true;
        }
      }
    }
    if (!draft && isResponse && !form.stampAction) {
      errors.stampAction = true;
    }
    dispatch(setSubmittalFormErrors(errors));
    return Object.keys(errors).length === 0;
  };

  validate = (draft = false) => {
    return this.validateForm(draft);
  };

  formStatus = (draft = false) => {
    const { submittal, form } = this.props;
    const status = submittal && SubmittalStatusEnum.create(submittal.status);
    let newStatus = form.status;
    if (draft) {
      if (!status || SubmittalStatusEnum.DRAFT.equals(status)) {
        newStatus = SubmittalStatusKeys.DRAFT;
      } else if (
        SubmittalStatusEnum.PENDING.equals(status) ||
        SubmittalStatusEnum.DRAFT_PENDING.equals(status)
      ) {
        newStatus = SubmittalStatusKeys.DRAFT_PENDING;
      } else if (
        SubmittalStatusEnum.ASSIGNED.equals(status) ||
        SubmittalStatusEnum.DRAFT_ASSIGNED.equals(status)
      ) {
        newStatus = SubmittalStatusKeys.DRAFT_ASSIGNED;
      }
    } else {
      if (newStatus === 'for_record') {
        newStatus = SubmittalStatusKeys.FOR_RECORD;
      } else {
        newStatus =
          form.stampAction ||
          (form.assignee.length === 0
            ? SubmittalStatusKeys.PENDING
            : SubmittalStatusKeys.ASSIGNED);
      }
    }

    this.handleFormChange('status', newStatus);
    return newStatus;
  };

  handleSaveDraft = () => {
    this.handleSubmit(true);
  };

  handleMarkAsClosed = () => {
    this.handleSubmit(false, true);
  };

  handleCreated = (_response) => {
    const { form, handleClose, dispatch } = this.props;

    if (form.newRevision) {
      dispatch(setSubmittalCreatedRevisionModalShow(true));
    } else {
      handleClose();
    }
  };

  handleSubmit = (draft = false, closed = false) => {
    if (!this.validate(draft)) {
      this.props.dispatch(setSetShowSubmittalErrorMessage(true));
      return;
    }
    const {
      currentUser,
      project,
      directory,
      form,
      gcAcceleration,
      dispatch,
    } = this.props;

    const status = SubmittalStatusEnum.create(this.formStatus(draft));
    const submittal = {
      ...form,
      status: status.status,
      assigneeId: form.assignee[0] || '',
      recipientIds: form.recipients.map((recpt) => recpt[0]),
      requestedAt: form.requestedDate ? moment(form.requestedDate) : '',
      closed:
        !!form.closed ||
        closed ||
        !!(gcAcceleration && form.stampAction) ||
        false,
      remarks: form.remarks || '',
      stampAction: form.stampAction || '',
    };
    const canEdit = SubmittalPermissions.canEdit(
      currentUser,
      project,
      directory,
      submittal
    );

    let promise;

    if (form.id && !form.newRevision) {
      if (!canEdit) {
        const addlAssignee = Submittal.addlAssignee(
          this.props.submittal,
          this.props.currentUser
        );
        if (addlAssignee) {
          promise = dispatch(
            respondSubmittalAddlAssignee(addlAssignee.id, submittal)
          );
        } else {
          promise = dispatch(respondSubmittal(project.id, form.id, submittal));
        }
      } else {
        promise = dispatch(updateSubmittal(project.id, form.id, submittal));
      }
    } else {
      const { currentPage, currentStatus } = this.props;
      promise = dispatch(
        createSubmittal(project.id, submittal, currentPage, currentStatus)
      );
    }

    promise.then((response) => {
      if (response.ok) {
        if (gcAcceleration) {
          dispatch(respondSubmittal(project.id, form.id, submittal)).then(
            (response) => {
              if (response.ok) {
                this.handleCreated(response);
              }
            }
          );
        } else {
          this.handleCreated(response);
        }
      }
    });
  };

  handleFormChange = (attribute, value) => {
    this.props.dispatch(updateSubmittalFormValue(attribute, value));
  };

  setReadOnly = (readOnly) => {
    this.props.dispatch(setSubmittalReadOnly(readOnly));
  };

  handleExportTransmittal = () => {
    const { project, form, dispatch } = this.props;
    dispatch(loadTransmittalPdf(project.id, form.id));
  };

  handleEdit = () => {
    this.setReadOnly(false);
  };

  handleEditLeadTime = () => {
    this.props.dispatch(setSubmittalLeadTimeReadOnly(false));
  };

  handleGcAcceleration = () => {
    this.props.dispatch(setSubmittalGcAcceleration(true));
  };

  handleDiscardDraft = () => {
    this.setState({ showDeleteConfirmation: true });
  };

  handleDeleteConfirmation = (confirmed) => {
    this.setState({ showDeleteConfirmation: false });
    confirmed && this.handleDelete();
  };

  handleDelete = () => {
    const { project, submittal, handleClose, dispatch } = this.props;
    dispatch(deleteSubmittal(project.id, submittal.id)).then((response) => {
      if (response.ok) {
        handleClose();
      }
    });
  };

  handleSubmitLeadTime = () => {
    const { project, form, handleClose, dispatch } = this.props;
    const submittal = {
      leadTimeApplies: form.leadTimeApplies,
      leadTimeWeeks: form.leadTimeWeeks,
      orderDate: form.orderDate,
      vendorDelivery: form.vendorDelivery,
      fieldDelivery: form.fieldDelivery,
      leadTimeReminder: form.leadTimeReminder,
    };
    dispatch(updateSubmittalLeadTime(project.id, form.id, submittal)).then(
      (response) => {
        if (response.ok) {
          handleClose();
        }
      }
    );
  };
  handleOpenSubmittalResponseModal = () => {
    this.setReadOnly(false);
    this.props.dispatch(setSubmittalResponseModalShow(true));
  };
  handleOpenSubmittalResponsesViewModal = () => {
    this.props.dispatch(setSubmittalResponsesViewModalShow(true));
  };

  render() {
    const {
      currentUser,
      project,
      directory,
      submittal,
      form,
      readOnly,
      leadTimeReadOnly,
      gcAcceleration,
      showErrorMessage,
      handleClose,
      directUploadInProcess,
    } = this.props;
    const { showDeleteConfirmation } = this.state;

    const showLeadTime = SubmittalPermissions.canViewLeadTime(submittal);

    const canExportTransmittal =
      !readOnly &&
      SubmittalPermissions.canExportTransmittal(
        currentUser,
        project,
        directory,
        submittal,
        form
      );

    const canMarkResolved =
      readOnly &&
      SubmittalPermissions.canMarkResolved(
        currentUser,
        project,
        directory,
        submittal,
        form
      );

    const canDiscard = SubmittalPermissions.canDiscard(
      currentUser,
      project,
      directory,
      submittal,
      form
    );

    const canAccelerate =
      !gcAcceleration &&
      SubmittalPermissions.canAccelerate(
        currentUser,
        project,
        directory,
        submittal,
        form
      );

    const canSaveDraft =
      !readOnly &&
      SubmittalPermissions.canSaveDraft(
        currentUser,
        project,
        directory,
        submittal
      );

    const canEditClosed =
      readOnly &&
      leadTimeReadOnly &&
      SubmittalPermissions.canEditClosed(
        currentUser,
        project,
        directory,
        submittal,
        form
      );

    const canEditLeadTime =
      readOnly &&
      leadTimeReadOnly &&
      SubmittalPermissions.canEditLeadTime(
        currentUser,
        project,
        directory,
        submittal
      );
    const canRespond =
      SubmittalPermissions.canRespond(currentUser, submittal, gcAcceleration) &&
      !Submittal.isNewRevision(form);
    const hasAssignees =
      submittal &&
      (!!submittal.assignee_id ||
        (submittal.submital_addl_assignees &&
          submittal.submital_addl_assignees.length > 0));

    console.log(submittal);
    return (
      <div className="submittal-actions">
        {showErrorMessage && (
          <h4 className="error-message">
            There are missing fields, please review.
          </h4>
        )}
        <div className="buttons-container">
          {canExportTransmittal && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Export Transmittal"
              onClick={this.handleExportTransmittal}
            />
          )}
          {canMarkResolved && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Mark Resolved"
              onClick={this.handleMarkAsClosed}
            />
          )}
          {canDiscard && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Discard"
              onClick={this.handleDiscardDraft}
            />
          )}
          {(!readOnly || !leadTimeReadOnly) && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Cancel"
              onClick={handleClose}
            />
          )}
          {canSaveDraft && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Save Draft"
              disabled={directUploadInProcess}
              onClick={this.handleSaveDraft}
            />
          )}
          {!readOnly && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label={form.id ? 'Submit' : 'Create'}
              disabled={directUploadInProcess}
              onClick={() => this.handleSubmit()}
            />
          )}
          {canEditClosed && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Edit"
              onClick={this.handleEdit}
            />
          )}
          {canEditLeadTime && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Edit Lead Time"
              onClick={this.handleEditLeadTime}
            />
          )}
          {!leadTimeReadOnly && showLeadTime && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Submit"
              onClick={this.handleSubmitLeadTime}
            />
          )}
          {(canAccelerate || canRespond) && form.id && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Respond"
              onClick={this.handleOpenSubmittalResponseModal}
            />
          )}
          {hasAssignees && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="View Responses"
              onClick={this.handleOpenSubmittalResponsesViewModal}
            />
          )}
        </div>
        {showDeleteConfirmation && (
          <ConfirmationModal
            show={showDeleteConfirmation}
            message="Are you sure you want to discard this Submittal?"
            onHide={this.handleDeleteConfirmation}
          />
        )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentPage: getCurrentPage(state),
    currentStatus: getCurrentStatus(state),
    form: getSubmittalForm(state),
    readOnly: getSubmittalReadOnly(state),
    leadTimeReadOnly: getSubmittalLeadTimeReadOnly(state),
    gcAcceleration: getSubmittalGcAcceleration(state),
    showErrorMessage: getSubmittalShowErrorMessage(state),
    directUploadInProcess: getDirectUploadInProcess(state),
  };
})(SubmittalActions);
