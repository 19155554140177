import React from 'react';
import { connect } from 'react-redux';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';

import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import SidebarForm from 'components/shared/sidebar-form/SidebarForm';
import MessageModal, {
  MODAL_ICONS,
} from 'components/shared/modal/MessageModal';
import useFieldReportForm from './FieldReportForm.hook';

import './FieldReportForm.css';
import FieldReportFormInfo from './FieldReportFormInfo';
import FieldReportFormStatus from './FieldReportFormStatus';
import FieldReportFormFiles from './FieldReportFormFiles';
import FieldReportFormDisclaimer from './FieldReportFormDisclaimer';
import FieldReport from 'domain/field-report';
import FieldReportPdfModal from './FieldReportPdfModal';
import {
  getCurrentFieldReport,
  getShowWaitingPreview,
} from './store/selectors';
import FieldReportSaveDraftModal from './FieldReportSaveDraftModal';
import FieldReportHtmlViewer from './FieldReportHtmlViewer';

const FieldReportForm = (props) => {
  const { showWaitingPreview, currentProject } = props;
  const {
    form,
    formSection,
    setFormSection,
    handleFormChange,
    handleSaveDraft,
    handlePublish,
    handleCancel,
    handleCloseSaveDraftModal,
    handleSubmitSaveDraftModal,
    handleHtmlView,
  } = useFieldReportForm(props);

  const sections = [
    {
      id: 'information',
      title: 'Report Information',
      error: FieldReport.hasErrorInformaitonSection(form.errors),
      render: () => (
        <FieldReportFormInfo form={form} handleFormChange={handleFormChange} />
      ),
    },
    {
      id: 'project_status',
      title: 'Project Status',
      error: FieldReport.hasErrorProjectStatusSection(form.errors),
      render: () => (
        <FieldReportFormStatus
          form={form}
          handleFormChange={handleFormChange}
        />
      ),
    },
    {
      id: 'files',
      title: 'Photos & Documents',
      render: () => (
        <FieldReportFormFiles form={form} handleFormChange={handleFormChange} />
      ),
    },
    {
      id: 'disclaimer',
      title: 'Disclaimer',
      render: () => (
        <FieldReportFormDisclaimer
          form={form}
          handleFormChange={handleFormChange}
        />
      ),
    },
  ];

  const actions = [
    <Button
      key="view"
      type={BUTTON_TYPES.CONFIRMATION}
      color={BUTTON_COLORS.TRANSPARENT}
      label="View"
      onClick={handleHtmlView}
    />,
    <Button
      key="draft"
      type={BUTTON_TYPES.CONFIRMATION}
      color={BUTTON_COLORS.TRANSPARENT}
      label="Save Draft"
      onClick={handleSaveDraft}
    />,
    <Button
      key="publish"
      type={BUTTON_TYPES.CONFIRMATION}
      color={BUTTON_COLORS.GREEN}
      label="Publish"
      onClick={handlePublish}
    />,
    <Button
      key="cancel"
      type={BUTTON_TYPES.CONFIRMATION}
      color={BUTTON_COLORS.TRANSPARENT}
      label="Cancel"
      onClick={handleCancel}
    />,
  ];

  return (
    <React.Fragment>
      <SidebarForm
        className="field-report-form"
        title="Field Report Draft"
        sections={sections}
        actions={actions}
        selectedSection={formSection}
        onChange={(s) => setFormSection(s.id)}
      />
      <FieldReportPdfModal />
      <FieldReportHtmlViewer project={currentProject} />
      <FieldReportSaveDraftModal
        onClose={handleCloseSaveDraftModal}
        onConfirm={handleSubmitSaveDraftModal}
      />
      <MessageModal
        show={showWaitingPreview}
        icon={MODAL_ICONS.LOADING}
        message={<div>Making PDF, please wait...</div>}
        buttons={[]}
        iconSize={45}
      />
    </React.Fragment>
  );
};

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    currentUser: getCurrentUser(state),
    currentFieldReport: getCurrentFieldReport(state),
    showWaitingPreview: getShowWaitingPreview(state),
  };
})(FieldReportForm);
