import { createSelector } from 'reselect';

export const getDailyReports = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.dailyReports,
  (dailyReports) => {
    return dailyReports;
  }
);

export const getCurrentDailyReport = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.currentDailyReport,
  (currentDailyReport) => {
    return currentDailyReport;
  }
);

export const getShowAddDailyReportModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui
      .showAddDailyReportModal,
  (dailyReports) => {
    return dailyReports;
  }
);

export const getShowProjectReportPermissionsModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui
      .showProjectReportPermissionsModal,
  (dailyReports) => {
    return dailyReports;
  }
);

export const getShowReviewForm = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui.showReviewForm,
  (showReviewForm) => {
    return showReviewForm;
  }
);

export const getDailyReportIsSubmitted = createSelector(
  (state) => state.projects.scenes.details.scenes.dailyReportLog.ui.isSubmitted,
  (isSubmitted) => {
    return isSubmitted;
  }
);

export const getContributors = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.contributors,
  (recipients) => {
    return recipients;
  }
);

export const getPresetTasks = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.presetTasks,
  (presetTasks) => {
    return presetTasks;
  }
);

export const getSupervisorTasks = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.supervisorTasks,
  (supervisorTasks) => {
    return supervisorTasks;
  }
);

export const getMembers = createSelector(
  (state) => state.projects.scenes.details.scenes.dailyReportLog.data.members,
  (members) => {
    return members;
  }
);

export const getDeletedMembers = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.deletedMembers,
  (deletedMembers) => {
    return deletedMembers;
  }
);

export const getMembersUsers = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.membersUsers,
  (membersUsers) => {
    return membersUsers;
  }
);

export const getContributorsForm = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.contributorsForm,
  (contributorsForm) => {
    return contributorsForm;
  }
);

export const getDailyReportEntryIds = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data
      .currentDailyReportIds,
  (ids) => {
    return ids;
  }
);

export const getActiveEntryId = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.activeEntryId,
  (id) => {
    return id;
  }
);

export const getReportDate = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.reportDate,
  (reportDate) => {
    return reportDate;
  }
);

export const getReportExtraInputs = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.reportExtraInputs,
  (reportExtraInputs) => {
    return reportExtraInputs;
  }
);

export const getReportOpenItem = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.reportOpenItem,
  (reportOpenItem) => {
    return reportOpenItem;
  }
);

export const getGCImages = createSelector(
  (state) => state.projects.scenes.details.scenes.dailyReportLog.data.gcImages,
  (gcImages) => {
    return gcImages;
  }
);

export const getGCDeletedImages = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.gcDeletedImages,
  (gcDeletedImages) => {
    return gcDeletedImages;
  }
);

export const getActiveContributor = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui.activeContributor,
  (dailyReports) => {
    return dailyReports;
  }
);

export const getEditMode = createSelector(
  (state) => state.projects.scenes.details.scenes.dailyReportLog.ui.editMode,
  (editMode) => {
    return editMode;
  }
);

export const getDailyReportLogPdf = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.dailyReportLogPdf,
  (dailyReportLogPdf) => {
    return dailyReportLogPdf;
  }
);

export const getDailyReportLogPdfViewer = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui
      .dailyReportLogPdfViewer,
  (dailyReportLogPdfViewer) => {
    return dailyReportLogPdfViewer;
  }
);

export const getReportPdfViewer = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui.reportPdfViewer,
  (reportPdfViewer) => {
    return reportPdfViewer;
  }
);

export const getReportPdf = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.reportLogPdf,
  (reportLogPdf) => {
    return reportLogPdf;
  }
);

export const getGeneralContactorName = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.generalContactor,
  (generalContactor) => {
    return generalContactor;
  }
);

export const getMemberForm = createSelector(
  (state) => state.projects.scenes.details.scenes.dailyReportLog.ui.memberForm,
  (memberForm) => {
    return memberForm;
  }
);

export const getCurrentPage = createSelector(
  (state) => state.projects.scenes.details.scenes.dailyReportLog.ui.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getReportsPageSize = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui.reportsPageSize,
  (reportsPageSize) => {
    return reportsPageSize;
  }
);

export const getReportsTotal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui.reportsTotal,
  (reportsTotal) => {
    return reportsTotal;
  }
);

export const getDelivery = createSelector(
  (state) => state.projects.scenes.details.scenes.dailyReportLog.ui.delivery,
  (delivery) => {
    return delivery;
  }
);

export const getExtraInputs = createSelector(
  (state) => state.projects.scenes.details.scenes.dailyReportLog.ui.extraInputs,
  (extraInputs) => {
    return extraInputs;
  }
);

export const getDailyReportsTabSelected = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui
      .dailyReportsTabSelected,
  (dailyReportsTabSelected) => {
    return dailyReportsTabSelected;
  }
);

export const getSubDailyReportsCount = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data
      .subDailyReportsCount,
  (subDailyReportsCount) => {
    return subDailyReportsCount;
  }
);

export const getFieldWorkOrders = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.fieldWorkOrders,
  (fieldWorkOrders) => {
    return fieldWorkOrders;
  }
);

export const getShowFieldWorkOrderModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui
      .showFieldWorkOrderModal,
  (showFieldWorkOrderModal) => {
    return showFieldWorkOrderModal;
  }
);

export const getCurrentFieldWorkOrder = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data
      .currentFieldWorkOrder,
  (currentFieldWorkOrder) => {
    return currentFieldWorkOrder;
  }
);

export const getFieldWorkOrderForm = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.fieldWorkOrderForm,
  (fieldWorkOrderForm) => {
    return fieldWorkOrderForm;
  }
);

export const getFieldWorkOrderEdit = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui.fieldWorkOrderEdit,
  (fieldWorkOrderEdit) => {
    return fieldWorkOrderEdit;
  }
);

export const getShowFieldWorkOrderError = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui
      .showFieldWorkOrderError,
  (showFieldWorkOrderError) => {
    return showFieldWorkOrderError;
  }
);

export const getAddFloor = createSelector(
  (state) => state.projects.scenes.details.scenes.dailyReportLog.ui.addFloor,
  (addFloor) => {
    return addFloor;
  }
);

export const getProjectReportValue = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data
      .fieldProjectReportForm,
  (fieldProjectReportForm) => {
    return fieldProjectReportForm;
  }
);

export const getProjectReportPdf = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.projectReportPdf,
  (projectReportPdf) => {
    return projectReportPdf;
  }
);

export const getProjectReportPdfViewer = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui
      .projectReportPdfViewer,
  (projectReportPdfViewer) => {
    return projectReportPdfViewer;
  }
);

export const getFieldProjectReports = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data
      .fieldProjectReports,
  (fieldProjectReports) => {
    return fieldProjectReports;
  }
);

export const getLastProjectReport = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data.lastProjectReport,
  (lastProjectReport) => {
    return lastProjectReport;
  }
);

export const getprojectReportOpenDetails = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui
      .projectReportOpenDetails,
  (projectReportOpenDetails) => {
    return projectReportOpenDetails;
  }
);

export const getFieldProjectResolvedInfo = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.data
      .projectReportResolvedInfo,
  (projectReportResolvedInfo) => {
    return projectReportResolvedInfo;
  }
);

export const getprojectReportsPermissions = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui
      .projectReportsPermissions,
  (projectReportsPermissions) => {
    return projectReportsPermissions;
  }
);

export const getProjectPhotosAndDocumentsFilteringActive = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui
      .projectPhotosAndDocumentsFilteringActive,
  (projectPhotosAndDocumentsFilteringActive) => {
    return projectPhotosAndDocumentsFilteringActive;
  }
);

export const getProjectReportPreview = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.dailyReportLog.ui.projectReportPreview,
  (projectReportPreview) => {
    return projectReportPreview;
  }
);

export const getProjectReportInitialTab = createSelector(
  (state) => state.projects.scenes.details.scenes.dailyReportLog.ui.initialTab,
  (initialTab) => {
    return initialTab;
  }
);
