import axios from 'axios';
import numeral from 'numeral';

import BaseApi from './base-api';

class DailyReportsApi extends BaseApi {
  getDailyReports(projectId, currentPage) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/daily_reports?page=${currentPage}`
      )
      .then((response) => {
        return {
          ok: true,
          dailyReports: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getDailyReportLogPdf(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/daily_report_log/print`, {
        responseType: 'blob',
      })
      .then((response) => {
        return {
          ok: true,
          dailyReportLogPdf: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  addDailyReport(values, reportDate, projectId, generalContactorName) {
    const data = {
      daily_report: {
        report_date: reportDate,
        daily_report_entries_attributes: values,
        gc_staff_on_site: !!generalContactorName,
      },
    };

    return axios
      .post(`${this.baseUrl}/projects/${projectId}/daily_reports`, data)
      .then((response) => {
        return {
          ok: true,
          dailyReport: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateDailyReport(values, projectId, dailyReportId) {
    const data = {
      daily_report: {
        report_date: values.reportDate,
        gc_staff_on_site: values.gcStaffOnSite,
        inspections: values.inspections,
        deliveries: values.deliveries,
        received_submittal_ids: values.received_submittal_ids,
        visitors: values.visitors,
        phone_calls: values.phone_calls,
        status: values.status,
        daily_report_entries_attributes: values.dailyReportEntriesAttributes,
        is_safety_accepted: values.is_safety_accepted,
        safety: values.safety,
      },
    };

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/daily_reports/${dailyReportId}`,
        data
      )
      .then((response) => {
        return {
          ok: true,
          dailyReport: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  duplicateDailyReport(values, projectId, dailyReportId) {
    const data = {
      daily_report: {
        report_date: values.reportDate,
      },
    };

    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/daily_reports/${dailyReportId}/duplicate`,
        data
      )
      .then((response) => {
        return {
          ok: true,
          dailyReport: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteDailyReport(projectId, dailyReportId) {
    return axios
      .delete(
        `${this.baseUrl}/projects/${projectId}/daily_reports/${dailyReportId}`
      )
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getDailyReportPdf(projectId, dailyReportId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/daily_reports/${dailyReportId}/print`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        return {
          ok: true,
          reportPdf: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getDailyReport(projectId, id) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/daily_reports/${id}`)
      .then((response) => {
        return {
          ok: true,
          dailyReport: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  addDailyReportMembers(values, projectId, reportId) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.addDailyReportMembersParams(values);

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/daily_reports/${reportId}`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          dailyReport: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  addDailyReportMembersParams(values) {
    let apiPayload = new FormData();
    if (values.gc_staff_members_attributes) {
      values.gc_staff_members_attributes.forEach((member, index) => {
        if (member.id) {
          apiPayload.append(
            `daily_report[gc_staff_members_attributes][${index}][id]`,
            member.id
          );
        }

        if (member._destroy) {
          apiPayload.append(
            `daily_report[gc_staff_members_attributes][${index}][_destroy]`,
            member._destroy
          );
        } else {
          apiPayload.append(
            `daily_report[gc_staff_members_attributes][${index}][user_id]`,
            member.user_id
          );
          apiPayload.append(
            `daily_report[gc_staff_members_attributes][${index}][floor]`,
            member.floor
          );
          apiPayload.append(
            `daily_report[gc_staff_members_attributes][${index}][area]`,
            member.area
          );
          apiPayload.append(
            `daily_report[gc_staff_members_attributes][${index}][supervisor_task_other]`,
            member.supervisor_task_other
          );
          apiPayload.append(
            `daily_report[gc_staff_members_attributes][${index}][comments]`,
            member.comments
          );
          apiPayload.append(
            `daily_report[gc_staff_members_attributes][${index}][hours_worked]`,
            member.hours_worked
          );
          apiPayload.append(
            `daily_report[gc_staff_members_attributes][${index}][started_at]`,
            member.started_at
          );
          apiPayload.append(
            `daily_report[gc_staff_members_attributes][${index}][ended_at]`,
            member.ended_at
          );
          if (
            member.supervisor_task_ids &&
            member.supervisor_task_ids.length > 0
          ) {
            member.supervisor_task_ids.forEach((id) => {
              apiPayload.append(
                `daily_report[gc_staff_members_attributes][${index}][supervisor_task_ids][]`,
                id
              );
            });
          } else {
            apiPayload.append(
              `daily_report[gc_staff_members_attributes][${index}][supervisor_task_ids][]`,
              []
            );
          }
        }
      });
    }

    let gcImagesIndex = 0;
    if (values.gcImages && values.gcImages.length > 0) {
      values.gcImages.forEach((image) => {
        apiPayload.append(
          `daily_report[images_attributes][${gcImagesIndex}][image]`,
          image.file,
          image.file.name
        );
        gcImagesIndex++;
      });
    }

    if (values.gcDeletedImages && values.gcDeletedImages.length > 0) {
      values.gcDeletedImages.forEach((image) => {
        apiPayload.append(
          `daily_report[images_attributes][${gcImagesIndex}][id]`,
          image.id
        );
        apiPayload.append(
          `daily_report[images_attributes][${gcImagesIndex}][_destroy]`,
          true
        );
        gcImagesIndex++;
      });
    }

    if (values.project_open_item_attributes) {
      const {
        id,
        _destroy,
        assignee_id,
        recipient_ids,
        description,
        resolve_by,
        critical,
        images_attributes,
        deleted_images_attributes,
        documents_attributes,
        deleted_documents_attributes,
      } = values.project_open_item_attributes;
      if (id) {
        apiPayload.append('daily_report[project_open_item_attributes][id]', id);
      }

      if (_destroy) {
        apiPayload.append(
          'daily_report[project_open_item_attributes][_destroy]',
          _destroy
        );
      } else {
        apiPayload.append(
          'daily_report[project_open_item_attributes][assignee_id]',
          assignee_id
        );
        apiPayload.append(
          'daily_report[project_open_item_attributes][description]',
          description
        );
        apiPayload.append(
          'daily_report[project_open_item_attributes][resolve_by]',
          resolve_by
        );
        apiPayload.append(
          'daily_report[project_open_item_attributes][critical]',
          critical
        );

        if (recipient_ids && recipient_ids.length > 0) {
          recipient_ids.forEach((rcptId) => {
            apiPayload.append(
              'daily_report[project_open_item_attributes][recipient_ids][]',
              rcptId
            );
          });
        } else {
          apiPayload.append(
            'daily_report[project_open_item_attributes][recipient_ids][]',
            []
          );
        }

        let index = 0;
        if (images_attributes && images_attributes.length > 0) {
          images_attributes.forEach((image) => {
            apiPayload.append(
              `daily_report[project_open_item_attributes][images_attributes][${index}][image]`,
              image.file,
              image.file.name
            );
            index++;
          });
        }

        if (deleted_images_attributes && deleted_images_attributes.length > 0) {
          deleted_images_attributes.forEach((image) => {
            apiPayload.append(
              `daily_report[project_open_item_attributes][images_attributes][${index}][id]`,
              image.id
            );
            apiPayload.append(
              `daily_report[project_open_item_attributes][images_attributes][${index}][_destroy]`,
              true
            );
            index++;
          });
        }

        index = 0;
        if (documents_attributes && documents_attributes.length > 0) {
          documents_attributes.forEach((doc) => {
            apiPayload.append(
              `daily_report[project_open_item_attributes][documents_attributes][${index}][document]`,
              doc.file,
              doc.file.name
            );
            index++;
          });
        }

        if (
          deleted_documents_attributes &&
          deleted_documents_attributes.length > 0
        ) {
          deleted_documents_attributes.forEach((doc) => {
            apiPayload.append(
              `daily_report[project_open_item_attributes][documents_attributes][${index}][id]`,
              doc.id
            );
            apiPayload.append(
              `daily_report[project_open_item_attributes][documents_attributes][${index}][_destroy]`,
              true
            );
            index++;
          });
        }
      }
    }

    return apiPayload;
  }
}

export const dailyReportsApi = new DailyReportsApi();
